import { type OffersForSaleSchema } from '@b2ag/types-api-schemas'
import {
  type EnhancedLogisticOfferForSale,
  enhanceLogisticOffersForSale,
  type LogisticOfferForSale,
} from '@invivodf/module-logistic-offer-client-sdk'
import { DateTime } from 'luxon'

export function mapLogisticOffers(offer: OffersForSaleSchema[number]): EnhancedLogisticOfferForSale[] {
  const today = DateTime.now().toISODate()
  const logisticOffers =
    offer.logistic_offers?.map(
      (logisticOffer) =>
        ({
          ...logisticOffer,
        }) as unknown as LogisticOfferForSale, // offer repo does not have the real type
    ) || []
  return enhanceLogisticOffersForSale(logisticOffers, today)
}
