// eslint-disable-next-line max-classes-per-file
declare namespace Intl {
  class ListFormat {
    constructor(locale?: string, options?: { style?: string; type?: 'conjunction' | 'disjunction' })

    format(list: string[]): string
  }
}

if (!('ListFormat' in Intl)) {
  const words = {
    conjunction: 'et',
    disjunction: 'ou',
  }
  Intl.ListFormat = class ListFormat {
    // eslint-disable-next-line no-useless-constructor
    constructor(
      public locale: string = 'fr',
      public readonly options: { style?: string; type?: 'conjunction' | 'disjunction' } = {
        style: 'normal',
        type: 'conjunction',
      },
    ) {}

    format(list: string[]) {
      if (this.options.style === 'narrow') return list.join(', ')
      const last = list[list.length - 1]
      return `${list.slice(0, -1).join(', ')} ${words[this.options.type || 'conjunction']} ${last}`
    }
  }
}

const listFormatter = new Intl.ListFormat('fr', { style: 'narrow' })
const andListFormatter = new Intl.ListFormat('fr', { type: 'conjunction' })
const orListFormatter = new Intl.ListFormat('fr', { type: 'disjunction' })

// @ts-expect-error Le comportement voulu ici c'est de retirer les valeurs falsy (typiquement null, undefined, '')
// pour rendre les formatteurs de liste plus pratiques à utiliser
// (par défaut, ça fait une erreur si on ne passe pas que des strings à un ListFormat).
// Donc ce cast de `Boolean` n’a pas pour but de décrire ce que fait vraiment Boolean,
// mais de dire ce qu’on attend de lui pour ce cas d’usage.
const shouldDisplay = Boolean as (v: unknown) => v is string

export const formatList = (list: unknown[]) => listFormatter.format(list.filter(shouldDisplay))
export const formatAndList = (list: unknown[]) => andListFormatter.format(list.filter(shouldDisplay))
export const formatOrList = (list: unknown[]) => orListFormatter.format(list.filter(shouldDisplay))
