import { measureUnit } from '../unit/measure'

export const { format: formatNumber } = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 })
export const { format: formatNumber3Digits } = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 3 })
export const { format: formatEuros } = new Intl.NumberFormat('fr-FR', {
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'EUR',
})
export const { format: formatRoundEuros } = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: 'currency',
  currency: 'EUR',
})

export const formatQuantity = (n: number, unit?: string) =>
  unit ? `${formatNumber3Digits(n)}\xa0${measureUnit(unit, n)}` : formatNumber3Digits(n)

export const formatMeasureUnit = (n: number, unit?: string) =>
  unit ? `${formatNumber(n)}\xa0${measureUnit(unit, n)}` : formatNumber(n)
