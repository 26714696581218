import type { Offer as PricingOffer } from '@b2ag/pricing'
import { type EnhancedLogisticOfferForSale } from '@invivodf/module-logistic-offer-client-sdk'

import { type Quota } from '@b2ag/quota/src/quota'

export type OfferType = 'off_season' | ''

export type PriceType = 'fixedPrice' | 'temporaryPrice' | 'preorderPrice'

export const variantId = Symbol('variant ID')

export interface ShippingWay {
  kind: string
  title: string
}

export interface PaymentDueDate {
  reference: string
  title: string
  description: string
  rate: number
}

export interface OfferBase {
  offerId: string
  allowedWithoutStock?: boolean
  discountId?: string
  isSoldAsUnit: boolean
  measurementPrice?: number
  offerType: OfferType
  priceType: PriceType
  quantityMax?: number
  rpdUnit?: string
  rpdUnitPrice?: number
  sellerCustomId: string
  stockManagementType: string
  tvaUnitPrice: number
  unitPrice: number
  validityEndDate?: Date
  [variantId]: string
  productId: string
  measureQuantity?: number
  measureUnit?: string
  quota?: Quota
  pricing?: PricingOffer
}

export interface Offer extends OfferBase {
  description?: string
  isTechExclusive?: boolean
  quantityMin: number
  quantityStep: number
  intervalName?: string
  shippingStartDate?: string
  shippingEndDate?: string
  logisticOffers: EnhancedLogisticOfferForSale[]
  taxTvaPercent: number
  productIsRestrictedExplosivesPrecursor: boolean
  paymentDueDateList?: PaymentDueDate[]
}

export interface OfferSelection {
  offer: Offer
  quantity: number
}
