import { DateTime } from 'luxon'
import { contentfulAssetUrlToUrl } from './url.mapper'
import type { ArticleHeader } from '../../domain/ArticleHeader'

export function contentfulContentToArticleHeader(dto: any, publishedAtDate: string): ArticleHeader {
  return {
    image: contentfulAssetUrlToUrl(dto.fields?.image?.fields?.file?.url),
    imageAlt: dto.fields?.image?.fields?.description,
    imageLegend: dto.fields?.imageLegend,
    author: dto.fields?.author,
    supplierName: dto.fields?.supplierName,
    supplierColor: dto.fields?.supplierColor,
    publishedAt: DateTime.fromISO(publishedAtDate).setLocale('fr').toJSDate(),
    richBoxTag: dto.fields?.richBoxTag,
    partnerFilter: dto.fields?.partnerFilter,
    imageUrl: dto.fields?.imageUrl,
  } as ArticleHeader
}
