import Big from 'big.js'

import Discount from '../Discount'

import Price from './Price'

export default class DetailPrice {
  public readonly exclTaxes: Price

  public readonly inclTaxes: Price

  public readonly vatAmount: number

  public readonly rpdAmount: number | null

  public readonly discountAmount: number | null

  constructor(
    public readonly basePrice: Price,
    private readonly vat: number,
    rpd?: number,
    discount?: Discount,
  ) {
    this.exclTaxes = discount ? basePrice.applyDiscount(discount) : basePrice
    this.rpdAmount = rpd || null
    this.vatAmount = +Big(+this.exclTaxes)
      .add(this.rpdAmount || 0)
      .mul(this.vat)
      .div(100)
    this.inclTaxes = new Price(
      Big(this.exclTaxes.valueOf())
        .plus(this.vatAmount)
        .plus(this.rpdAmount || 0),
    )
    this.discountAmount = discount ? +Big(+basePrice).minus(+this.exclTaxes) : null
  }

  applyDiscount(discount: Discount): DetailPrice {
    return new DetailPrice(this.basePrice, this.vat, this.rpdAmount || undefined, discount)
  }
}
