import type { Discount } from '@b2ag/pricing/src/domain/pricing.interface'

export function mapFromApi(discount: any, cooperativeId: number): Discount {
  const {
    id,
    reference,
    public_name: publicName,
    unit,
    validity_start_date: validityStartDate,
    validity_end_date: validityEndDate,
    measure_unit: measureUnit,
    discountable_ids: offerIdList,
    scale,
    amount,
    discount_type: type,
  } = discount

  return {
    id,
    reference,
    publicName,
    unit,
    validityStartDate: new Date(validityStartDate),
    validityEndDate: validityEndDate ? new Date(validityEndDate) : null,
    measureUnit,
    cooperativeId,
    offerIdList,
    scale,
    amount,
    type,
    offerMap: Object.fromEntries(offerIdList.map((offerId) => [offerId, 0])),
  }
}

export const mapListFromApi = (discountList: any[] = [], cooperativeId: number) =>
  discountList.map((discount) => mapFromApi(discount, cooperativeId))
