const getAssetWithPath =
  typeof require === 'function'
    ? require
    : function (path: string) {
        if (import.meta.url) return new URL(path, import.meta.url).href
        return undefined
      }

export const defaultImagePerProductKind = {
  sante_vegetal: getAssetWithPath(`../assets/img/product/product_default_crop_protection.jpg`),
  semences: getAssetWithPath('../assets/img/product/product_default_seed.jpg'),
  nutrition_vegetal_amendements: getAssetWithPath(`../assets/img/product/product_default_nutrition_amendments.jpg`),
  services: getAssetWithPath(`../assets/img/product/product_default_service.jpg`),
  equipements_exploitant: getAssetWithPath(`../assets/img/product/product_default_farmer_equipment.jpg`),
  equipements_culture: getAssetWithPath(`../assets/img/product/product_default_culture_equipment.jpg`),
  equipement_vigne_arbo: getAssetWithPath(`../assets/img/product/product_default_vine_equipement.jpg`),
  amenagement_exploitation: getAssetWithPath(`../assets/img/product/product_default_farm_development.jpg`),
}

export function getProductDefaultImageFull(kind: string, type?: string): string {
  return type === 'Pack'
    ? getAssetWithPath(`../assets/img/product/product_default_crop_protection_pack.jpg`)
    : defaultImagePerProductKind[kind]
}

export const defaultImagePerProductKind160Px = {
  sante_vegetal: getAssetWithPath(`../assets/img/product/product_default_160_crop_protection.jpg`),
  semences: getAssetWithPath(`../assets/img/product/product_default_160_seed.jpg`),
  nutrition_vegetal_amendements: getAssetWithPath(`../assets/img/product/product_default_160_nutrition_amendments.jpg`),
  services: getAssetWithPath(`../assets/img/product/product_default_160_service.jpg`),
  equipements_exploitant: getAssetWithPath(`../assets/img/product/product_default_160_farmer_equipment.jpg`),
  equipements_culture: getAssetWithPath(`../assets/img/product/product_default_160_culture_equipment.jpg`),
  equipement_vigne_arbo: getAssetWithPath(`../assets/img/product/product_default_160_vine_equipement.jpg`),
  amenagement_exploitation: getAssetWithPath(`../assets/img/product/product_default_160_farm_development.jpg`),
}

export function getProductDefaultImage160Px(kind, type) {
  return type === 'Pack'
    ? getAssetWithPath(`../assets/img/product/product_default_160_crop_protection_pack.jpg`)
    : defaultImagePerProductKind160Px[kind]
}

const cloudinarySanteVegetalPack = 'products/default/product_default_crop_protection_pack'
export const cloudinaryDefaultImagePerProductKind = {
  sante_vegetal: 'products/default/product_default_crop_protection',
  semences: 'products/default/product_default_seed',
  nutrition_vegetal_amendements: 'products/default/product_default_nutrition_amendments',
  services: 'products/default/product_default_service',
  equipements_exploitant: 'products/default/product_default_farmer_equipment',
  equipements_culture: 'products/default/product_default_culture_equipment',
  equipement_vigne_arbo: 'products/default/product_default_vine_equipement',
  amenagement_exploitation: 'products/default/product_default_farm_development',
}

export function getCloudinaryProductDefaultImage(kind: string, type?: string) {
  return type === 'Pack' ? cloudinarySanteVegetalPack : cloudinaryDefaultImagePerProductKind[kind]
}
