import type { MetadataSEO } from '@b2ag/articles/src/domain/MetadataSEO'

export function contentfulContentToMetadataSEO(dto: any): MetadataSEO | undefined {
  const seo = {} as MetadataSEO
  if (dto.fields?.seoTitle) {
    seo.title = dto.fields.seoTitle
  }
  if (dto.fields?.seoDescription) {
    seo.description = dto.fields.seoDescription
  }
  return (Object.keys(seo).length && seo) || undefined
}
