import { computed, type ComputedRef } from 'vue'
import type { CooperativeVariant } from '@b2ag/product/src/services/product'
import { computeStockQuantityByMeasure, computeClickAndCollectStockQuantity, recomputeStock } from '@b2ag/stock'
import type { Stock } from '@b2ag/stock'
import { useStore } from '@b2ag/store'

// eslint-disable-next-line import/prefer-default-export
export function useStock(
  variant: Pick<CooperativeVariant, 'id' | 'measure_quantity' | 'isSoldAsUnit'>,
  consumedStock?: ComputedRef<{ quantity: number; clickAndCollect: number; storeCode: string | undefined }>,
  externalStore?: any,
) {
  const store = externalStore || useStore()
  const stockFromStore = computed(() => store.getters['stock/stock'](variant.id))
  const stock = computed<Stock>(() => {
    const emptyStock: Stock = {
      variant_id: variant.id,
      click_and_collect: 0,
      quantity: 0,
      cooperative_id: store.getters.currentCooperativeId,
      stores: [],
      updated_at: new Date().toISOString(),
    }
    if (stockFromStore.value && consumedStock) {
      return recomputeStock(stockFromStore.value, consumedStock.value)
    }
    return stockFromStore.value ?? emptyStock
  })
  const stockQuantity = computed(() =>
    variant.isSoldAsUnit
      ? stock.value?.quantity
      : computeStockQuantityByMeasure(variant.measure_quantity, stock.value?.quantity ?? 0),
  )
  const clickAndCollectStockQuantity = computed(() =>
    computeClickAndCollectStockQuantity(
      stock.value?.click_and_collect || 0,
      variant.isSoldAsUnit,
      variant.measure_quantity,
    ),
  )

  const hasStock = computed(() => stockQuantity.value > 0)

  return { stock, stockQuantity, clickAndCollectStockQuantity, hasStock }
}
