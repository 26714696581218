import type { SearchResponse } from '@algolia/client-search'

interface InvivoCategory {
  label: string
  code: string
}
export interface Product {
  _id: string
  supplier: string
  categories: InvivoCategory[][]
  [key: string]: any
}

export interface VariantHit {
  ean: string
  /** invivo variant ID */ id: string
  is_sold_as_unit: boolean
  measure_quantity: number
  measure_unit: string
  name: string
}
export interface ProductHit {
  objectID: string
  _id: string
  destination: string
  family: string
  is_exclusive: boolean
  is_exclu_tech: boolean
  is_highlighted: boolean
  is_supplier_highlighted: boolean
  is_off_season: boolean
  kind: string
  name: string
  supplier: string
  supplier_logo: string
  thumbnail: string
  variants: VariantHit[]
  provider?: string
  type?: string
  label?: string
  images?: string[]
}

export interface SearchResult {
  hits: ProductHit[]
  nbHits: number
}

export interface CategoryHit {
  objectID: string
  label: string
  path?: string
  position: number
  slug: string
}

export interface CategoryFacet {
  value: string
  label: string
  children: CategoryFacet[]
  slug?: string
  hierarchyDepth: number
  to?: {
    name: string
    query?: {
      [key: string]: string
    }
    params: {
      slug?: string
    }
  }
}

export function mapToProductHit(product: ProductHit) {
  return {
    _id: product._id,
    family: product.family,
    is_exclusive: product.is_exclusive,
    is_highlighted: product.is_highlighted,
    is_supplier_highlighted: product.is_supplier_highlighted,
    is_off_season: product.is_off_season,
    kind: product.kind,
    name: product.name,
    supplier: product.supplier,
    supplier_logo: product.supplier_logo,
    thumbnail: product.thumbnail,
    variants: product.variants.map((variant) => ({
      ean: variant.ean,
      id: variant.id,
      is_sold_as_unit: variant.is_sold_as_unit,
      measure_quantity: variant.measure_quantity,
      measure_unit: variant.measure_unit,
      name: variant.name,
    })),
  }
}

export interface SearchService {
  initSearchIndex(searchIndexName?: string): void

  initSearchIndexes(searchIndexProductsNames?: string, searchIndexCategoriesName?: string): void

  initSearchIndexCategories(searchIndexCategoriesName?: string): void

  getCategoriesFacets(): Promise<CategoryFacet[]>

  fetchSupplierRelatedProducts(product: Product, isCooperative: boolean, itemsToFetch: number): Promise<SearchResult>

  getSuppliers(): Promise<string[]>

  fetchAlgoliaProducts(isCooperative: boolean, filter: string, nbItemsToFetch?: number): Promise<SearchResponse>

  getCategories(): Promise<CategoryHit[]>
}
