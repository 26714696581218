export function getAnalyticsMetaDataPayload(router, from, store) {
  let referrerDomainName
  try {
    const { referrer } = window.document
    referrerDomainName = new URL(referrer).host
  } catch (error: any) {
    referrerDomainName = window.location.host
  }

  let pageType = router.meta?.analyticsPageType
  if (!pageType && router.matched?.length) {
    pageType =
      router.matched
        .slice()
        .reverse()
        .find((matchedRoute) => matchedRoute.meta?.analyticsPageType) || router.name
  }

  return {
    page_path: router.path,
    domain_name: window.location.host,
    url: router.fullPath,
    referrer_path: from.path,
    referrer_domain_name: referrerDomainName,
    referrer_url: from.fullPath,
    page_name: router.name,
    page_category: pageType,
    environment: window.env.ENVIRONMENT_NAME,
    login_status: store.getters.user.id ? 1 : 0,
    cooperative_id: store.getters.currentCooperativeId || null,
    cooperative_name: store.getters.currentCooperativeName || null,
    membership_number: store.getters.currentProfile?.membershipNumber || null,
    id_client: store.getters.currentProfile?.id || null,
  }
}
