import { Discount, Offer as OfferPricing } from '@b2ag/pricing'
import { formatEuros, measureUnit as formatUnit } from '@b2ag/l10n'
import { i18n } from '@b2ag/locale'

function getUnitPriceHT(discountValue: Discount | null, offer: Pick<OfferPricing, 'unitPriceExcludingTaxes'>) {
  return discountValue ? offer.unitPriceExcludingTaxes.applyDiscount(discountValue) : offer.unitPriceExcludingTaxes
}

function getMeasurementPriceHT(
  discountValue: Discount | null,
  offer: Pick<OfferPricing, 'measurementPriceExcludingTaxes'>,
) {
  return discountValue
    ? offer.measurementPriceExcludingTaxes.applyDiscount(discountValue)
    : offer.measurementPriceExcludingTaxes
}

export function getFormattedHTLabel(offer: Pick<OfferPricing, 'isVariantSoldAsUnit' | 'variantMeasureUnit'>) {
  // eslint-disable-next-line no-irregular-whitespace
  return offer.isVariantSoldAsUnit
    ? i18n._('HT')
    : i18n._('HT / {unit}', { unit: formatUnit(offer.variantMeasureUnit.toString()) })
}

export function getFormattedPrice(
  offer: Pick<OfferPricing, 'isVariantSoldAsUnit' | 'unitPriceExcludingTaxes' | 'measurementPriceExcludingTaxes'>,
): string {
  const { isVariantSoldAsUnit, unitPriceExcludingTaxes, measurementPriceExcludingTaxes } = offer
  return formatEuros(isVariantSoldAsUnit ? +unitPriceExcludingTaxes : +measurementPriceExcludingTaxes)
}

export function getFormattedDiscountedPrice(
  offer: Pick<OfferPricing, 'isVariantSoldAsUnit' | 'measurementPriceExcludingTaxes' | 'unitPriceExcludingTaxes'>,
  discountValue: Discount | null,
) {
  return formatEuros(
    offer.isVariantSoldAsUnit ? +getUnitPriceHT(discountValue, offer) : +getMeasurementPriceHT(discountValue, offer),
  )
}

export function getFormattedDiscountValue(
  discountValue: Discount | null,
  offer: Pick<OfferPricing, 'variantMeasureUnit' | 'isVariantSoldAsUnit'>,
) {
  const formattedMeasureUnit = formatUnit(offer.variantMeasureUnit.toString())
  if (discountValue === null) return null

  const formattedDiscountBase = `-${discountValue.discount}${discountValue.unit}`
  return discountValue.unit === '%' || offer.isVariantSoldAsUnit
    ? formattedDiscountBase
    : `${formattedDiscountBase}/${formattedMeasureUnit}`
}

export function hasDiscount(offer: Pick<OfferPricing, 'hasDiscount'>) {
  return offer.hasDiscount()
}
