import { useStore } from '@b2ag/store'
import { computed } from 'vue'
import type { CategoryFacet } from '@b2ag/search/src/search.service'

export function useSearch() {
  const store = useStore()

  const getCategories = computed(() => store.getters.getCategories)

  const categoryHasNoChildren = (category: CategoryFacet) => {
    return (category.children && category.children.length === 0) || !category.children
  }

  const formatRouterLink = (category: CategoryFacet) => {
    return {
      name: 'category',
      params: {
        slug: category.slug,
      },
    }
  }

  const getParentCategoryLabel = (category: CategoryFacet) => {
    const categoryLevels = category.value.split(' > ')
    return categoryLevels?.length >= 2 ? categoryLevels[categoryLevels.length - 2] : null
  }

  return {
    categoryHasNoChildren,
    formatRouterLink,
    getCategories,
    getParentCategoryLabel,
  }
}
