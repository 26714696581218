import { computed } from 'vue'
import { useStore } from '@b2ag/store'
import { useEnv } from './useEnv'

// eslint-disable-next-line import/prefer-default-export
export const useUser = () => {
  const store = useStore()
  const { isFrontAgri } = useEnv()
  return isFrontAgri
    ? {
        isCertiPhytoValid: computed(() => store.getters.isCropProtectionAllowed),
        isExplosivesPrecursorCertificateValid: computed(() => store.getters.isExplosivesPrecursorAllowed),
        profile: computed(() => store.getters.currentProfile),
      }
    : {
        isCertiPhytoValid: computed(() => store.getters.isTargetCustomerCropProtectionAllowed),
        isExplosivesPrecursorCertificateValid: computed(() => store.getters.isTargetCustomerExplosivesPrecursorAllowed),
        profile: computed(() => store.getters.targetCustomer),
      }
}
