import { computed } from 'vue'
import { useStore } from '@b2ag/store'

export function useCooperativeStores(externalStore?: any) {
  const store = externalStore || useStore()
  const cooperativeStores = computed(() => store.getters['shop/getCooperativeStores'])
  const getCooperativeStoreByCode = computed(() => store.getters['shop/getCooperativeStoreByCode'])
  const fetchStores = async () => {
    await store.dispatch('shop/fetchCooperativeStores')
  }

  return {
    cooperativeStores,
    getCooperativeStoreByCode: getCooperativeStoreByCode.value,
    fetchStores,
  }
}
