import { i18n } from '@b2ag/locale'
import type { PriceType, Offer } from './offer'

export const TEMPORARY_PRICE = 'temporaryPrice'
export const PREORDER_PRICE = 'preorderPrice'
const STOCK_MANAGED = 'managed'

export function isTemporaryPrice(priceType: PriceType) {
  return priceType === TEMPORARY_PRICE
}

export function isPreorderPrice(priceType: PriceType) {
  return priceType === PREORDER_PRICE
}

export function isPreorderPriceType(offer: Offer) {
  return offer?.priceType === PREORDER_PRICE
}

export function isManaged(offer: Pick<Offer, 'stockManagementType' | 'allowedWithoutStock'>): boolean {
  return offer?.stockManagementType === STOCK_MANAGED && !offer?.allowedWithoutStock
}

export function isOfferBuyable(
  isQuotaSufficient: boolean,
  isQuotaReached: boolean,
  noStockAndNotAllowedWithoutStock: boolean,
  isQuantityMaxedOutInCart: boolean,
) {
  return isQuotaSufficient && !isQuotaReached && !noStockAndNotAllowedWithoutStock && !isQuantityMaxedOutInCart
}

export function mainErrorMessage(
  isQuotaSufficient: boolean,
  isQuotaReached: boolean,
  noStockAndNotAllowedWithoutStock: boolean,
  isQuantityMaxedOutInCart: boolean,
) {
  if (isQuotaReached) return i18n._('Le quota sur cette offre est atteint')
  if (!isQuotaSufficient) return i18n._('Le quota restant sur cette offre est insuffisant')
  if (noStockAndNotAllowedWithoutStock) return i18n._('Produit en rupture de stock')
  if (isQuantityMaxedOutInCart) return i18n._('La quantité maximale commandable est atteinte dans votre panier')
  return null
}

export function hasPaymentDueDates(offer: Offer): boolean {
  return !!offer.paymentDueDateList && offer.paymentDueDateList?.length > 0
}

export function getVariantInvivoIdFromOfferId(offerId: string): string {
  return offerId.split(':')[1]
}

export function hasDiscount(offer: Offer): boolean {
  return !!offer.discountId
}
