import { ref, type Ref } from 'vue'
import type { AxiosInstance } from '@b2ag/axios'
import FeatureFlippingService from './featureFlipping.service'

let isProductViewedEnabled: Ref<boolean>
let isNewSearchEnabled: Ref<boolean>
let isAnchoredArticlesEnabled: Ref<boolean>
let isSearchBarPrevisionnelEnabled: Ref<boolean>
let isShippingAddressEnabled: Ref<boolean>
let isRealtimeStocksEnabled: Ref<boolean>

let features: Record<string, Ref>
let featureFlippingService: FeatureFlippingService | undefined

/**
 * @deprecated For unit test only
 */
export function reset() {
  featureFlippingService = undefined
}

export function provideFeatureFlipping(
  featureFlippingApiClient: Pick<AxiosInstance, 'post'>,
  appFront: string,
  appBrand: string = 'aladin',
) {
  featureFlippingService = new FeatureFlippingService(featureFlippingApiClient, appBrand)

  isProductViewedEnabled = ref(false)
  isNewSearchEnabled = ref(false)
  isAnchoredArticlesEnabled = ref(false)
  isSearchBarPrevisionnelEnabled = ref(false)
  isShippingAddressEnabled = ref(false)
  isRealtimeStocksEnabled = ref(false)

  features = {
    [`${appBrand}:front-tech:products-viewed`]: isProductViewedEnabled,
    [`${appBrand}:front-tech:taskforce-new-search`]: isNewSearchEnabled,
    [`${appBrand}:article:anchored-articles`]: isAnchoredArticlesEnabled,
    [`${appBrand}:previsionnel:search-bar`]: isSearchBarPrevisionnelEnabled,
    [`${appBrand}:shipping-address`]: isShippingAddressEnabled,
    [`${appBrand}:front-${appFront}:realtime-stocks`]: isRealtimeStocksEnabled,
  }
}

export interface FeatureFlipping {
  isDashboardTcEnabled: boolean
  isProductViewedEnabled: Ref<boolean>
  isNewSearchEnabled: Ref<boolean>
  updateFeatureFlipping: (partnerId?: number) => Promise<void>
  isAnchoredArticlesEnabled: Ref<boolean>
  isSearchBarPrevisionnelEnabled: Ref<boolean>
  isShippingAddressEnabled: Ref<boolean>
  isRealtimeStocksEnabled: Ref<boolean>
}

export function useFeatureFlipping(): FeatureFlipping {
  return {
    isDashboardTcEnabled: (window.env as any)?.DASHBOARD_CA_TC_FEATURE === 'yes',
    get isProductViewedEnabled() {
      return isProductViewedEnabled
    },
    get isNewSearchEnabled() {
      return isNewSearchEnabled
    },
    get isAnchoredArticlesEnabled() {
      return isAnchoredArticlesEnabled
    },
    get isSearchBarPrevisionnelEnabled() {
      return isSearchBarPrevisionnelEnabled
    },
    get isShippingAddressEnabled() {
      return isShippingAddressEnabled
    },
    get isRealtimeStocksEnabled() {
      return isRealtimeStocksEnabled
    },
    updateFeatureFlipping: async (partnerId?: number) => {
      if (!featureFlippingService) throw new Error('FeatureFlippingService is not initialized')
      try {
        const checkAllResponse = await featureFlippingService.checkAllFeatures(partnerId)
        Object.keys(features).forEach((featureKey) => {
          const checkFeature = checkAllResponse.find((feature) => feature.id === featureKey)
          features[featureKey].value = checkFeature ? checkFeature.active : false
        })
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
  }
}
