import { type AppBrand, appBrands } from '@b2ag/app-brands'
import { getAppTypeLocale } from '@b2ag/locale'

function getDefaultSEODescription(appContext: AppBrand = appBrands.default) {
  const appTypeLocale = getAppTypeLocale(appContext)
  return appTypeLocale.metadata.defaultDescription
}

function productSEODescription(
  { kind = '', supplier = '', name = 'un produit', categories = [], formulation = '' } = {},
  appContext: AppBrand = appBrands.default,
) {
  const appTypeLocale = getAppTypeLocale(appContext)

  const inFormulation = formulation.length > 0 ? `en ${formulation}` : ''
  let description = ''

  let firstCategory = ''
  if (categories.length > 0) {
    // @ts-ignore: migration de JS à TS, ne veut pas toucher à la logique
    firstCategory = categories[0][categories[0].length - 1].label
  }

  const replaceMap = {
    '{$firstCategory}': firstCategory,
    '{$name}': name,
    '{$supplier}': supplier,
    '{$inFormulation}': inFormulation,
  }

  const descriptionTemplateByKind =
    appTypeLocale['v-product'].metadata.description[kind] ?? appTypeLocale['v-product'].metadata.description.default

  description = descriptionTemplateByKind.replace(/{\$(inFormulation|firstCategory|name|supplier)}/g, (key) => {
    return replaceMap[key] ?? ''
  })

  return description.trim().replace(/\s+/g, ' ').replace(/ ,/g, ',').replace(/,,/g, ',')
}

function searchSEODescription(family = '', category = '', appContext: AppBrand = appBrands.default) {
  const appTypeLocale = getAppTypeLocale(appContext)

  const productGroupName =
    !family && !category
      ? appTypeLocale['p-app-search'].metadata.defaultProductGroupeName
      : appTypeLocale['p-app-search'].metadata.productGroupName
          .replace(/{\$family}/g, family)
          .replace(/{\$category}/g, category)
          .replace(/{\$familyOrCategory}/g, category || family)

  const description = appTypeLocale['p-app-search'].metadata.description.replace(
    '{$productGroupName}',
    productGroupName,
  )
  return description.trim().replace(/\s+/g, ' ')
}

export { getDefaultSEODescription, productSEODescription, searchSEODescription }
