import type { AxiosInstance } from '@b2ag/axios'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'

export interface IStockService {
  get(cooperativeId: string, variantId: string): Promise<any>
}

export class StockService implements IStockService {
  api: AxiosInstance

  constructor(orderApiHost: string) {
    this.api = createAxiosClientWithAladinBearer(`${orderApiHost}/v1/stocks`, { callerId: buildCallerId() })
  }

  async get(cooperativeId, variantId) {
    const params = {
      cooperative_id: cooperativeId,
    }
    const response = await this.api.get(`/${variantId}`, {
      params,
      validateStatus(status) {
        return status === 200 || status === 404
      },
    })
    return response.status === 404 ? null : response.data
  }
}
