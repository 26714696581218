// eslint-disable-next-line import/extensions, import/no-unresolved
import { AlgoliaSearchService } from '@b2ag/search'
import type { Article } from '@b2ag/articles/src/domain/Article'
import type { IArticlesRepository } from '../repository/IArticlesRepository'

export interface GetAnchoredArticlesByPartnerIdUseCase {
  (partnerId: number): Promise<Article | null>
}

export interface AnchoredArticleUseCaseFactory {
  getAnchoredArticleByPartnerIdUseCaseFactory(
    articlesRepository: IArticlesRepository,
    searchService: AlgoliaSearchService,
  ): GetAnchoredArticlesByPartnerIdUseCase
}

export function getAnchoredArticleByPartnerIdUseCaseFactory(
  articlesRepository: IArticlesRepository,
  searchService: AlgoliaSearchService,
) {
  return async (partnerId: number): Promise<Article | null> => {
    const suppliersList = await searchService.getSuppliers()
    let anchoredArticles = await articlesRepository.getAnchoredArticlesByPartnerId(partnerId)
    anchoredArticles = anchoredArticles.filter(
      (article) =>
        !article.header.supplierName ||
        (article.header.supplierName && suppliersList.includes(article.header.supplierName)),
    )
    return anchoredArticles.length > 0 ? anchoredArticles[0] : null
  }
}
