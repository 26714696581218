import type { AxiosInstance } from '@b2ag/axios'
import { createAxiosClientAnonymous } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'

export interface IRecommendationService {
  getRecommendations(group: string): Promise<any>
  findRecommendations(productId: string, group: string): Promise<any>
}

class RecommendationService implements IRecommendationService {
  api: AxiosInstance

  constructor(recommendationApiHost: string) {
    this.api = createAxiosClientAnonymous(`${recommendationApiHost}/v1`, { callerId: buildCallerId() })
  }

  async getRecommendations(group: string) {
    try {
      const response = await this.api.get('/recommendations', { params: { group } })
      return response.data
    } catch (error: any) {
      if (error.response.status === 404 || error.response.status === 422) return []
      throw error
    }
  }

  async findRecommendations(productId: string, group: string) {
    try {
      const response = await this.api.get(`/recommendations/products/${productId}`, { params: { group } })
      return response.data
    } catch (error: any) {
      if (error.response?.status === 404) return []
      throw error
    }
  }
}

export default RecommendationService
