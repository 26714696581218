import Vue from 'vue'
import Router from 'vue-router'

import { useAnalyticService } from '@b2ag/plugin-aladin-gtm'
import { AuthErrorKeys, useAuthService } from '@b2ag/auth'
import { VAuthCallback, VAuthCallbackError } from '@b2ag/auth/dist/auth-views.umd'
import { i18n } from '@b2ag/locale'
import VMicroFrontProvider from '@b2ag/micro-front-tools/src/providers/VMicroFrontProvider.vue'
import { DataForParent, getAppContext } from '@b2ag/micro-front-tools'
import { isWebViewFarmi } from '@b2ag/farmi-app-appro-tools'

import { callbacksFromLegalsMicroFrontIframe } from '@/contexts/legals'
import { callbacksFromCheckoutMicroFrontIframe, routingCancelCheckout } from '@b2ag/checkout'

import { isFarmi } from '@b2ag/app-brands'

import { getAnalyticsMetaDataPayload } from '@/utils/analytics'
import { locationStorage } from './services'
import store from './store/store'
import { callbacksFromCartMicroFrontIframe } from './contexts/cart'

const PAGE_TYPE = {
  SIGN_UP: 'Inscription',
  PROFILE: 'Profils',
  PURCHASES: 'Achats',
  AUTH: 'oauth',
  CART: 'Panier',
  CHECKOUT: 'Checkout',
  SEARCH: 'Recherche',
  ACCOUNT: 'Mon compte',
  HOME: 'Home',
  PRODUCT_PAGE: 'Page produit',
  SEARCH_RESULT: 'Résultats de recherche',
  CONTACT_FAQ: 'Contact/FAQ',
  NEWS: 'Article',
}

/* ------- PARENT - LAYOUT ------- */

const VMainLayout = () => import(/* webpackChunkName: "MainLayout.route" */ './layouts/VMainLayout/VMainLayout.vue')

const VMinimalLayout = () =>
  import(/* webpackChunkName: "MinimalLayout.route" */ './layouts/VMinimalLayout/VMinimalLayout.vue')

/* ------- PARENT - CORE ------- */

const VHome = () => import(/* webpackChunkName: "Home.route" */ '@b2ag/marketing/src/application/views/VHome/VHome.vue')
const VSearch = () => import(/* webpackChunkName: "Search.route" */ './views/VSearch/VSearch.vue')
const VProduct = () => import(/* webpackChunkName: "Product.route" */ './views/VProduct/VProduct.vue')
const VNotFound = () => import(/* webpackChunkName: "NotFound.route" */ '@b2ag/views/src/VNotFound/VNotFound.vue')

const coreRoutes = [
  {
    path: 'produits/:productId',
    name: 'product',
    component: VProduct,
    meta: {
      analyticsPageType: PAGE_TYPE.PRODUCT_PAGE,
    },
  },
  {
    path: 'recherche',
    name: 'search',
    component: VSearch,
    meta: {
      analyticsPageType: PAGE_TYPE.SEARCH_RESULT,
    },
  },
  {
    path: 'category/:slug',
    name: 'category',
    component: VSearch,
    props: (route) => {
      return { categorySlug: route.params.slug }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.SEARCH_RESULT,
    },
  },
]

/* ------- CONTEXTS - AUTHENTIFICATION ------- */

const authRoutes = [
  {
    path: '/oauth',
    component: VMinimalLayout,
    meta: {
      doNotStoreRoute: true,
      analyticsPageType: PAGE_TYPE.AUTH,
    },
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          doNotStoreRoute: true,
        },
        beforeEnter(_to, _, next) {
          const authService = useAuthService()
          authService.login()
          next()
        },
      },
      {
        path: 'login/extranet',
        name: 'login-extranet',
        meta: {
          doNotStoreRoute: true,
        },
        beforeEnter(to, _, next) {
          localStorage.setItem('extranetPartnerId', to.query.partner as string)
          localStorage.setItem('extranetMembershipNumber', to.query.membershipNumber as string)
          const authService = useAuthService()
          authService.login({ target: 'EXTRANET' })
          next()
        },
      },
      {
        path: 'callback',
        name: 'auth-callback',
        component: VAuthCallback,
        meta: {
          inscriptionFlow: true,
          doNotStoreRoute: true,
        },
        props: () => {
          return { targetRoute: locationStorage.afterLoginPath, router, auth: useAuthService() }
        },
        beforeEnter(to, _, next) {
          if (to.query.error_description) {
            next({ name: 'auth-callback-error', query: { errorCode: to.params.error_description } })
          }
          next()
        },
      },
      {
        path: 'error',
        name: 'auth-callback-error',
        meta: {
          inscriptionFlow: true,
          doNotStoreRoute: true,
          private: false,
        },
        component: VAuthCallbackError,
        props: (route) => {
          return { errorCode: route.query.errorCode, auth: useAuthService(), appName: i18n._('aladin') }
        },
      },
    ],
  },
]

/* ------- CONTEXTS - ARTICLES ------- */

const VUniversePage = () =>
  import(
    /* webpackChunkName: "UniversePage.route" */ '@b2ag/articles/src/application/view/VUniversePage/VUniversePage.vue'
  )
const VArticlePage = () =>
  import(
    /* webpackChunkName: "VArticlePage.route" */ '@b2ag/articles/src/application/view/VArticlePage/VArticlePage.vue'
  )
const VHubPage = () =>
  import(/* webpackChunkName: "HubPage.route" */ '@b2ag/articles/src/application/view/VHubPage/VHubPage.vue')
const VHubDirectory = () =>
  import(
    /* webpackChunkName: "HubDirectory.route" */ '@b2ag/articles/src/application/view/VHubDirectory/VHubDirectory.vue'
  )

const articlesRoutes = [
  {
    path: '/univers',
    component: VMainLayout,
    children: [{ path: ':slug', name: 'univers', component: VUniversePage }],
  },
  {
    path: '/article',
    component: VMainLayout,
    children: [{ path: ':slug', name: 'article-page', component: VArticlePage }],
  },
  {
    path: '/actualites',
    component: VMainLayout,
    children: [{ path: ':slug', name: 'hub-page', component: VHubPage }],
  },
  {
    path: '/actualites-agricoles',
    component: VMainLayout,
    children: [
      { path: '', name: 'annuaire', component: VHubDirectory },
      { path: ':slug', name: 'annuaire-secondary', component: VHubDirectory },
    ],
  },
]

/* ------- MICROFRONT - MON COMPTE ------- */

const accountRoutes = [
  {
    name: 'account',
    path: 'mon-compte',
    component: {
      render: (h) => h('router-view'),
    },
    redirect: { name: 'user' },
    meta: {
      private: true,
      analyticsPageType: PAGE_TYPE.ACCOUNT,
    },
    children: [
      {
        path: '',
        name: 'user',
        component: VMicroFrontProvider,
        props: () => {
          return {
            microFrontName: 'my-account',
            routeName: '',
            router,
            analyticService: (() => useAnalyticService())(),
          }
        },
      },
      {
        path: 'technico-commerciaux',
        name: 'techsales',
        component: VMicroFrontProvider,
        props: () => {
          return {
            microFrontName: 'my-account',
            routeName: 'technico-commerciaux',
            router,
            analyticService: (() => useAnalyticService())(),
          }
        },
      },
      {
        path: 'mes-listes',
        name: 'purchase-orders',
        component: VMicroFrontProvider,
        props: () => {
          return {
            microFrontName: 'my-account',
            routeName: 'mes-listes',
            router,
            analyticService: (() => useAnalyticService())(),
          }
        },
      },
      {
        path: 'changer-de-cooperative',
        name: 'switch-profile',
        component: VMicroFrontProvider,
        props: () => {
          return {
            microFrontName: 'my-account',
            routeName: 'changer-de-cooperative',
            router,
            analyticService: (() => useAnalyticService())(),
          }
        },
      },
      {
        path: 'commandes',
        name: 'orders',
        component: VMicroFrontProvider,
        props: () => {
          return {
            microFrontName: 'my-account',
            routeName: 'commandes',
            router,
            analyticService: (() => useAnalyticService())(),
          }
        },
      },
      {
        path: 'commande/:orderId',
        name: 'order',
        redirect: { name: 'order-detail' },
      },
      {
        path: 'commande/:orderId',
        name: 'order-detail',
        component: VMicroFrontProvider,
        props: (props) => {
          return {
            microFrontName: 'my-account',
            routeName: `commande/${props.params.orderId}`,
            router,
            analyticService: (() => useAnalyticService())(),
          }
        },
      },
    ],
  },
  {
    path: 'liste/:purchaseOrderId',
    name: 'purchase-order-details',
    component: VMicroFrontProvider,
    props: (props) => {
      return {
        microFrontName: 'shopping-list',
        routeName: `agri/purchase-orders/${props.params.purchaseOrderId}`,
        router,
        analyticService: (() => useAnalyticService())(),
      }
    },
    meta: {
      private: true,
    },
  },
]

/* ------- MICROFRONT - PANIER ------- */

const cartRoutes = [
  {
    path: 'panier',
    name: 'cart',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'cart',
        routeName: '',
        router,
        callbackForMessages: (data: DataForParent) => callbacksFromCartMicroFrontIframe(data),
        analyticService: (() => useAnalyticService())(),
      }
    },
    meta: {
      private: true,
      analyticsPageType: PAGE_TYPE.CART,
    },
  },
]

/* ------- MICROFRONT - CHECKOUT ------- */

const checkoutRoutes = [
  {
    path: '/achats/:checkoutId',
    name: 'buy-layout',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'checkout',
        routeName: `agri/${router.currentRoute.params.checkoutId}`,
        router,
        callbackForMessages: (data: DataForParent) => callbacksFromCheckoutMicroFrontIframe(data, store),
        analyticService: (() => useAnalyticService())(),
      }
    },
    meta: {
      private: true,
      analyticsPageType: PAGE_TYPE.PURCHASES,
    },
  },
]

/* ------- MICROFRONT - LANDING ------- */

const landingPagesRoutes = [
  {
    path: '/pages',
    component: VMainLayout,
    children: [
      {
        path: 'partenaires',
        name: 'landing-page-partner',
        component: VMicroFrontProvider,
        props: () => {
          return {
            microFrontName: 'landing',
            viewName: 'partner',
            metaInfos: {
              title: i18n._('LANDING_PARTNER_TITLE'),
              description: i18n._('LANDING_PARTNER_DESCRIPTION'),
            },
            router,
            analyticService: (() => useAnalyticService())(),
          }
        },
      },
      {
        path: 'fournisseurs',
        name: 'landing-page-provider',
        component: VMicroFrontProvider,
        props: () => {
          return {
            microFrontName: 'landing',
            viewName: 'provider',
            metaInfos: {
              title: i18n._('LANDING_PROVIDER_TITLE'),
              description: i18n._('LANDING_PROVIDER_DESCRIPTION'),
            },
            router,
            analyticService: (() => useAnalyticService())(),
          }
        },
      },
    ],
  },
]

const landingMainRoutes = [
  {
    path: 'aide',
    name: 'contact',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'landing',
        viewName: 'aide',
        router,
        analyticService: (() => useAnalyticService())(),
      }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.CONTACT_FAQ,
    },
  },
  {
    path: 'a-propos',
    name: 'about',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'landing',
        viewName: 'about',
        analyticService: (() => useAnalyticService())(),
      }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.CONTACT_FAQ,
    },
  },
  {
    path: 'nos-partenaires',
    name: 'partners',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'landing',
        viewName: 'partners',
        metaInfos: {
          title: i18n._('PAGE_NOS_PARTENAIRES_TITRE'),
          description: i18n._('PAGE_NOS_PARTENAIRES_DESCRIPTION'),
        },
        analyticService: (() => useAnalyticService())(),
      }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.CONTACT_FAQ,
    },
  },
  {
    path: 'nos-marques',
    name: 'brands',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'landing',
        viewName: 'brands',
        router,
        metaInfos: {
          title: i18n._('LANDING_BRAND_TITLE'),
          description: i18n._('LANDING_BRAND_DESCRIPTION'),
        },
        analyticService: (() => useAnalyticService())(),
      }
    },
  },
  {
    path: 'modes-de-livraison',
    name: 'delivery-modes',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'landing',
        viewName: 'livraisons',
        metaInfos: {
          title: i18n._('LANDING_DELIVERY_METHODS_TITLE'),
          description: i18n._('LANDING_DELIVERY_METHODS_DESCRIPTION'),
        },
        analyticService: (() => useAnalyticService())(),
      }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.CONTACT_FAQ,
    },
  },
  {
    path: 'modes-de-paiement',
    name: 'payment-modes',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'landing',
        viewName: 'paiements',
        metaInfos: {
          title: i18n._('LANDING_PAYMENT_METHODS_TITLE'),
          description: i18n._('LANDING_PAYMENT_METHODS_DESCRIPTION'),
        },
        analyticService: (() => useAnalyticService())(),
      }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.CONTACT_FAQ,
    },
  },
]

/* ------- MICROFRONT - LEGALS ------- */

const legalsMainRoutes = [
  {
    path: 'conditions',
    name: 'legals',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'legals',
        viewName: 'cgu',
      }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.CONTACT_FAQ,
    },
  },
  {
    path: 'politique-de-confidentialite',
    name: 'privacy',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'legals',
        viewName: 'privacy',
      }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.CONTACT_FAQ,
    },
  },
  {
    path: 'mentions-legales',
    name: 'legal-notice',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'legals',
        viewName: 'legal-notice',
      }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.CONTACT_FAQ,
    },
  },
  {
    path: 'cgv',
    name: 'cgv',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'legals',
        viewName: 'cgv',
        router,
      }
    },
    meta: {
      analyticsPageType: PAGE_TYPE.CONTACT_FAQ,
    },
  },
]

const legalsAcceptCGU = [
  {
    path: 'accept-cgu',
    name: 'accept-cgu',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'legals',
        viewName: 'accept-cgu',
        callbackForMessages: (data: DataForParent) => callbacksFromLegalsMicroFrontIframe(data, router),
      }
    },
    meta: {
      inscriptionFlow: true,
      private: true,
      doNotStoreRoute: true,
    },
  },
]

/* ------- CONTEXTS - INSCRIPTION ------- */

const VAddCooperative = () =>
  import(/* webpackChunkName: "AddCooperative.route" */ './views/VAddCooperative/VAddCooperative.vue')
const VProfileConfirmation = () =>
  import(/* webpackChunkName: "ProfileCOnfirmation.route" */ './views/VProfileConfirmation/VProfileConfirmation.vue')

const signInRoutes = [
  {
    path: '/inscription',
    component: VMinimalLayout,
    children: [
      {
        path: '',
        name: 'register',
        async beforeEnter() {
          const auth = useAuthService()
          await auth.signin()
        },
        meta: {
          inscriptionFlow: true,
          doNotStoreRoute: true,
          analyticsPageType: PAGE_TYPE.SIGN_UP,
        },
      },
      {
        path: 'ajouter-une-cooperative',
        name: 'account-add-cooperative',
        meta: {
          inscriptionFlow: true,
          private: true,
          doNotStoreRoute: true,
        },
        component: VAddCooperative,
      },
      ...legalsAcceptCGU,
    ],
  },
  {
    path: '/profils',
    component: VMinimalLayout,
    children: [
      {
        path: 'confirmation',
        name: 'profile-confirmation',
        component: VProfileConfirmation,
        meta: {
          inscriptionFlow: true,
          doNotStoreRoute: true,
          analyticsPageType: PAGE_TYPE.PROFILE,
        },
      },
    ],
  },
  {
    path: '/inscription-v2',
    name: 'inscription-v2',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'signup',
        router,
      }
    },
    meta: {
      disabled: true,
    },
  },
  {
    path: '/reset-password-v2',
    name: 'reset-password-v2',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'signup',
        routeName: 'reset-password',
        router,
      }
    },
  },
  {
    path: '/validation-email-v2',
    name: 'validation-email-v2',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'signup',
        routeName: 'email-signup-verification',
        router,
      }
    },
    meta: {
      disabled: true,
    },
  },
  {
    path: '/inscription-v2-callback',
    name: 'inscription-v2-callback',
    component: VAuthCallback,
    meta: {
      inscriptionFlow: true,
      doNotStoreRoute: true,
      disabled: true,
    },
    props: () => {
      return { targetRoute: '/association-exploitation-v2', router, auth: useAuthService() }
    },
  },
  {
    path: '/association-exploitation-v2',
    name: 'association-exploitation-v2',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'signup',
        routeName: 'association-exploitation',
        router,
      }
    },
    meta: {
      disabled: true,
    },
  },
]

/* ------- MICROFRONT - FARMI APP APPRO ------- */

const farmiAppApproRoutes = [
  {
    path: 'farmi/app/appro/categories',
    name: 'farmi-app-appro-categories',
    component: VMicroFrontProvider,
    beforeEnter(_, __, next) {
      if (!isWebViewFarmi()) {
        next({ name: 'home' })
      } else {
        next()
      }
    },
    props: () => {
      return {
        microFrontName: 'farmi-app-appro',
        viewName: 'categories',
        router,
      }
    },
  },
]

/* -------------------------------------------- */

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    ...signInRoutes,
    ...authRoutes,
    ...checkoutRoutes,
    ...landingPagesRoutes,
    ...articlesRoutes,
    {
      path: '/',
      component: VMainLayout,
      children: [
        ...cartRoutes,
        ...legalsMainRoutes,
        ...accountRoutes,
        ...landingMainRoutes,
        ...farmiAppApproRoutes,
        ...coreRoutes,
        {
          path: '',
          name: 'home',
          component: VHome,
          beforeEnter(_, __, next) {
            if (isFarmi && isWebViewFarmi()) {
              next({ name: 'farmi-app-appro-categories' })
            } else {
              next()
            }
          },
          meta: {
            analyticsPageType: PAGE_TYPE.HOME,
          },
          props: () => {
            return {
              appFrontType: getAppContext().appFrontType,
            }
          },
        },
        {
          path: '*',
          name: 'not-found',
          component: VNotFound,
        },
      ],
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

async function routerBeforeEach(storeInstance, to, from, next) {
  if (to.meta.disabled) {
    return next({ name: 'not-found' })
  }

  if (!to.meta.doNotStoreRoute) {
    let { path } = to

    if (to.name === 'search') {
      path = to.fullPath.replace('&forceLogin=true', '').replace('?forceLogin=true', '')
    }

    locationStorage.storeLocation(path)
  }

  const authService = useAuthService()
  const isAuthenticated = await authService.isAuthenticated()

  if (!isAuthenticated && (to.meta.private || to.query?.forceLogin)) {
    await authService.login()
    // doLogin redirects to auth0, so the following should not matter. Just make
    // sure to end the function.
    return false
  }

  if (isAuthenticated) {
    if (!storeInstance.getters.isStoreInitialized) {
      await storeInstance.dispatch('fetchUser')
      storeInstance.commit('SET_IS_STORE_INITIALIZED', true)
    }

    if (!to.meta.inscriptionFlow) {
      if (!storeInstance.getters.hasConfirmedProfile) {
        if (to.name !== 'account-add-cooperative') {
          next({ name: 'account-add-cooperative' })
        } else {
          next()
        }
        return true
      }

      try {
        await authService.checkUserAuthSession()
      } catch (error: any) {
        if (error.message === AuthErrorKeys.IS_NOT_AUTHENTICATED) {
          await authService.login()
          return false
        }
        if (error.message === AuthErrorKeys.INVALID_SCOPE) {
          next({ name: 'auth-callback-error', query: { errorCode: error.message } })
          return true
        }
      }
    }

    // CGU verification
    if (!storeInstance.getters.isCGUFarmerAccepted && to.name !== 'accept-cgu') {
      next({ name: 'accept-cgu' })
      return true
    }
  }

  try {
    if (from.name === checkoutRoutes[0].name) await routingCancelCheckout(from.params.checkoutId, storeInstance)
  } catch (error) {}

  return next()
}

async function routerAfterEach(storeInstance, to, from) {
  const metaData = getAnalyticsMetaDataPayload(to, from, storeInstance)
  useAnalyticService().trackEvent('page_view', metaData)

  const authService = useAuthService()
  const isAuthenticated = await authService.isAuthenticated()

  if (
    from.name === 'oauth-callback' &&
    from.params.profile_id &&
    isAuthenticated &&
    !storeInstance.getters.isStoreInitialized
  ) {
    await storeInstance.dispatch('fetchUser')
    storeInstance.commit('SET_IS_STORE_INITIALIZED', true)

    const currentProfile = storeInstance.getters.user.profiles.find((p) => p.id === from.params.profile_id)
    await storeInstance.dispatch('switchProfile', currentProfile)
  }

  setTimeout(() => {
    window.prerenderReady = true
  }, 100)
}

router.beforeEach((to, from, next) => routerBeforeEach(store, to, from, next))
router.afterEach((to, from) => routerAfterEach(store, to, from))

export const getRouter = () => router

export default router
