<template>
  <div id="all" :class="environmentClasses()">
    <router-view id="app" />
    <p v-if="!isOnline" class="app__offline-message">Vous êtes actuellement hors ligne</p>
  </div>
</template>

<script lang="ts">
import { provideMediaService } from '@b2ag/medias'
import { provideRouter } from '@b2ag/composables'
import { provideStore } from '@b2ag/store'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { provideFocusIndex } from '@b2ag/composables/src/catalog/useDiscount'
import { getAppTypeLocale } from '@b2ag/locale'
import { isWebViewFarmi } from '@b2ag/farmi-app-appro-tools'
import { mapGetters } from 'vuex'
import { getDefaultSEODescription } from '@b2ag/utils/src/SEO/description'
import { provideAddressReactivitySettings } from '@b2ag/soufflet-pricer'
import { currentAppBrand } from '@b2ag/app-brands'
import { provideCategoryDescription } from '@b2ag/marketing/src/application/composables/useCategoryDescription'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { useCategoriesSlugs } from '@b2ag/search/src/composables/useCategoriesSlugs'
import { getStore } from './store/store'
import { getRouter } from './router'
import { makeAddressReactivitySettings } from './contexts/soufflet-pricer'

const SANDBOX_ENVIRONMENT_NAMES = ['DEV', 'TEST', 'QUA', 'COOP', 'OPS']
const appTypeLocale = getAppTypeLocale(currentAppBrand)

export default {
  name: 'App',
  computed: {
    ...mapGetters(['isOnline']),
  },
  setup() {
    const store = getStore()
    const environmentName = window.env.ENVIRONMENT_NAME || ''

    function isSandboxEnvironment() {
      return SANDBOX_ENVIRONMENT_NAMES.includes(environmentName)
    }
    function environmentClasses() {
      if (!isSandboxEnvironment()) {
        return ['all--production']
      }
      const classes = ['all--sandbox']
      if (environmentName.length > 0) {
        classes.push(`all--${environmentName.toLowerCase()}`)
      }
      return classes
    }

    window.env.APP_CONTEXT = currentAppBrand
    window.env.FRONT_CONTEXT = 'agri'

    provideStore(getStore())
    provideRouter(getRouter())
    const mediaApiClient = createAxiosClientWithAladinBearer(`${window.env.MEDIA_API_HOST}/v1`, {
      callerId: buildCallerId(),
    })
    provideMediaService(mediaApiClient)
    provideFocusIndex()
    provideCategoryDescription()
    provideAddressReactivitySettings(makeAddressReactivitySettings(store))

    useCategoriesSlugs()

    // XJU - 30/01/2024 : pas trouvé mieux pour le moment
    if (isWebViewFarmi()) {
      const styles = document.createElement('style')
      styles.setAttribute('type', 'text/css')
      styles.textContent = `:root { --mobile-header-height: 58px !important; }`
      document.head.appendChild(styles)
    }

    const styles = document.createElement('style')
    styles.setAttribute('type', 'text/css')
    styles.textContent = `:root { --desktop-header-height: 66px !important; }`
    document.head.appendChild(styles)

    return {
      environmentClasses,
    }
  },
  metaInfo() {
    return {
      title: appTypeLocale.metadata.title,
      link: [
        // @ts-ignore à voir au passage en Vue3
        { vmid: 'canonical', rel: 'canonical', href: `${window.location.origin}${this.$route.fullPath}` },
        {
          rel: 'alternate',
          hreflang: 'fr',
          // @ts-ignore à voir au passage en Vue3
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
      meta: [
        { vmid: 'robots', name: 'robots', content: 'index, follow' },
        { vmid: 'description', name: 'description', content: getDefaultSEODescription(currentAppBrand) },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@b2ag/themes/common/lib/bootstrap/index.scss';
@import '@b2ag/themes/common/styles/index.scss';
@import '@b2ag/themes/common/styles/root.scss';
@import '@b2ag/themes/current/styles/fonts.css';

html {
  height: 100%;
}

body {
  min-height: 100vh;
  height: 100%;
  color: $color-black;
  background-color: $color-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
  overflow: hidden;
}

p {
  margin: 0;
}

a {
  color: $color-main;

  &:hover {
    color: $color-main-dark;
  }
}

h5,
.h5 {
  font-size: 2rem;
}

hr {
  margin: 0 1rem;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.btn {
  border-radius: $border-radius-1;
  box-shadow: none;
  padding: 15px;
  font-size: 1em;
  line-height: 1em;

  &:hover {
    box-shadow: none;
  }
  @media (min-width: $breakpoint-sm) {
    padding: 12px;
  }
}

.btn-full-screen {
  width: 100%;
  @media (min-width: $breakpoint-sm) {
    margin: 0 1em 0 0;
    width: auto;
  }
}

.adn-button--fullwidth {
  width: 100%;
}

.adn-button .spinner-border {
  vertical-align: middle;
  margin-left: 10px;
}

#app {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#all {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

#minimal {
  flex: 1;

  .main {
    flex: 1;
    height: 100vh;
    background: $color-white;
    margin-top: 0;
    display: flex;
    align-items: center;

    & > div {
      flex: 1;
    }
  }
}

.app__offline-message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: darkorange;
  color: $color-white;
  text-align: center;
  margin: 0;
  line-height: 30px;
  z-index: 1000;
}

.main-title {
  font-size: 1.2em;
  font-weight: bold;
  color: $color-black;
  text-align: left;
  @media (min-width: $breakpoint-lg) {
    text-align: center;
  }

  strong {
    text-transform: uppercase;
    color: $color-main;
  }
}

.container--full-width-on-mobile {
  @media (max-width: 991px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    margin: 0;
    max-width: 100%;
  }
}

.content-block {
  @media (min-width: $breakpoint-md) {
    margin-bottom: 2em;
  }
}

.form-check-label {
  cursor: pointer;
}

option:disabled {
  color: $color-inactive-light;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  color: $color-black;
  padding: 9px 12px;
  height: 41px;
  font-size: $font-size-s;
  border: 1px solid $color-inactive-light;
  border-radius: $border-radius-1;
  background-color: $color-white;
  box-shadow: none;

  &::placeholder,
  & input::placeholder {
    font-size: $font-size-s;
    color: $color-inactive-dark;
    @media (min-width: $breakpoint-sm) {
      font-size: 1.6rem;
    }
  }

  &:focus {
    box-sizing: border-box;
    border: 1px solid $color-black;
    box-shadow: none;
    outline: transparent;
  }

  &.login-form__input-error {
    border-color: $color-error;
  }
}

textarea {
  min-height: 64px;
  height: auto;
}

#all .v-select {
  background: $color-white;

  input,
  &.vs--searchable .vs__dropdown-toggle {
    cursor: pointer;
  }

  .vs__dropdown-menu {
    box-shadow: none;
    border: 1px solid $color-black;
    padding: 0;
  }

  .vs__dropdown-footer {
    border-top: 1px solid $color-text-light;
  }

  .vs__dropdown-footer,
  .vs__dropdown-option {
    padding: 15px 20px;
    line-height: 22px !important;
    color: $color-black;

    &:hover {
      background: $color-background;
      color: $color-black;
    }

    span {
      color: $color-text-light;
    }

    a {
      color: $color-black;
    }
  }

  .vs__dropdown-option--highlight {
    background: $color-background;
  }

  .vs__selected {
    line-height: 33px;
  }

  .vs__search,
  .vs__search:focus {
    line-height: 33px;
  }

  .vs__dropdown-toggle {
    border: 1px solid $color-black;
  }

  .vs__actions {
    padding: 4px 15px 0 3px;

    .vs__open-indicator {
      fill: $color-black;
    }

    .vs__clear {
      fill: $color-black;
    }
  }
}

.placeholder-loading {
  background-color: $color-inactive-light;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    background: linear-gradient(90deg, $color-inactive-light 0%, $color-background 50%, $color-inactive-light 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: 1.5s placeholder-loading ease-in-out infinite;
  }
}
@keyframes placeholder-loading {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.darken {
  filter: grayscale(1);
}
</style>
