import type { DispatchOptions } from 'vuex'
import type { ShippingAddressesDto } from '@aladin/universcoop-interfaces'
import {
  getLocalStorageJSON,
  removeLocalStorage,
  setLocalStorageJSON,
  type StorageKey,
} from '@b2ag/utils/src/localStorage'
import { useFeatureFlipping } from '@b2ag/feature-flipping'
import { getServicesForStore, type Commit, type RootGetters } from '.'
import type { AddressesStoreActions, AddressesStoreGetters } from './interfaces/addresses.interface'
import type { ShopStoreActions } from './interfaces/shop.interface'

const SHIPPING_ADDRESS_KEY: StorageKey<ShippingAddressesDto> = 'current_address'

const savedAddress = getLocalStorageJSON(SHIPPING_ADDRESS_KEY)

type State = { addresses: ShippingAddressesDto[]; currentAddress?: ShippingAddressesDto }
const initialState: State = {
  addresses: savedAddress ? [savedAddress] : [],
  currentAddress: savedAddress ?? undefined,
}

function hasCityCode(address: ShippingAddressesDto): boolean {
  return !!address.city_code
}

function setCurrentAddress(state: State, currentAddress: ShippingAddressesDto) {
  state.currentAddress = currentAddress
  if (currentAddress) setLocalStorageJSON(SHIPPING_ADDRESS_KEY, currentAddress)
}

function deleteCurrentAddress(state: State) {
  state.currentAddress = undefined
  removeLocalStorage(SHIPPING_ADDRESS_KEY)
}

const mutations = {
  setCurrentAddress,
  deleteCurrentAddress,
  setAddresses(state: State, addresses: ShippingAddressesDto[] = []) {
    state.addresses = addresses
    if (!state.currentAddress) {
      const addressWithCityCode = addresses.find(hasCityCode)
      if (addressWithCityCode) {
        setCurrentAddress(state, addressWithCityCode)
      } else {
        deleteCurrentAddress(state)
      }
    }
  },
}

type GetterImplementation<T extends keyof AddressesStoreGetters> = (
  state: State,
  getters: AddressesStoreGetters,
) => AddressesStoreGetters[T]

const getters: { [K in keyof AddressesStoreGetters]: GetterImplementation<K> } = {
  addressesWithCityCode(state) {
    return state.addresses.filter(hasCityCode)
  },
  currentAddress(state) {
    return state.currentAddress
  },
  currentCityCode(state) {
    return state.currentAddress?.city_code
  },
}

type ShopStoreActionsWhitelist = 'resetShop'

type Dispatch = <K extends keyof AddressesStoreActions>(
  key: K,
  payload: Parameters<AddressesStoreActions[K]>[0],
  options: DispatchOptions,
) => ReturnType<AddressesStoreActions[K]>

type ExternalShopDispatch = <K extends ShopStoreActionsWhitelist>(
  key: `shop/${K}`,
  payload: Parameters<ShopStoreActions[K]>[0],
  options: { root: true },
) => ReturnType<ShopStoreActions[K]>

interface Context {
  commit: Commit<typeof mutations>
  state: State
  dispatch: Dispatch & ExternalShopDispatch
  rootGetters: Record<RootGetters, any>
  getters: AddressesStoreGetters
}

type ActionImplementation<T extends keyof AddressesStoreActions> = (
  context: Context,
  ...args: Parameters<AddressesStoreActions[T]>
) => ReturnType<AddressesStoreActions[T]>

const actions: { [K in keyof AddressesStoreActions]: ActionImplementation<K> } = {
  async fetchAddresses({ commit, rootGetters }) {
    const { isShippingAddressEnabled, updateFeatureFlipping } = useFeatureFlipping()
    if (process.env.VUE_APP_CONTEXT !== 'farmi') return
    await updateFeatureFlipping(rootGetters.currentCooperativeId)
    if (!isShippingAddressEnabled.value) return
    const partnerId = rootGetters.currentCooperativeId
    const membershipCode = rootGetters.targetCustomerMembership || rootGetters.currentMembershipNumber

    const { membershipApiClient } = getServicesForStore()
    const addresses = await membershipApiClient
      ?.findShippingAddressesForAMembershipAndAPartner(partnerId, membershipCode)
      .catch(() => [])
    commit('setAddresses', addresses)
  },
  async setCurrentAddress({ commit, dispatch, rootGetters }, currentAddress: ShippingAddressesDto) {
    const { isShippingAddressEnabled, updateFeatureFlipping } = useFeatureFlipping()
    if (process.env.VUE_APP_CONTEXT !== 'farmi') return
    await updateFeatureFlipping(rootGetters.currentCooperativeId)
    if (!isShippingAddressEnabled.value) return
    commit('setCurrentAddress', currentAddress)
    dispatch('shop/resetShop', undefined, { root: true })
  },
  async deleteCurrentAddress({ commit, rootGetters }) {
    const { isShippingAddressEnabled, updateFeatureFlipping } = useFeatureFlipping()
    if (process.env.VUE_APP_CONTEXT !== 'farmi') return
    await updateFeatureFlipping(rootGetters.currentCooperativeId)
    if (!isShippingAddressEnabled.value) return
    commit('deleteCurrentAddress')
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
}
