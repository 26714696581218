import { computed } from 'vue'
import type { DiscountApplication } from '@b2ag/pricing'
import { useDiscount } from './useDiscount'

export function useDiscountValue(
  application: Pick<DiscountApplication, 'offerIdList' | 'type' | 'getDiscount'>,
  offerId: string,
) {
  const discountApplication = computed(() => application)
  const { discountProjection } = useDiscount(discountApplication, offerId)
  return computed(() => application && application.getDiscount(discountProjection.value))
}
