import { Big } from 'big.js'

import type { LineItem } from '@b2ag/cart/src/lineItem'
import type { Offer } from '@b2ag/offers/src/offer'
import { getQuantityAlreadyInCart } from './quantity.business'
import type { Quota, SimplifiedQuotaDashboard } from './quota'

export function hasQuota(offer: Offer) {
  return !!offer.quota
}

export function computeQuotaAddableToCart(offer: Offer, lineItems: LineItem[]): number {
  if (!offer.quota) return Infinity

  const quantityAlreadyInCart = getQuantityAlreadyInCart(offer, lineItems)
  const measureFactor = offer.isSoldAsUnit ? 1 : offer.measureQuantity ?? 1
  const remaining = +Big(offer.quota.remaining).minus(Big(Math.max(0, quantityAlreadyInCart)).times(measureFactor))
  return Math.max(0, remaining)
}

export function isQuotaSufficientFor(offer: Offer, quotaRemaining: number, quantityMin: number) {
  return quotaRemaining >= (offer.isSoldAsUnit ? quantityMin : quantityMin * (offer.measureQuantity || 1))
}

export function getQuotaRemaining(offer: Offer) {
  return offer.quota?.remaining ?? Infinity
}

export function getQuotaAsUnit(quota, isSoldAsUnit, measureQuantity) {
  return isSoldAsUnit ? quota ?? Infinity : Math.floor((quota || Infinity) / (measureQuantity || 1))
}

export function isQuotaReached(value: number) {
  return value <= 0
}

export function numberOfReachedQuotas(quotas: Quota[]): number {
  return quotas.filter((quota) => isQuotaReached(quota.remaining)).length
}

export function numberOfActiveQuotas(quotas: Quota[]): number {
  return quotas.length - numberOfReachedQuotas(quotas)
}

export function computeQuotaSumByVariant(quotas: SimplifiedQuotaDashboard[]): SimplifiedQuotaDashboard[] {
  const quotaMapByVariant = new Map<string, SimplifiedQuotaDashboard>()
  quotas.forEach((quota) => {
    const aggregate = quotaMapByVariant.get(quota.variantId)
    if (aggregate) {
      aggregate.remaining += quota.remaining
      aggregate.initial += quota.initial
    } else quotaMapByVariant.set(quota.variantId, quota)
  })
  return Array.from(quotaMapByVariant.values())
}
