import { contentfulAssetUrlToUrl } from '@b2ag/articles/src/infra/mapper/url.mapper'
import type { HomeAdvertorial } from '../../domain/blocs/HomeAdvertorial'
import { BlocType } from '../../domain/BlocType'

export const mapAdvertorialsFromApi = (advertorials: any): HomeAdvertorial[] => {
  if (!advertorials) return [] as HomeAdvertorial[]
  return advertorials.map(mapAdvertorialFromApi).filter(Boolean)
}

export const mapAdvertorialFromApi = (advertorial: any): HomeAdvertorial | null => {
  if (!advertorial) return null
  return {
    blocType: BlocType.HOME_ADVERTORIAL,
    image: contentfulAssetUrlToUrl(advertorial.fields.image?.fields?.file?.url) || '',
    imageAlt: advertorial.fields.image?.fields?.description || '',
    tag: advertorial.fields.tag || '',
    title: advertorial.fields.title || '',
    content: advertorial.fields.content || '',
    url: advertorial.fields.url || '',
    anchorLabel: advertorial.fields.anchorLabel || '',
    color: advertorial.fields.color || '#0076b4',
    supplierName: advertorial.fields.supplierName || '',
  }
}
