export interface HubSpotFormCreation {
  formId: string
  targetId: string
  createdCallBack?: Function
}

export interface HubSpotFormHandler {
  formId: string
  onFormReady?: Function
  onFormSubmitted?: Function
}

declare const window: any

export function useHubspot() {
  const hubSpotHandler: HubSpotFormHandler = {
    formId: '',
    onFormReady: undefined,
    onFormSubmitted: undefined,
  }

  function initHBSPTForm(hubSpotFormCreation: HubSpotFormCreation) {
    const { formId, targetId, createdCallBack } = hubSpotFormCreation

    ;(window as any).hbspt.forms.create({
      portalId: '5942000',
      formId,
      target: `#${targetId}`,
    })

    if (createdCallBack && typeof createdCallBack === 'function') {
      if (createdCallBack) createdCallBack()
    }
  }

  function createHubspotForm(hubSpotFormCreation: HubSpotFormCreation) {
    const hbsptScript = document.getElementById('hbspt-script')

    if (!hbsptScript) {
      // Reset subscribers
      ;(window as any).$hsScriptOnLoad = []
      // Inject hbsptScript in DOM
      const script = document.createElement('script')
      script.defer = true
      script.id = 'hbspt-script'
      script.src = 'https://js.hsforms.net/forms/v2.js'
      script.onload = () => {
        ;(window as any).$hsScriptOnLoad.forEach((callback) => callback())
      }

      document.body.appendChild(script)
    }

    if (!(window as any).hbspt) {
      ;(window as any).$hsScriptOnLoad.push(() => {
        initHBSPTForm(hubSpotFormCreation)
      })
    } else {
      initHBSPTForm(hubSpotFormCreation)
    }
  }

  function handleHubspotEvent(event) {
    if ((event as any).data.id !== hubSpotHandler.formId) {
      return
    }
    if ((event as any).data.type === 'hsFormCallback' && (event as any).data.eventName === 'onFormReady') {
      if (hubSpotHandler.onFormReady) hubSpotHandler.onFormReady()
      return
    }
    if ((event as any).data.type === 'hsFormCallback' && (event as any).data.eventName === 'onFormSubmitted') {
      if (hubSpotHandler.onFormSubmitted) hubSpotHandler.onFormSubmitted()
    }
  }

  function initHbFormListener(hubSpotFormHandler: HubSpotFormHandler) {
    const { formId, onFormReady, onFormSubmitted } = hubSpotFormHandler
    if (hubSpotHandler.formId) {
      return
    }
    hubSpotHandler.formId = formId
    hubSpotHandler.onFormReady = onFormReady
    hubSpotHandler.onFormSubmitted = onFormSubmitted
    if (!window.hbListeners) {
      window.hbListeners = {}
    }

    // Avoid duplicated listener
    if (!window.hbListeners[formId]) {
      window.addEventListener('message', handleHubspotEvent)
      window.hbListeners[formId] = true
    }
  }

  function removeHbFormListener() {
    if (window.hbListeners?.[hubSpotHandler.formId]) {
      window.hbListeners[hubSpotHandler.formId] = false
    }

    hubSpotHandler.formId = ''
    window.removeEventListener('message', handleHubspotEvent)
  }

  // Method to force hubspot react component to clean up before destroy
  function unmountReactComponent(formId) {
    const windowReactForms = (window as any)?.HSFR?.FORM_COMPONENTS
    if (windowReactForms?.length) {
      const formsIndex = windowReactForms.findIndex((node) => node.props.formId === formId)
      if (formsIndex !== -1 && windowReactForms[formsIndex]) {
        windowReactForms[formsIndex].componentWillUnmount()
        windowReactForms.splice(formsIndex, 1)
      }
    }
  }

  return {
    createHubspotForm,
    initHbFormListener,
    removeHbFormListener,
    unmountReactComponent,
    hubSpotHandler,
  }
}
