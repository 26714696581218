import Discount from '../Discount'

import DiscountScaleSlice, { type DiscountScaleSliceProps } from './DiscountScaleSlice'

export class DiscountScale {
  public readonly slices: DiscountScaleSlice[]

  constructor(slices: DiscountScaleSliceProps[]) {
    this.slices =
      (Array.isArray(slices) &&
        slices.map(
          (slice) =>
            new DiscountScaleSlice({
              min: slice.min,
              max: slice.max,
              discount: slice.discount,
            }),
        )) ||
      []
  }

  getDiscount(quantity: number): Discount | undefined {
    const sliceFromQuantity = this.slices.find((discount) => {
      const matchMin = quantity >= discount.min
      const matchMax = !discount.max || (discount.max && quantity < discount.max)
      return matchMin && matchMax
    })
    return sliceFromQuantity?.discount
  }
}
