import { getCityCodeFromPricerOfferId, isSouffletPricerOfferId } from '@b2ag/soufflet-pricer'
import type { LineItem } from './lineItem'

export interface PreShipping {
  store_code: string
  line_items: LineItem[]
  kind: string
}

export interface Cart {
  line_items: LineItem[]
  pre_shippings: PreShipping[]
}

export function getPreShippingStoreCode(cart: Cart) {
  return cart
    ? cart.pre_shippings?.find((preShipping) => preShipping.kind === 'click_and_collect')?.store_code
    : undefined
}

export function getPreShippingLineItemIds(cart: Cart) {
  return cart
    ? cart.pre_shippings
        ?.find((preShipping) => preShipping.kind === 'click_and_collect')
        ?.line_items?.map((lineItem) => lineItem.id)
    : []
}

export function containsPricerOffers(cart: Cart) {
  return cart?.line_items?.some((item) => isSouffletPricerOfferId(item.offer_id))
}

export function getCityCodeFromCart(cart: Cart) {
  const firstPricerOffer = cart?.line_items?.find((item) => isSouffletPricerOfferId(item.offer_id))
  return firstPricerOffer ? getCityCodeFromPricerOfferId(firstPricerOffer.offer_id) : null
}
