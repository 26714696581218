export function convertTag(tag: string): string {
  const accentCharacters = [
    'Á',
    'À',
    'Ã',
    'Â',
    'Ä',
    'á',
    'à',
    'ã',
    'â',
    'ä',
    'É',
    'È',
    'Ê',
    'Ë',
    'é',
    'è',
    'ê',
    'ë',
    'Í',
    'Ì',
    'Î',
    'Ï',
    'í',
    'ì',
    'î',
    'ï',
    'Ó',
    'Ò',
    'Ô',
    'Õ',
    'Ö',
    'ó',
    'ò',
    'ô',
    'õ',
    'ö',
    'Ú',
    'Ù',
    'Û',
    'Ü',
    'ú',
    'ù',
    'û',
    'ü',
    'Ç',
    'ç',
    'Ñ',
    'ñ',
    "'",
  ]

  const nonAccentedTag = tag
    .replace(/[-&/\\%@_+*.]/gm, ' ') // Replace special characters with space
    .replace(/\s\s+/g, ' ') // Remove multiple spaces between words, for edge cases like "johnson   johnson" whih is the result of "johnson & johnson"
    .split('')
    .filter((character) => !accentCharacters.includes(character))
    .join('')
    .trim()

  // Convert normal string to camelCased string
  return nonAccentedTag
    .split(' ')
    .map((item, index) => {
      if (index === 0) {
        return item.toLocaleLowerCase()
      }

      return `${item[0].toUpperCase()}${item.substring(1).toLocaleLowerCase()}`
    })
    .join('')
}
