import { ProductHit } from '@b2ag/search'
import { filterAllowedLogisticOffers } from '@b2ag/stock'
import { CooperativeProduct, ProductRedirectError } from '@b2ag/product/src/services/product'
import { Offer, variantId } from '@b2ag/offers/src/offer'
import { ShopStoreActions } from '@b2ag/store/src/interfaces/shop.interface'
import groupBy from '@b2ag/utils/src/group-by'
import { FETCH_PRODUCT_ERROR } from '@b2ag/product/src/services/product.service'
import {
  initialShopState,
  shopMutations,
  shopGetters,
  shopActions as globalShopActions,
  type ShopState,
  type ActionImplementation,
} from '@b2ag/store/src/shop.store'
import { discountService, offerService, productService, searchService } from '@/services'
import { getCartOfferIds, getMissingProductsIds } from './utils'

export type { ShopState }

const shopActions: { [K in keyof ShopStoreActions]: ActionImplementation<K> } = {
  ...globalShopActions,
  async fetchMissingProducts({ getters, commit, rootGetters, state }, productIdList) {
    try {
      commit('START_SHOP_LOADING')
      const missingProductsIds = getMissingProductsIds(
        productIdList,
        getters.getAlgoliaProductsByIds(productIdList),
        getters.getProductsForPrice,
      )
      const { currentCooperativeId, currentMembershipNumber } = rootGetters

      if (missingProductsIds.length === 0) return

      const cityCode = rootGetters['addresses/currentCityCode']
      const [products, offers] = await Promise.all([
        productService.findProducts(missingProductsIds, currentCooperativeId),
        offerService.findOffers(missingProductsIds, currentCooperativeId, currentMembershipNumber, { cityCode }),
      ])
      commit('SET_PRODUCT_MAP', {
        productList: products.map((product) => ({ ...product, isCooperativeProduct: true })),
      })
      commit('SET_OFFER_LIST_BY_VARIANT', groupBy(variantId, offers))
      const discountList = offers.length
        ? await discountService.findDiscounts(
            offers.map((offer) => offer.offerId).concat(await getCartOfferIds(rootGetters, state)),
            currentCooperativeId,
          )
        : []
      commit('SET_DISCOUNT_MAP', discountList)
    } catch (err: any) {
      // TODO: better error handling
      // eslint-disable-next-line no-console
      console.error('Error while fetching missing products:', err)
      throw err
    } finally {
      commit('STOP_SHOP_LOADING')
    }
  },

  // SPECIFIC AGRI ACTIONS

  async fetchProduct({ commit, dispatch, rootGetters, getters, state }, { productId }) {
    const { currentCooperativeId, currentMembershipNumber } = rootGetters
    if (!currentCooperativeId) {
      throw new Error('CurrentCooperativeId is not defined')
    }
    if (!productId) {
      throw new Error('ProductId is not defined')
    }
    try {
      commit('START_SHOP_LOADING')
      commit('START_OFFERS_LOADING')
      commit('START_DISCOUNTS_LOADING')
      const cityCode = rootGetters['addresses/currentCityCode']
      const [product, offers]: [CooperativeProduct, Offer[]] = await Promise.all([
        productService.getCatalog(productId, currentCooperativeId),
        offerService.findOffers([productId], currentCooperativeId, currentMembershipNumber, { cityCode }),
      ])
      commit('SET_PRODUCT_MAP', { productList: [{ ...product, isCooperativeProduct: true }] })
      if (offers.length) {
        await dispatch('fetchCooperativeStores')

        filterAllowedLogisticOffers(getters.getCooperativeStores, product.variants, offers)
        commit('SET_OFFER_LIST_BY_VARIANT', groupBy(variantId, offers))
        commit('STOP_OFFERS_LOADING')
        const discountList = await discountService.findDiscounts(
          offers.map((offer) => offer.offerId).concat(await getCartOfferIds(rootGetters, state)),
          currentCooperativeId,
        )
        commit('SET_DISCOUNT_MAP', discountList)
        commit('STOP_DISCOUNTS_LOADING')
      }
    } catch (e: any) {
      if (e.message === FETCH_PRODUCT_ERROR) {
        commit('RESET_SHOP')
        throw new Error('product is not found')
      }
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      commit('STOP_DISCOUNTS_LOADING')
      commit('STOP_OFFERS_LOADING')
      commit('STOP_SHOP_LOADING')
    }
  },
  async fetchNationalProduct({ commit }, { productId }) {
    commit('START_SHOP_LOADING')
    try {
      const product = await productService.get(productId)
      commit('SET_PRODUCT_MAP', { productList: [{ ...product, isCooperativeProduct: false }], forPrice: true })
    } catch (error: any) {
      commit('RESET_SHOP')
      if (error instanceof ProductRedirectError) {
        throw error
      }
    } finally {
      commit('STOP_SHOP_LOADING')
    }
  },
  async fetchSupplierRelatedProducts({ commit, rootGetters, getters }, { product, nbItemsToFetch }) {
    if (getters.areSupplierProductsLoading) return

    commit('SET_SUPPLIER_PRODUCTS_LOADING', true)
    const { currentCooperativeId, currentMembershipNumber } = rootGetters
    try {
      const products = (
        await searchService.fetchSupplierRelatedProducts(product, !!currentCooperativeId, nbItemsToFetch)
      ).hits as ProductHit[]
      if (products?.length > 0) {
        const variantsMap = new Map(products.flatMap((prd) => prd.variants.map((variant) => [variant.id, variant])))
        const variantIds = Array.from(variantsMap.keys())
        const cityCode = rootGetters['addresses/currentCityCode']
        let offers = await offerService.findOfferBaseByVariants(
          variantIds,
          currentCooperativeId,
          currentMembershipNumber,
          { cityCode },
        )
        offers = offers.map((offer) => {
          const variant = variantsMap.get(offer[variantId])
          return variant
            ? {
                ...offer,
                isSoldAsUnit: variant.is_sold_as_unit,
                measureQuantity: variant.measure_quantity,
                measureUnit: variant.measure_unit,
              }
            : offer
        })

        const offersGroupByVariant = groupBy(variantId, offers)

        const discountList = await discountService.findDiscounts(
          offers.map((offer) => offer.offerId),
          currentCooperativeId,
        )

        const relatedProductIds = products.map((prd) => prd._id)
        commit('SET_ALGOLIA_MAP', products)
        commit('SET_SUPPLIER_PRODUCT_IDS_MAP', { productId: product._id, relatedProductIds })
        commit('SET_OFFER_LIST_BY_VARIANT', offersGroupByVariant)
        commit('SET_DISCOUNT_MAP', discountList)
      }
    } finally {
      commit('SET_SUPPLIER_PRODUCTS_LOADING', false)
    }
  },
  async fetchHighlightedProductList({ commit, dispatch, rootGetters }) {
    try {
      commit('START_SHOP_LOADING')
      const { currentCooperativeId, currentMembershipNumber } = rootGetters
      searchService.initSearchIndex(window.env.ALGOLIA_INDEX_COOP_PREFIX + rootGetters.currentCooperativeId)
      const highlightedProductList = (
        await searchService.fetchRandomAlgoliaProducts(!!currentCooperativeId, 'is_highlighted: "true"', 20)
      ).hits as ProductHit[]
      if (highlightedProductList?.length > 0) {
        const productIds = highlightedProductList.map((product) => product._id)
        const variantsMap = new Map(
          highlightedProductList.flatMap((product) => product.variants.map((variant) => [variant.id, variant])),
        )
        const variantIds = Array.from(variantsMap.keys())
        const cityCode = rootGetters['addresses/currentCityCode']
        let offers = await offerService.findOfferBaseByVariants(
          variantIds,
          currentCooperativeId,
          currentMembershipNumber,
          { cityCode },
        )

        offers = offers.map((offer) => {
          const variant = variantsMap.get(offer[variantId])
          if (variant) {
            return {
              ...offer,
              isSoldAsUnit: variant.is_sold_as_unit,
              measureQuantity: variant.measure_quantity,
              measureUnit: variant.measure_unit,
            }
          }
          return offer
        })

        const offersGroupByVariant = groupBy(variantId, offers)

        commit('SET_ALGOLIA_MAP', highlightedProductList)
        commit('SET_HIGHLIGHTED_PRODUCT_ID_LIST', productIds)
        commit('SET_OFFER_LIST_BY_VARIANT', offersGroupByVariant)

        await dispatch('fetchCooperativeStores')

        const discountList = await discountService.findDiscounts(
          offers.map((offer) => offer.offerId),
          currentCooperativeId,
        )
        commit('SET_DISCOUNT_MAP', discountList)
      }
    } catch (err: any) {
      // TODO: better error handling
      // eslint-disable-next-line no-console
      console.log('Error while fetching highlighted products:', err)
      throw err
    } finally {
      commit('STOP_SHOP_LOADING')
    }
  },
  async fetchOffSeasonProductList({ commit, rootGetters }) {
    try {
      commit('START_SHOP_LOADING')
      const { currentCooperativeId, currentMembershipNumber } = rootGetters
      searchService.initSearchIndex(window.env.ALGOLIA_INDEX_COOP_PREFIX + rootGetters.currentCooperativeId)
      const offSeasonProductList = (
        await searchService.fetchRandomAlgoliaProducts(!!currentCooperativeId, 'is_off_season: "true"', 5)
      ).hits as ProductHit[]
      if (offSeasonProductList?.length > 0) {
        const productIds = offSeasonProductList.map((product) => product._id)
        const variantsMap = new Map(
          offSeasonProductList.flatMap((product) => product.variants.map((variant) => [variant.id, variant])),
        )
        const variantIds = Array.from(variantsMap.keys())
        const cityCode = rootGetters['addresses/currentCityCode']
        let offers = await offerService.findOfferBaseByVariants(
          variantIds,
          currentCooperativeId,
          currentMembershipNumber,
          { cityCode },
        )
        offers = offers.map((offer) => {
          const variant = variantsMap.get(offer[variantId])
          if (variant) {
            return {
              ...offer,
              isSoldAsUnit: variant.is_sold_as_unit,
              measureQuantity: variant.measure_quantity,
              measureUnit: variant.measure_unit,
            }
          }
          return offer
        })
        const offersGroupByVariant = groupBy(variantId, offers)
        // DEV NOTE : we MUST do a refacto to separate the calls for national products and cooperative variant products
        commit('SET_ALGOLIA_MAP', offSeasonProductList)
        commit('SET_OFF_SEASON_PRODUCT_ID_LIST', productIds)
        commit('SET_OFFER_LIST_BY_VARIANT', offersGroupByVariant)
        const discountList = await discountService.findDiscounts(
          offers.map((offer) => offer.offerId),
          currentCooperativeId,
        )
        commit('SET_DISCOUNT_MAP', discountList)
      }
    } catch (err: any) {
      // TODO: better error handling
      // eslint-disable-next-line no-console
      console.log('Error while fetching highlighted products:', err)
      throw err
    } finally {
      commit('STOP_SHOP_LOADING')
    }
  },
  async fetchPushedProduct({ dispatch, rootGetters }, { productId }) {
    if (rootGetters.isAuthenticated) {
      await dispatch('fetchProduct', { productId })
    } else {
      await dispatch('fetchNationalProduct', { productId })
    }
  },
}

const shop = {
  namespaced: true,
  state: () => initialShopState,
  mutations: shopMutations,
  actions: shopActions,
  getters: shopGetters,
}
export default shop
