Object.defineProperty(window, 'indexedDB', {
  // @ts-expect-error à définir dans le contexte de l'application
  value: window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB,
  configurable: true,
  enumerable: true,
  writable: true,
})

// prefixes of window.IDB objects

// @ts-expect-error à définir dans le contexte de l'application
window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction

// @ts-expect-error à définir dans le contexte de l'application
window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange
