export interface DiscountProps {
  unit: string
  isDiscountAppliedInMeasureUnit: boolean
  discount: number
}

export default class Discount {
  public readonly unit: string

  public readonly isDiscountAppliedInMeasureUnit: boolean

  public readonly discount: number

  constructor(discountProps: DiscountProps) {
    this.unit = discountProps.unit
    this.isDiscountAppliedInMeasureUnit = discountProps.isDiscountAppliedInMeasureUnit
    this.discount = discountProps.discount
  }

  appliedInMeasureUnit(variantMeasureQuantity: number): Discount {
    if (this.isDiscountAppliedInMeasureUnit || this.unit === '%') return this
    return new Discount({
      unit: this.unit,
      isDiscountAppliedInMeasureUnit: true,
      discount: this.discount / variantMeasureQuantity,
    })
  }

  appliedInUnit(variantMeasureQuantity: number): Discount {
    if (!this.isDiscountAppliedInMeasureUnit || this.unit === '%') return this
    return new Discount({
      unit: this.unit,
      isDiscountAppliedInMeasureUnit: false,
      discount: this.discount * variantMeasureQuantity,
    })
  }
}
