import type { AxiosInstance } from '@b2ag/axios'
import { createAxiosClientAnonymous } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'

class MembershipConfirmationService {
  api: AxiosInstance

  constructor(userApiHost: string) {
    this.api = createAxiosClientAnonymous(`${userApiHost}/v1`, { callerId: buildCallerId() })
  }

  create({ confirmation_token: confirmationToken }) {
    return this.api
      .patch('/membership-confirmations', {
        confirmation_token: confirmationToken,
      })
      .then(({ data }) => data)
  }
}

export default MembershipConfirmationService
