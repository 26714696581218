export default {
  expert: 'expert',
  interlocuteurs: 'interlocuteurs',
  metadata: {
    title: 'Farmi | Vente en ligne de produits et services agricoles',
    defaultDescription:
      'Farmi, site E-commerce agricole, trouvez ce dont vous avez besoin pour votre exploitation : semences, services agricoles, équipements agricoles…',
  },
  nomApp: 'Farmi',
  'p-app-search': {
    metadata: {
      titleAllProducts: 'Nos gammes',
      title: '{$familyOrCategory} - Consultez notre offre sur Farmi',
      productGroupName: 'notre gamme {$familyOrCategory}',
      defaultProductGroupeName: 'nos gammes',
      description:
        'Vous êtes agriculteur ? Consulter {$productGroupName} et commandez en ligne sur Farmi, vente de produits et services agricoles en ligne.',
    },
  },
  'p-product-layout': {
    'asterisk-info': 'Selon les modalités appliquées par Soufflet Agriculture. Voir les conditions générales de vente.',
  },
  tech: 'commercial',
  tc: 'ARC',
  'v-product': {
    metadata: {
      title: {
        default: '{$firstCategory}, {$name} - Farmi',
      },
      description: {
        default: 'Consultez la fiche produit Farmi pour {$name}, {$firstCategory}, et commandez en quelques clics.',
      },
    },
  },
  'v-user': {
    'number-label': 'N° client',
    'user-section-label': 'Votre compte Farmi',
  },
  partenaire: {
    exploitation: 'exploitation',
    serviceClient: 'service-client',
    negoce: 'service client',
    entrepriseNoArticle: 'entreprise',
    entreprise: 'une entreprise',
    choix: 'Farmi',
  },
  adherent: {
    choix: 'client',
    client: 'client',
  },
}
