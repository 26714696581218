import { computeVariantListWithoutOffer, computeVariantListWithOffer } from '@b2ag/product/src/services/shop.business'
import { computed } from 'vue'
import { useStore } from '@b2ag/store'

// eslint-disable-next-line import/prefer-default-export
export function useShop(productId) {
  const store = useStore()
  const product = computed(() => store.getters['shop/getProductById'](productId))
  const discountList = computed(() => store.getters['shop/discountList'](productId))
  const variantListWithoutOffer = computed(() => computeVariantListWithoutOffer(product.value))
  const variantListWithOffer = computed(() => computeVariantListWithOffer(product.value))
  const packagingOptions = store.getters['shop/packagingOptions'](productId)
  return {
    discountList,
    packagingOptions,
    variantListWithoutOffer,
    variantListWithOffer,
  }
}
