import { contentfulAssetUrlToUrl } from '@b2ag/articles/src/infra/mapper/url.mapper'
import type { Category } from '../../domain/blocs/Category'
import { BlocType } from '../../domain/BlocType'

export const mapCategoriesFromApi = (categories): Category[] => {
  if (!categories) return [] as Category[]
  return categories.map((category) => {
    const categoryObj = {
      title: '',
      backgroundImage: '',
      backgroundColor: '',
      textColor: '',
      url: '',
      picto: '',
    } as Category
    if (category?.fields) {
      categoryObj.blocType = BlocType.CATEGORY
      categoryObj.title = category.fields.title || ''
      categoryObj.backgroundImage = contentfulAssetUrlToUrl(category.fields.backgroundImage?.fields?.file?.url) || ''
      categoryObj.backgroundColor = category.fields.backgroundColor || ''
      categoryObj.textColor = category.fields.textColor || ''
      categoryObj.url = category.fields.url || ''
      categoryObj.picto = contentfulAssetUrlToUrl(category.fields.picto?.fields?.file?.url) || ''
    }
    return categoryObj
  })
}
