import { mapArticleThumbnails } from '@b2ag/articles/src/infra/mapper/articleThumbnails.mapper'
import type { Home } from '../../domain/Home'
import { mapBannerFromApi } from './homeBanner.mapper'
import { mapMainHeaderFromApi } from './homeMainHeader.mapper'
import { mapArticlesFromApi } from './homeArticles.mapper'
import { mapCategoriesFromApi } from './homeCategories.mapper'
import { mapAdvertorialsFromApi } from './homeAdvertorials.mapper'
import { mapPushProductsFromApi } from './homePushProducts.mapper'

export function contentfulToHome(dto: any): Home {
  return {
    mainHeader: mapMainHeaderFromApi(dto.fields.mainHeader),
    articles: mapArticlesFromApi(dto.fields.articles),
    categories: mapCategoriesFromApi(dto.fields.categories),
    advertorials: mapAdvertorialsFromApi(dto.fields.advertorials),
    pushArticles: mapArticleThumbnails(dto.fields.pushArticles),
    pushProducts: mapPushProductsFromApi(dto.fields.pushProducts),
    banners:
      !dto.fields.banners || dto.fields.banners?.length === 0
        ? []
        : dto.fields.banners.map((banner) => mapBannerFromApi(banner)),
  } as Home
}
