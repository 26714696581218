import { computed, type ComputedRef } from 'vue'
import {
  computeQuotaAddableToCart,
  hasQuota,
  getQuotaRemaining,
  getQuotaAsUnit,
  isQuotaReached,
} from '@b2ag/quota/src/quota.business'
import { useCart } from '../useCart'

// eslint-disable-next-line import/prefer-default-export
export interface IUseQuota {
  hasQuota: ComputedRef<boolean>
  quotaReference: ComputedRef<number | undefined>
  quotaInitial: ComputedRef<number | undefined>
  quotaRemaining: ComputedRef<number>
  quotaRemainingAsUnit: ComputedRef<number>
  quotaAddableToCart: ComputedRef<number>
  isQuotaReached: ComputedRef<boolean>
}

export function useQuota(offer): IUseQuota {
  const { cartOrList } = useCart()

  const quotaReference = computed(() => offer.quota?.reference)
  const quotaInitial = computed(() => offer.quota?.initial)
  const quotaAddableToCart = computed(() => computeQuotaAddableToCart(offer, cartOrList.value?.line_items))

  return {
    hasQuota: computed(() => hasQuota(offer)),
    quotaReference,
    quotaInitial,
    quotaRemaining: computed(() => getQuotaRemaining(offer)),
    quotaRemainingAsUnit: computed(() =>
      getQuotaAsUnit(offer?.quota?.remaining, offer.isSoldAsUnit, offer.measureQuantity),
    ),
    quotaAddableToCart,
    isQuotaReached: computed(() => isQuotaReached(quotaAddableToCart.value)),
  }
}
