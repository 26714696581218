import { contentfulAssetUrlToUrl } from '@b2ag/articles/src/infra/mapper/url.mapper'
import type { HomeArticle } from '../../domain/blocs/HomeArticle'
import { BlocType } from '../../domain/BlocType'

export const mapArticlesFromApi = (articles: any): HomeArticle[] => {
  if (!articles || !articles?.length) return [] as HomeArticle[]
  return articles.map((article) => {
    return {
      blocType: BlocType.HOME_ARTICLE,
      title: article.fields.title,
      backgroundImage: contentfulAssetUrlToUrl(article.fields.backgroundImage.fields.file.url),
      url: article.fields.url,
      tags: article.fields.tags,
      buttonLabel: article.fields.buttonLabel,
      anchorLabel: article.fields.anchorLabel,
    }
  })
}
