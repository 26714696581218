const FARMI_USER_AGENT_BASE = 'Farmi-Mobile'

export function getOSVersion(userAgent: string): string | undefined {
  const match = userAgent.match(/Farmi-Mobile-(Android|iOS)-(\d+\.\d+)/)
  if (!match) {
    return undefined
  }
  return match[2]
}

export function isAndroid(userAgent: string): boolean {
  return userAgent.startsWith(`${FARMI_USER_AGENT_BASE}-Android`)
}

export function isIOS(userAgent: string): boolean {
  return userAgent.startsWith(`${FARMI_USER_AGENT_BASE}-iOS`)
}

export function isWebViewFarmi(userAgent: string = window.navigator.userAgent): boolean {
  return userAgent.startsWith(FARMI_USER_AGENT_BASE)
}
