import { provide, inject } from 'vue'
import type { AxiosInstance } from '@b2ag/axios'
import { MediaService } from './medias.service'

// eslint-disable-next-line symbol-description
export const mediaServiceSymbol = Symbol('mediaService')

export function provideMediaService(mediaApiClient: AxiosInstance) {
  provide(mediaServiceSymbol, new MediaService(mediaApiClient))
}

export function useMediaService(): MediaService {
  const mediaService = inject<MediaService>(mediaServiceSymbol)
  if (!mediaService) {
    throw new Error(
      'MediaService not defined. Please ensure that "provideMediaService" has been called in a parent component "setup" method, or that useMediaService has been called in a "setup" method',
    )
  }
  return mediaService
}
