import { AuthErrorKeys } from '../../../domain/entity/AuthErrorKeys'

export interface ContentOAuthError {
  title: string
  description: string
}

export function contentOAuthErrorFactory(appName: string, key?: AuthErrorKeys): ContentOAuthError {
  if (key === AuthErrorKeys.COOPERATIVE_NOT_FOUND_ERROR) {
    return {
      title: 'Votre partenaire ne permet pas de se connecter depuis son Extranet.',
      description: `Vous pouvez contacter ${appName} ou votre partenaire pour leur demander d'ajouter cette fonctionnalité. En attendant veuillez vous connecter manuellement.`,
    }
  }
  if (key === AuthErrorKeys.MEMBERSHIP_NOT_FOUND_ERROR) {
    return {
      title: `Votre partenaire n'a pas transmis votre profil à ${appName}`,
      description: `Pour utiliser ${appName}, vous devez d'abord demander directement à votre partenaire d'activer le service.`,
    }
  }
  if (key === AuthErrorKeys.ZERO_PROFILE_FOUND_ERROR) {
    return {
      title: 'Vous y êtes presque !',
      description: `Il ne reste plus qu’à vous inscrire. Pour bénéficier de la connexion automatique, cliquez sur le lien ci-dessous.`,
    }
  }
  if (key === AuthErrorKeys.TOO_MANY_PROFILE_FOUND_ERROR) {
    return {
      title: 'Impossible de vous connecter automatiquement',
      description: `Plusieurs comptes ${appName} sont associés au même numéro d'adhérent ou de client. Veuillez vous connecter manuellement.`,
    }
  }
  if (key === AuthErrorKeys.EXTRANET_AUTHENTICATION_FAILED) {
    return {
      title: 'Impossible de vous connecter automatiquement',
      description: "L'extranet de votre partenaire ne vous a pas authentifié.",
    }
  }
  if (key === AuthErrorKeys.INVALID_SCOPE) {
    return {
      title: 'Interdit',
      description: "Vous n'êtes pas autorisé à accéder à ce site",
    }
  }
  if (key === AuthErrorKeys.STATE_INVALID) {
    return {
      title: 'Authentification impossible',
      description: `${appName} n'est pas à l'origine de cette requête`,
    }
  }
  return {
    title: 'Une erreur est survenue',
    description: 'Nous sommes désolé pour la gêne occasionnée. Veuillez réessayer plus tard.',
  }
}
