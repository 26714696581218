import { NATIONAL_SCOPE } from '@b2ag/marketing/src/domain/utils/constantes'
import ContentfulClient from '@b2ag/contentful'
import type { Page } from '../../domain/Page'
import type { RequestPageOption } from '../../domain/RequestPageOption'
import { contentfulContentToArticle } from '../mapper/article.mapper'
import type { Article } from '../../domain/Article'
import type { ArticlesFilter, IArticleRepository } from '../../domain/repository/IArticleRepository'

export class ArticleRepository implements IArticleRepository {
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(public readonly client: ContentfulClient) {}

  async getArticle(slug): Promise<Article> {
    return this.client.api
      .getEntries({
        include: 4,
        content_type: 'richBox',
        'fields.slug': slug,
        limit: 1,
      })
      .then((content) => {
        if (content.total === 0) {
          throw new Error(`article ${slug} inconnu`)
        }
        return contentfulContentToArticle(content.items[0])
      })
  }

  async getArticlesByFilter(
    filter: ArticlesFilter = {
      supplierName: '',
      tags: [],
      excludeTags: [],
    },
    partnerScope: string = NATIONAL_SCOPE,
    options: RequestPageOption = { itemPerPage: 20, page: 1 },
  ): Promise<Page<Article>> {
    const query = {
      include: 1,
      content_type: 'richBox',
      'fields.partnerFilter[in]': partnerScope,
      limit: options.itemPerPage,
      skip: (options.page - 1) * options.itemPerPage,
      order: '-fields.publishedAt',
    }
    if (filter.supplierName) query['fields.supplierName'] = filter.supplierName
    if (filter.tags?.length) query['metadata.tags.sys.id[in]'] = filter.tags.join(',')
    if (filter.excludeTags?.length) query['metadata.tags.sys.id[nin]'] = filter.excludeTags.join(',')

    return this.client.api.getEntries<any>(query).then((content) => {
      const articles = content.items.map(contentfulContentToArticle)

      return {
        total: content.total,
        itemPerPage: options.itemPerPage,
        current: options.page,
        items: articles,
      } as Page<Article>
    })
  }
}
