import type { AxiosInstance } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { mapListFromApi } from './discount.mapper'

export type IDiscountService = {
  findDiscounts(offerIdList: string[], cooperativeId: number): Promise<any[]>
}

export class DiscountService {
  api: AxiosInstance

  constructor({ discountApiHost }) {
    this.api = createAxiosClientWithAladinBearer(discountApiHost, { callerId: buildCallerId() })
  }

  async findDiscounts(offerIdList: string[], cooperativeId: number) {
    if (!offerIdList.length) {
      return []
    }
    const response = await this.api.post(
      `/v2/discounts-for-sale/query`,
      { discountable_ids: offerIdList },
      { params: { seller_id: cooperativeId } },
    )
    return mapListFromApi(response.data, cooperativeId)
  }
}
