import type { Article } from '@b2ag/articles/src/domain/Article'
import { contentfulContentToArticle } from '@b2ag/articles/src/infra/mapper/article.mapper'
import ContentfulClient from '@b2ag/contentful'
import type { IArticlesRepository } from '../../domain/repository/IArticlesRepository'

export class ArticlesRepository implements IArticlesRepository {
  // eslint-disable-next-line no-useless-constructor
  constructor(public readonly client: ContentfulClient) {}

  getAllByPartnerId(partnerId: number): Promise<Article[]> {
    return this.client.api
      .getEntries({
        content_type: 'richBox',
        'fields.partnerId': partnerId,
        order: '-fields.publishedAt',
      })
      .then((content) => {
        return content.items.map(contentfulContentToArticle)
      })
  }

  getAnchoredArticlesByPartnerId(partnerId: number): Promise<Article[]> {
    return this.client.api
      .getEntries({
        content_type: 'richBox',
        'fields.partnerId[exists]': false,
        'fields.partnerFilter[in]': partnerId,
        order: '-fields.publishedAt',
      })
      .then((content) => {
        return content.items.map(contentfulContentToArticle)
      })
  }
}
