import { currentAppBrand } from '@b2ag/app-brands'
import { AuthContext, contexts, getAuthConfigFor, provideAuthService, useAuthService } from '@b2ag/auth'
import { createAxiosClientAnonymous, createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { providePartner } from '@b2ag/composables/src/partner/usepartner'
import AdnAnchorNavigation from '@b2ag/design-system/src/components/AdnAnchorNavigation/AdnAnchorNavigation.vue'
import AdnButton from '@b2ag/design-system/src/components/AdnButton/AdnButton.vue'
import AdnDiscountBanner from '@b2ag/design-system/src/components/AdnDiscountBanner/AdnDiscountBanner.vue'
import AdnImage from '@b2ag/design-system/src/components/AdnImage/AdnImage.vue'
import AdnInput from '@b2ag/design-system/src/components/AdnInput/AdnInput.vue'
import AdnLogoAladin from '@b2ag/design-system/src/components/AdnLogoAladin/AdnLogoAladin.vue'
import AdnModal from '@b2ag/design-system/src/components/AdnModal/AdnModal.vue'
import { isWebViewFarmi } from '@b2ag/farmi-app-appro-tools'
import { provideFeatureFlipping, useFeatureFlipping } from '@b2ag/feature-flipping'
import i18n from '@b2ag/locale/src/plugins/i18n.plugin'
import { loadLocaleFor } from '@b2ag/locale/src/plugins/loadLocalFor'
import type { GtmSupportOptions } from '@b2ag/plugin-aladin-gtm'
import { initAnalyticsIdf, useAnalyticService } from '@b2ag/plugin-aladin-gtm'
import { initSentry } from '@b2ag/sentry'
import '@b2ag/utils/src/indexedDB'
import Vue from 'vue'
import VueSocialSharing from 'vue-social-sharing'
import { getConfig } from '@b2ag/micro-front-tools'
import AxeptioPlugin from '@b2ag/plugin-axeptio'
import { initServicesForStore } from '@b2ag/store'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import AsyncComputed from 'vue-async-computed'
import InstantSearch from 'vue-instantsearch'
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta'
import VueObserveVisibility from 'vue-observe-visibility'
import VueScrollTo from 'vue-scrollto'
import HubspotPlugin from '@b2ag/plugin-hubspot'
import App from './App.vue'
import router from './router'
import {
  discountService,
  membershipApiClient,
  offerService,
  recommendationService,
  searchService,
  stockService,
  storeService,
} from './services'
import store from './store/store'

loadLocaleFor(currentAppBrand)

if (!Array.prototype.flatMap) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, 'flatMap', {
    value(f) {
      return this.map(f).reduce((acc, val) => acc.concat(val), [])
    },
  })
}

Vue.config.productionTip = false

Vue.use(i18n)
Vue.use(VueObserveVisibility)
Vue.use(InstantSearch)
Vue.use(AsyncComputed)
Vue.use(VueScrollTo)
Vue.use(VueMeta)
Vue.use(VueSocialSharing)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  lazyComponent: true,
  observer: true,
})

Vue.component('adn-button', AdnButton)
Vue.component('adn-input', AdnInput)
Vue.component('adn-image', AdnImage)
Vue.component('adn-modal', AdnModal)
Vue.component('adn-anchor-navigation', AdnAnchorNavigation)
Vue.component('adn-discount-banner', AdnDiscountBanner)
Vue.component('adn-logo-aladin', AdnLogoAladin)

async function mountApp() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  } as any).$mount('#app')
}

function getGtmSupportOptions(): GtmSupportOptions {
  const ids: string[] = []
  if (window.env?.GTM_ID_B2AG) ids.push(window.env.GTM_ID_B2AG)
  if (window.env?.GTM_ID_LEGACY) ids.push(window.env.GTM_ID_LEGACY)
  if (window.env?.GTM_ID_SPECIFIC) ids.push(window.env.GTM_ID_SPECIFIC)

  return {
    id: ids,
    enabled: true,
    defer: true,
    debug: window.env?.ENVIRONMENT_NAME === 'DEV',
    loadScript: true,
  }
}

function initAxeptioAndGTM() {
  if (window.location.hostname.includes('localhost')) return

  // if you want to disable analytics, just remove AXEPTIO_CLIENT_ID from your local config. (see obvious line below)
  if (window.env?.AXEPTIO_CLIENT_ID) {
    Vue.use(AxeptioPlugin, {
      clientId: window.env?.AXEPTIO_CLIENT_ID,
      base: window.env?.AXEPTIO_BASE,
      domain: window.env?.AXEPTIO_DOMAIN,
      flushStoredEvents: () => useAnalyticService()?.flushStoredEvents(),
    })

    initAnalyticsIdf(getGtmSupportOptions(), useAuthService)
  }
}

function initHubspot() {
  if (!window.env?.HUBSPOT_PORTAL_ID || window.location.hostname.includes('localhost')) return
  Vue.use(HubspotPlugin, {
    portalId: window.env.HUBSPOT_PORTAL_ID,
  })
}

function initFeatureFlipping() {
  const featureFlippingApiClient = createAxiosClientAnonymous(`${window.env.FEATURE_FLIPPING_API_HOST}`)
  provideFeatureFlipping(featureFlippingApiClient, 'agri', currentAppBrand)
}

function initPartners() {
  const izanamiApiClient = createAxiosClientAnonymous(`${window.env.FEATURE_FLIPPING_API_HOST}`)
  const userApiHost = getConfig('USER_API_HOST')
  const partnerApiClient = createAxiosClientWithAladinBearer(`${userApiHost}/v1/cooperatives`, {
    callerId: buildCallerId(),
  })
  providePartner(izanamiApiClient, partnerApiClient, currentAppBrand)
}

async function updateFeatureFlipping() {
  // eslint-disable-next-line import/no-named-as-default-member
  await useFeatureFlipping().updateFeatureFlipping(store.getters.currentCooperativeId)
}

async function initAuthService() {
  const ctx: AuthContext = { name: contexts.DEFAULT }

  if (isWebViewFarmi()) {
    ctx.name = contexts.FARMI_APP_APPRO
  }

  await provideAuthService(
    {
      audience: window.env.AUTH0_AUDIENCE!,
      domain: window.env.AUTH0_DOMAIN!,
      redirectUri: `${window.env.FRONT_AGRI_URL}/oauth/callback`,
      authority: window.env.AUTH0_DOMAIN!,
      clientId: window.env.AUTH0_CLIENT_ID!,
      requiredScopes: getAuthConfigFor('agri'),
      extranetConnection: window.env.AUTH0_EXTRANET_CONNECTION,
    },
    ctx,
  )
}

;(async (): Promise<void> => {
  try {
    initSentry({
      router,
      params: {
        Vue,
        dsn: window.env.SENTRY_DSN,
        enabled: window.env.SENTRY_ENABLED === 'yes',
        environment: window.env.ENVIRONMENT_NAME,
        release: window.env.VERSION,
        tracesSampleRate: window.env.SENTRY_TRACE_SAMPLE_RATE,
        allowUrls: window.env.SENTRY_ALLOW_URLS,
      },
    })

    await initAuthService()

    initAxeptioAndGTM()
    initFeatureFlipping()
    initHubspot()
    initPartners()

    initServicesForStore({
      membershipApiClient,
      searchService,
      stockService,
      recommendationService,
      storeService,
      discountService,
      offerService,
    })

    await updateFeatureFlipping()
    await mountApp()
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
})()
