import { currentAppBrand, isAladin } from '@b2ag/app-brands'
import { getConfig } from '@b2ag/micro-front-tools'
import isEmpty from 'lodash/isEmpty'
import toInteger from 'lodash/toInteger'
import toString from 'lodash/toString'

export function mapFromApi(cooperative = {}) {
  const {
    id,
    name,
    customer_support_opening_hours: openingHours = '',
    customer_support_phone_no: phone = '',
    customer_support_email: email = '',
  } = cooperative

  let image = `https://res.cloudinary.com/${getConfig(
    'CLOUDINARY_BUCKET_NAME',
  )}/image/upload/h_88,w_88,q_auto,f_auto,dpr_auto/logos/cooperatives/cooperative_${cooperative.id}.jpg`

  if (isEmpty(toString(id))) return {}

  if (!isAladin) {
    image = `https://res.cloudinary.com/${getConfig(
      'CLOUDINARY_BUCKET_NAME',
    )}/image/upload/h_88,w_auto,q_auto,f_auto,dpr_auto,c_pad/logos/b2ag/${currentAppBrand}_add_coop.png`
  }

  return {
    id: toString(id),
    name,
    image,
    customerSupport: { openingHours, phone, email },
  }
}

export function mapToApi(cooperative = {}) {
  const { id, name, membershipNumber } = cooperative

  if (isEmpty(toString(id))) return {}

  return {
    id: toInteger(id),
    name,
    membership_number: membershipNumber,
  }
}
