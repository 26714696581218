import type { AxiosInstance } from '@b2ag/axios'

import { mapOfferFromOffersForPriceApi, mapOfferFromOffersForSaleApi } from '@b2ag/product/src/services/product.mapper'
import type { Offer, OfferBase } from '../offer'

interface FindOffersOptions {
  cityCode?: string
}

const defaultOptions: FindOffersOptions = {}

const mappers = {
  'for-price': mapOfferFromOffersForPriceApi,
  'for-sale': mapOfferFromOffersForSaleApi,
} as const

type ResultType = {
  'for-price': OfferBase
  'for-sale': Offer
}

type Args = [partnerId: number, membershipCode: string, options?: FindOffersOptions]

export type IOfferService = {
  findOffers(productIds: string[], ...args: Args): Promise<Offer[]>
  findOffersByVariants(variantIds: string[], ...args: Args): Promise<Offer[]>
  findOffersBase(productIds: string[], ...args: Args): Promise<OfferBase[]>
  findOfferBaseByVariants(variantIds: string[], ...args: Args): Promise<OfferBase[]>
  findByOfferIdList(offerIds: readonly string[], partnerId: number, membershipCode: string): Promise<Offer[]>
}

export default class OfferService {
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly api: Pick<AxiosInstance, 'get'>) {}

  findOffers(productIds: string[], ...args: Args): Promise<Offer[]> {
    return this.#findOffers('for-sale', productIds, 'product_id', ...args)
  }

  findOffersByVariants(variantIds: string[], ...args: Args): Promise<Offer[]> {
    return this.#findOffers('for-sale', variantIds, 'variant_id', ...args)
  }

  findOffersBase(productIds, ...args: Args): Promise<OfferBase[]> {
    return this.#findOffers('for-price', productIds, 'product_id', ...args)
  }

  findOfferBaseByVariants(variantIds: string[], ...args: Args): Promise<OfferBase[]> {
    return this.#findOffers('for-price', variantIds, 'variant_id', ...args)
  }

  async #findOffers<T extends keyof typeof mappers>(
    endpoint: T,
    ids: string[],
    idParamName: 'variant_id' | 'product_id',
    partnerId: number,
    membershipCode: string,
    options = defaultOptions,
  ): Promise<ResultType[T][]> {
    if (!partnerId) return []
    const idParam = ids.join('.')
    const { data } = await this.api.get(`/v3/offers-${endpoint}`, {
      params: Object.assign(
        { partner_id: partnerId, [idParamName]: idParam },
        options.cityCode && { city_code: options.cityCode },
      ),
      headers: { 'X-Beneficiary-Id': membershipCode },
    })
    return data.map(mappers[endpoint])
  }

  async findByOfferIdList(offerIds: readonly string[], partnerId: number, membershipCode: string): Promise<Offer[]> {
    const ids = offerIds.join('.')
    const { data } = await this.api.get('/v3/offers-for-sale', {
      params: { partner_id: partnerId, ids },
      headers: { 'X-Beneficiary-Id': membershipCode },
    })
    return data.map(mapOfferFromOffersForSaleApi)
  }
}
