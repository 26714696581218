// eslint-disable-next-line import/extensions, import/no-unresolved
import type { Article } from '@b2ag/articles/src/domain/Article'
import type { IArticlesRepository } from '../repository/IArticlesRepository'

export interface GetArticlesByPartnerIdUseCase {
  (partnerId: number): Promise<Article[]>
}

export interface CategoryDescriptionUseCaseFactory {
  getArticlesByPartnerIdUseCaseFactory(articlesRepository: IArticlesRepository): GetArticlesByPartnerIdUseCase
}

export function getArticlesByPartnerIdUseCaseFactory(articlesRepository: IArticlesRepository) {
  return async (partnerId: number): Promise<Article[]> => {
    return articlesRepository.getAllByPartnerId(partnerId)
  }
}
