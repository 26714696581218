
import { messages as fr_FR_aladin } from "./fr-FR-aladin/messages"
import { messages as fr_FR_farmi } from "./fr-FR-farmi/messages"
import { messages as fr_FR_episens } from "./fr-FR-episens/messages"
import { messages as fr_FR_emoji } from "./fr-FR-emoji/messages"

export default {
  "fr-FR_aladin": fr_FR_aladin,
  "fr-FR_farmi": fr_FR_farmi,
  "fr-FR_episens": fr_FR_episens,
  "fr-FR_emoji": fr_FR_emoji,
}
