import configList from '../config/iframes.json'

let _expectedHref
let _callback

function messageListener({ source, data }) {
  if (source.location.href.includes(_expectedHref)) _callback(data)
}

export function getMessageFromIframe(expectedHref: string, callback: any) {
  _expectedHref = expectedHref
  _callback = callback
  window.addEventListener('message', messageListener)
}

export function removeMessageFromIframe() {
  window.removeEventListener('message', messageListener)
}

export function postMessageToChild(childName: string, data: unknown) {
  const iframeElementId = configList[childName]?.elementId

  if (!iframeElementId) return

  const iframeElementDOM: HTMLIFrameElement | null = document.querySelector('#' + iframeElementId)

  if (!iframeElementDOM) return

  iframeElementDOM.contentWindow?.postMessage(data, '*')
}
