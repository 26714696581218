import { contentfulAssetUrlToUrl } from '@b2ag/articles/src/infra/mapper/url.mapper'
import type { HomePushProducts } from '../../domain/blocs/HomePushProducts'
import { BlocType } from '../../domain/BlocType'

export const mapPushProductsFromApi = (pushProducts: any): HomePushProducts | undefined => {
  if (!pushProducts) return undefined
  const pushProductsObj = {
    blocType: BlocType.PUSH_PRODUCTS,
    title: '',
    description: '',
    backgroundImage: '',
    backgroundColor: '',
    anchorLabel: '',
    buttonLabel: '',
    buttonUrl: '',
    logo: '',
    products: [],
  } as HomePushProducts
  if (pushProducts?.fields) {
    pushProductsObj.logo = pushProducts.fields.logo?.fields?.file?.url
      ? `https:${pushProducts.fields.logo?.fields?.file?.url}`
      : ''
    pushProductsObj.title = pushProducts.fields.title || ''
    pushProductsObj.description = pushProducts.fields.description || ''
    pushProductsObj.backgroundImage =
      contentfulAssetUrlToUrl(pushProducts.fields.backgroundImage?.fields?.file?.url) || ''
    pushProductsObj.backgroundColor = pushProducts.fields.backgroundColor || ''
    pushProductsObj.anchorLabel = pushProducts.fields.anchorLabel || ''
    pushProductsObj.buttonLabel = pushProducts.fields.buttonLabel || ''
    pushProductsObj.buttonUrl = pushProducts.fields.buttonUrl || ''
    let products = pushProducts.fields.products || ''
    products = products.split(/[,;]/).map((product) => product?.trim())
    pushProductsObj.products = products
  }
  return pushProductsObj
}
