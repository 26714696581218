import {
  type CooperativeProductResponse,
  type CooperativeVariantSchema,
  type OfferSchema,
  type OffersForPriceSchema,
  type OffersForSaleSchema,
  type ProductInPackSchema,
} from '@b2ag/types-api-schemas'
import { type EnhancedLogisticOfferForSale } from '@invivodf/module-logistic-offer-client-sdk'

import { type Offer, type OfferBase, type PaymentDueDate, variantId } from '@b2ag/offers/src/offer'
import { type Quota } from '@b2ag/quota/src/quota'
import { type OfferForQuota } from '@b2ag/quota/src/quota-config.business'
import { mapLogisticOffers } from '@b2ag/offers/src/mappers/logisticOffer.mapper'
import {
  type CooperativeProduct,
  type CooperativeVariant,
  type FlattenedPackProduct,
  type Product,
  PRODUCT_TYPES,
} from './product'
import { isOffSeason } from './product.business'
import { getProductDefaultImage160Px } from './products-images.constants'

export const defaultShippingWays = {
  pickup_point: {
    title: 'Livraison relais coopérative',
    kind: 'pickup_point',
  },
  farm: {
    title: 'Livraison à la ferme',
    kind: 'farm',
  },
  click_and_collect: {
    title: 'Retrait en magasin',
    kind: 'click_and_collect',
  },
}

function mapShippingWayFromApi(shippingWay: string): { kind: string; title: string } {
  return defaultShippingWays[shippingWay] || {}
}

export const mapOfferFromOffersForPriceApi = (
  offer: OffersForPriceSchema[0] = {} as OffersForPriceSchema[0],
): OfferBase => {
  const [, variantInvivoId, reference] = offer.offer_id.split(':')

  return {
    offerId: offer.offer_id,
    sellerCustomId: reference,
    priceType: offer.price_type,
    measurementPrice: offer.measurement_price,
    unitPrice: offer.unit_price,
    rpdUnit: offer.tax_rpd_unit,
    tvaUnitPrice: offer.tax_tva_unit_price,
    rpdUnitPrice: offer.tax_rpd_unit_price,
    [variantId]: variantInvivoId,
    productId: offer.product_id,
    isSoldAsUnit: offer.variant_is_sold_as_unit,
    quantityMax: offer.quantity_max,
    discountId: undefined,
    offerType: offer.type || '',
    allowedWithoutStock: offer.allowed_without_stock,
    stockManagementType: offer.variant_stock_management_type,
    measureQuantity: offer.variant_measure_quantity,
    measureUnit: offer.variant_measure_unit,
    quota: offer.quota as Quota,
  }
}

export const mapOfferFromOffersForSaleApi = (offer: OffersForSaleSchema[0] = {} as OffersForSaleSchema[0]): Offer => {
  const logisticOffers: EnhancedLogisticOfferForSale[] = mapLogisticOffers(offer)
  return Object.assign(mapOfferFromOffersForPriceApi(offer), {
    shippingStartDate: offer.shipping_start_date,
    shippingEndDate: offer.shipping_end_date,
    validityEndDate: offer.validity_end_date ? new Date(offer.validity_end_date) : undefined,
    taxTvaPercent: offer.tax_tva_percent,
    logisticOffers,
    quantityMin: offer.quantity_min,
    quantityStep: offer.quantity_step,
    intervalName: offer.interval_name,
    isTechExclusive: offer.is_tech_exclusive,
    description: offer.description,
    productIsRestrictedExplosivesPrecursor: !!offer.product_is_restricted_explosives_precursor,
    paymentDueDateList: offer.payment_due_date_list as PaymentDueDate[],
  })
}

type ExtendCooperativeVariantSchema = CooperativeVariantSchema & {
  thickness_mm
  width_mm
  length_mm
  section_mm
  height_mm
  mesh_size_mm
  total_length_m
  restricted_to_profiles
}

const mapVariantFromApi = (
  variant: ExtendCooperativeVariantSchema,
  productKind: string,
  productId: string,
): CooperativeVariant => {
  const { taxes } = variant as any
  return {
    id: variant.id,
    ballType: variant.ball_type,
    ean_code: variant.ean_code,
    flowRateInLPerMn: variant.flow_rate_in_l_per_mn,
    knotResistance: variant.knot_resistance,
    supplier: variant.supplier,
    measure_quantity: variant.measure_quantity,
    measure_unit: variant.measure_unit,
    name: variant.name,
    type: variant.type,
    sellable: variant.sellable,
    offline: variant.offline,
    weight: variant.weight,
    published: variant.published,
    packaging: variant.packaging,
    associated: variant.associated,
    cooperativeVariantId: variant.cooperative_variant_id,
    stockManagementType: variant.stock_management_type,
    taxes: taxes && {
      tva: taxes.tva,
      rpd: taxes.rpd,
      rpdUnit: taxes.rpd_unit,
    },
    tearResistance: variant.tear_resistance,
    isSoldAsUnit: variant.is_sold_as_unit,
    cropProtectionLabel: variant.crop_protection_label,
    perforationResistance: variant.perforation_resistance,
    precocity: variant.precocity,
    productKind,
    productId,
    capacityInL: variant.capacity_in_l,
    color: variant.color,
    diameterInM: variant.diameter_in_m,
    heightInM: variant.height_in_m,
    length: variant.length,
    lengthInM: variant.length_in_m,
    lengthInMeterPerKilo: variant.length_in_meter_per_kilo,
    surface: variant.surface,
    unitDesignation: variant.unit_designation,
    width: variant.width,
    widthUnit: variant.width_unit,
    thicknessMm: variant.thickness_mm,
    widthMm: variant.width_mm,
    lengthMm: variant.length_mm,
    sectionMm: variant.section_mm,
    heightMm: variant.height_mm,
    meshSizeMm: variant.mesh_size_mm,
    totalLengthM: variant.total_length_m,
    weightInGrammPerSquareMeter: variant.weight_in_gramm_per_square_meter,
    restrictedToProfiles: variant.restricted_to_profiles,
  }
}

function isPackProduct(product: CooperativeProduct): boolean {
  return product.type === PRODUCT_TYPES.PACK
}

function flattenPackProduct(
  packProduct: Product | CooperativeProduct,
  productsInPack: ProductInPackSchema[],
): FlattenedPackProduct {
  const flattenedProduct = { ...packProduct } as any as FlattenedPackProduct

  const fieldsToFlatten = ['formulation', 'market_authorization', 'toxicities', 'usages_by_crops', 'active_substances']

  fieldsToFlatten.forEach((field) => {
    flattenedProduct[field] = productsInPack.map((product) => {
      if (field === 'usages_by_crops') {
        return { id: product.id, product_name: product.name, value: product[field] }
      }
      return { product_name: product.name, value: product[field] }
    })
  })

  flattenedProduct.safetySheetUrls = productsInPack
    .filter((product) => product.safety_sheet_urls)
    .map((product) => ({
      product_name: product.name,
      value: product.safety_sheet_urls,
    }))

  return flattenedProduct
}

export const mapFromApi = (product?: CooperativeProductResponse): CooperativeProduct => {
  if (!product) return {} as CooperativeProduct
  const {
    _id,
    type,
    active_substances,
    nutritive_elements,
    bio_control,
    supplier,
    supplier_logo,
    supplier_universe_page_link,
    supplier_score,
    is_supplier_highlighted,
    formulation,
    images,
    safety_sheet_urls,
    documentation,

    // special Equipment
    characteristics,

    // special Seed
    provider,
    profile,
    chlortoluron,
    resistance,
    generation,
    alternativity,
    mix_components,

    // special Nutrition Amendment
    cultures_with_vegetative_stage,
    origin,
    mixed,
    contribution_mode,
    standard,
    stimulating_substance,
    nutritive_elements_unit,
    is_restricted_explosives_precursor,
    type_eng,

    kind,
    market_authorization,
    name,
    organic,
    targets,
    usages_by_crops,
    variants = [],
    categories,
    family,
    toxicities,
    cooperative_review,
    is_highlighted,
    associated_products,
    exclusive,
    crop_name,
    destination,
    description,
    shape,
    color,
    compatibilities,
    energy_type,
    equipment_type,
    material,
    specificity,
    version,
    display_type,
  } = product
  return {
    _id: _id!,
    type: type as PRODUCT_TYPES,
    active_substances,
    nutritive_elements,
    bio_control,
    supplier,
    supplierLogo: supplier_logo,
    supplierUniversePageLink: supplier_universe_page_link,
    supplierScore: supplier_score,
    formulation,
    images,
    isRestrictedExplosivesPrecursor: !!is_restricted_explosives_precursor,
    isSupplierHighlighted: is_supplier_highlighted,
    safetySheetUrls: safety_sheet_urls || (documentation && documentation.safety_sheet_urls),
    productSheetUrls: documentation && documentation.product_sheet_urls,
    productManualUrls: documentation && documentation.product_manual_urls,
    characteristics,
    kind,
    market_authorization,
    name,
    organic,
    targets,
    usages_by_crops,
    // @ts-ignore: pas le temps d'analyser
    variants: variants.map((variant: ExtendCooperativeVariantSchema) => mapVariantFromApi(variant, kind, _id!)),
    categories,
    family,
    toxicities,
    cooperativeReview: cooperative_review,
    isHighlighted: is_highlighted,
    // @ts-ignore: MERCI DE DIRE POURQUOI CE TS IGNORE EST LÀ
    associatedProducts: associated_products,
    exclusive,
    cropName: crop_name,
    destination,
    provider,
    profile,
    chlortoluron,
    resistance,
    generation,
    alternativity,
    mixComponents: mix_components,
    description,
    shape,
    culturesWithVegetativeStage: cultures_with_vegetative_stage,
    origin,
    mixed,
    contributionMode: contribution_mode,
    standard,
    stimulatingSubstance: stimulating_substance,
    nutritiveElementsUnit: nutritive_elements_unit,
    color,
    compatibilities,
    energyType: energy_type,
    equipmentType: equipment_type,
    material,
    specificity,
    version,
    displayType: display_type,
    typeEng: type_eng,
  }
}

export const mapProductFromApiAndFlattenPackProduct = (
  responseProduct?: CooperativeProductResponse,
): CooperativeProduct => {
  const product = mapFromApi(responseProduct)

  if (responseProduct) {
    return isPackProduct(product) ? flattenPackProduct(product, responseProduct.products_in_pack!) : product
  }

  return product
}

export const mapMeasureUnit = (str: string): string => (str === 'l' ? 'L' : str)

export const mapOfferFromOfferApi = (offer: OfferSchema): OfferForQuota => ({
  offerId: offer.offer_id,
  validityEndDate: offer.validity_end_date ? new Date(offer.validity_end_date) : undefined,
  shippingStartDate: offer.shipping_start_date ? new Date(offer.shipping_start_date) : undefined,
  shippingEndDate: offer.shipping_end_date ? new Date(offer.shipping_end_date) : undefined,
  shippingMethodList: offer.shipping_method_list?.map(mapShippingWayFromApi),
  segmentList: offer.segment_list,
})

export function mapCooperativeProductToProductHit(product: CooperativeProduct) {
  return {
    _id: product._id,
    family: product.family,
    is_exclusive: product.exclusive,
    is_highlighted: product.isHighlighted,
    is_supplier_highlighted: product.isSupplierHighlighted,
    is_off_season: isOffSeason(product),
    kind: product.kind,
    name: product.name,
    supplier: product.supplier,
    supplier_logo: product.supplierLogo,
    thumbnail: product.images[0] || getProductDefaultImage160Px(product.kind, product.type),
    variants: product.variants.map((variant) => ({
      id: variant.id,
      is_sold_as_unit: variant.isSoldAsUnit,
      measure_quantity: variant.measure_quantity,
      measure_unit: variant.measure_unit,
      name: variant.name,
    })),
  }
}
