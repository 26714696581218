import { isManaged } from '@b2ag/offers/src/offer.business'
import type { FullVariant } from '@b2ag/product/src/services/shop.business'
import type { CooperativeProduct } from '@b2ag/product/src/services/product'
import type { Stock } from '@b2ag/stock'
import Vue from 'vue'
import { GetterTree, Module, MutationTree } from 'vuex'
import { getServicesForStore } from '.'

const stockState = () => ({
  stocks: {} as Record<string, Stock>,
  isStockLoading: false,
})

export type StockState = ReturnType<typeof stockState>

export const getters: GetterTree<StockState, any> = {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  stock:
    (state) =>
    (variantId: string): Stock | undefined =>
      state.stocks[variantId],
  stocks: (state): Record<string, Stock> => state.stocks,
  isStockLoading: (state): boolean => state.isStockLoading,
}

export const mutations: MutationTree<StockState> = {
  SET_STOCKS(state, payload: Stock[]) {
    payload.forEach((stock) => {
      Vue.set(state.stocks, stock.variant_id, stock)
    })
  },
  SET_IS_STOCK_LOADING(state, bool: boolean) {
    state.isStockLoading = bool
  },
}

export const actions = {
  async fetch(
    { commit, rootGetters },
    { variants }: { variants: FullVariant<CooperativeProduct['variants'][number]>[] },
  ) {
    commit('SET_IS_STOCK_LOADING', true)
    const { currentCooperativeId } = rootGetters
    try {
      const { stockService } = getServicesForStore()
      if (!stockService) throw new Error('Stock service is not initialized')
      const fetchStock = (id: string): Promise<Stock | undefined> => stockService.get(currentCooperativeId, id)
      const variantsWithStock = variants.filter(({ offers }) => offers.some(isManaged))
      const stocks: ReadonlyArray<Stock | undefined> = await Promise.all(
        variantsWithStock.map(({ id }) => id).map(fetchStock),
      )
      commit('SET_STOCKS', stocks)
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      commit('SET_IS_STOCK_LOADING', false)
    }
  },
}

const stock: Module<StockState, any> = {
  namespaced: true,
  state: stockState,
  getters,
  mutations,
  actions,
}

export default stock
