import { DateTime } from 'luxon'

export function formatDate(date: Date | undefined, format?: string): string | undefined
export function formatDate(date: Date | undefined, format?: Intl.DateTimeFormatOptions): string | undefined
export function formatDate(
  date: Date | undefined,
  format: string | Intl.DateTimeFormatOptions = 'dd/LL/yyyy',
): string | undefined {
  if (!date) return undefined
  if (typeof format === 'string') {
    return DateTime.fromJSDate(date).setLocale('fr').toFormat(format)
  }
  return DateTime.fromJSDate(date).setLocale('fr').toLocaleString(format)
}

export function formatDateInterval(startDate: Date | undefined, endDate: Date | undefined, format = 'dd/LL/yyyy') {
  if (!startDate && !endDate) return undefined
  if (startDate?.toString() === endDate?.toString()) return `le ${formatDate(startDate, format)}`
  if (!startDate) return `jusqu’au ${formatDate(endDate, format)}`
  if (!endDate) return `à partir du ${formatDate(startDate, format)}`
  return `du ${formatDate(startDate, format)} au ${formatDate(endDate, format)}`
}
