import { computed } from 'vue'
import { isOnDiscount } from '@b2ag/product/src/services/product.business'
import { useStore } from '@b2ag/store'

export function useProductCard(productId: string) {
  const store = useStore()
  const product = computed(() => store.getters['shop/getAlgoliaProductById'](productId))

  return {
    isOffSeasonProduct: computed(() => product.value?.is_off_season),
    bestOffer: computed(() => product.value?.bestOffer),
    bestOfferPriceType: computed(() => product.value?.bestOfferPriceType),
    product,
    isOnDiscount: computed(() => isOnDiscount(product.value)),
    isHighlighted: computed(() => product.value?.is_highlighted),
    isInnovation: computed(() => product.value?.is_supplier_highlighted),
    isExclusive: computed(() => product.value?.is_exclusive),
    isExcluTech: computed(() => product.value?.is_exclu_tech),
  }
}
