import type { CategoryDescription, mainBlockDescription } from '../../domain/blocs/CategoryDescription'

export const parseMultiBlocks = (block2, delimiter = 'heading-3') => {
  const result: Array<mainBlockDescription> = []
  if (block2?.content?.length) {
    let curBlock: mainBlockDescription | null = null
    block2.content.forEach((tag) => {
      if (tag.nodeType === delimiter) {
        if (curBlock !== null) {
          result.push(curBlock)
        }
        curBlock = { header: '', content: [] }
        curBlock.header = tag.content[0].value
      } else if (curBlock !== null) {
        curBlock.content.push({
          data: {},
          nodeType: block2.nodeType,
          content: [tag],
        })
      } else {
        curBlock = null
      }
    })
    if (curBlock !== null) {
      result.push(curBlock)
    }
    return result
  }
  return []
}

export const mapCategoryDescriptionFromApi = (dto: any): CategoryDescription => {
  return {
    category: dto.fields?.category ?? '',
    block1: dto.fields?.block1 ?? '',
    block2: (dto.fields?.block2 && parseMultiBlocks(dto.fields?.block2)) ?? [],
    block3: dto.fields?.block3 ?? '',
  }
}
