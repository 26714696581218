import { type EnhancedLogisticOfferForSale } from '@invivodf/module-logistic-offer-client-sdk'
import { ShippingMethodKind } from '@invivodf/module-logistic-offer-client-sdk/dist/convert-logistic-route-to-shipping-method-kind/convert-logistic-route-to-shipping-method-kind'
import { type AxiosResponse } from 'axios'
import uniqBy from 'lodash/uniqBy'
import { DateTime } from 'luxon'

import { type Offer } from '@b2ag/offers/src/offer'
import { hasDiscount } from '@b2ag/offers/src/offer.business'
import { type CooperativeVariant, type ProductRedirect } from './product'

// Dev note: Keys of the product identity section
const identityKeys = new Set(['type', 'contribution_mode', 'standard', 'destination', 'profile'])

// Dev note: Keys of the product detail section (The identity section is in the detail section)
const detailKeys = new Set(
  [
    'nutritive_elements',
    'toxicities',
    'shape',
    'description',
    'active_substances',
    'toxicities',
    'usages_by_crops',
    'toxicities',
    'type',
    'contribution_mode',
    'standard',
    'characteristics',
  ].concat(Array.from(identityKeys)),
)

export const NB_DAYS_BEFORE_VISIBLE = 10
export const MAX_DATE_TIME = DateTime.fromMillis(864000000000000)

export function getProductGenericLabel(product): string | undefined {
  return product && (product.kind !== 'services' ? 'produit' : 'service')
}

export function hasOffers(product): boolean {
  return product?.variants.some(({ offers = [] }) => offers.length > 0)
}

export function hasDetail(product) {
  return product && Object.entries(product).some(([key, value]) => detailKeys.has(key) && value)
}

export function hasImage(product) {
  return !!(product && product.images && product.images[0])
}

export function getProductTypeNoOfferLabel(product) {
  return product && (product.kind !== 'services' ? 'conditionnement' : 'service')
}

export function isOffSeason(product): boolean {
  return !!product?.variants?.some((variant) => variant.offers?.some((offer) => offer.offerType === 'off_season'))
}

export function isOnDiscount(product): boolean {
  return !!product?.variants?.some((variant: CooperativeVariant & { offers?: Offer[] }) =>
    variant.offers?.some(hasDiscount),
  )
}

export function isCalculableProduct(product) {
  return product?.kind === 'semences'
}

export function hasDocumentation(product) {
  return (
    product?.safetySheetUrls?.length >= 1 ||
    product?.documentation?.productManualUrls?.length >= 1 ||
    product?.documentation?.productSheetUrls?.length >= 1 ||
    product?.documentation?.safetySheetUrls?.length >= 1
  )
}

export function hasCooperativeReview(product) {
  return !!product?.cooperativeReview?.tips || !!product?.cooperativeReview?.upsides
}

export function hasUsages(product) {
  return !!(product?.usages_by_crops?.length > 0)
}

function removeClickAndCollect(logisticOffers: EnhancedLogisticOfferForSale[]) {
  return logisticOffers.filter(
    (logisticOffer) => logisticOffer.shippingMethodKind !== ShippingMethodKind.ClickAndCollect,
  )
}

function allowedWithoutStockVerification(offer: Offer) {
  if (offer.allowedWithoutStock) {
    return removeClickAndCollect(offer.logisticOffers || [])
  }
  return offer.logisticOffers || []
}

export function getProductLogisticOffers(product) {
  const logisticOffers =
    product.variants?.flatMap((variant: CooperativeVariant & { offers?: Offer[] }) => {
      const deliveryModeForThisVariant =
        variant.offers?.flatMap((offer) => allowedWithoutStockVerification(offer)) || []
      return variant.stockManagementType === 'notManaged'
        ? removeClickAndCollect(deliveryModeForThisVariant)
        : deliveryModeForThisVariant
    }) || []
  return uniqBy(logisticOffers, 'reference')
}

export function offerRemainingDays(offerValidityEndDate, discountValidityEndDate): number {
  const offerRealValidityEndDate = offerValidityEndDate
    ? DateTime.fromJSDate(offerValidityEndDate).endOf('day')
    : MAX_DATE_TIME
  const discountRealValidityEndDate = discountValidityEndDate
    ? DateTime.fromJSDate(discountValidityEndDate).endOf('day')
    : MAX_DATE_TIME
  const expiredDate =
    offerRealValidityEndDate < discountRealValidityEndDate ? offerRealValidityEndDate : discountRealValidityEndDate
  const today = DateTime.local()
  const remainingDays = Math.ceil(expiredDate.diff(today, 'days').as('days'))
  return remainingDays <= NB_DAYS_BEFORE_VISIBLE ? remainingDays : Infinity
}

export function isProductRedirectUrl(object: AxiosResponse<any>): object is AxiosResponse<ProductRedirect> {
  const { data } = object
  return data && 'redirectUrl' in data
}

export function isExcluTech(product): boolean {
  if (!product) {
    return false
  }

  const hasRestrictedVariant =
    product.variants &&
    product.variants.length > 0 &&
    product.variants.some(
      (variant) => variant.restrictedToProfiles?.length === 1 && variant.restrictedToProfiles?.includes('sales'),
    )

  const allOffers = product.variants?.flatMap((variant) => variant.offers || []) || []

  const hasTechExclusiveOffer = allOffers.length > 0 && allOffers.some((offer) => !!offer.isTechExclusive)

  return hasRestrictedVariant || hasTechExclusiveOffer
}
