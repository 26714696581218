import {
  CROP_PROTECTION_KIND,
  CULTURE_EQUIPMENT_FAMILY_NAME,
  CULTURE_EQUIPMENT_KIND,
  FARM_DEVELOPMENT_FAMILY_NAME,
  FARM_DEVELOPMENT_KIND,
  FARMER_EQUIPMENT_FAMILY_NAME,
  FARMER_EQUIPMENTS_KIND,
  NUTRITION_AMENDMENTS_KIND,
  SEED_FAMILY_NAME,
  SEED_KIND,
  SERVICE_KIND,
  VINE_EQUIPMENT_FAMILY_NAME,
  VINE_EQUIPMENT_KIND,
} from '@b2ag/product/src/domain/products.constants'

export default {
  expert: 'technicien',
  interlocuteurs: 'techniciens',
  metadata: {
    title: 'Aladin, plateforme agricole d’achat de biens',
    defaultDescription:
      'Commandez en ligne, les produits et services pour les besoins de votre exploitation : Engrais, Santé du végétal, Semences, Equipements, Services…',
  },
  nomApp: 'Aladin',
  'p-app-search': {
    metadata: {
      titleAllProducts: 'Tous les produits',
      title: '{$familyOrCategory} - Consultez notre offre sur Aladin',
      productGroupName: 'des {$family} {$category}',
      defaultProductGroupeName: 'de tous les produits',
      description:
        'Consultez la gamme {$productGroupName} proposée par votre partenaire agricole, et commandez en ligne sur aladin.farm.',
    },
  },
  'p-product-layout': {
    'asterisk-info':
      'Selon les modalités appliquées par votre coopérative ou négoce filiale. Voir les conditions générales de vente.',
  },
  tech: 'technicien',
  tc: 'TC',
  'v-product': {
    metadata: {
      title: {
        [NUTRITION_AMENDMENTS_KIND]: '{$family} {$firstCategory} {$name} {$supplier} - aladin.farm',
        [SEED_KIND]: `${SEED_FAMILY_NAME} {$firstCategory} {$name} {$supplier} - aladin.farm`,
        [FARMER_EQUIPMENTS_KIND]: `${FARMER_EQUIPMENT_FAMILY_NAME} {$firstCategory} {$name} {$supplier} - aladin.farm`,
        [CULTURE_EQUIPMENT_KIND]: `${CULTURE_EQUIPMENT_FAMILY_NAME} {$firstCategory} {$name} {$supplier} - aladin.farm`,
        [VINE_EQUIPMENT_KIND]: `${VINE_EQUIPMENT_FAMILY_NAME} {$firstCategory} {$name} {$supplier} - aladin.farm`,
        [FARM_DEVELOPMENT_KIND]: `${FARM_DEVELOPMENT_FAMILY_NAME} {$firstCategory} {$name} {$supplier} - aladin.farm`,
        [CROP_PROTECTION_KIND]: `{$firstCategory} {$name} {$supplier} - aladin.farm`,
        default: '{$firstCategory} {$name} {$supplier} - aladin.farm',
      },
      description: {
        [NUTRITION_AMENDMENTS_KIND]: `Consultez les prix de votre coopérative ou négoce pour {$name}, {$supplier}, nutrition du végétal et amendements {$firstCategory}, et commandez sur aladin.farm`,
        [SEED_KIND]: `Consultez les prix de votre coopérative ou négoce pour {$name}, {$supplier}, semences {$firstCategory}, et commandez sur aladin.farm`,
        [SERVICE_KIND]: `Consultez les prix de votre coopérative ou négoce pour {$name}, {$supplier}, services {$firstCategory}, et commandez sur aladin.farm`,
        [CROP_PROTECTION_KIND]: `Consultez le prix des intrants de votre coopérative ou négoce pour {$name}, {$supplier}, {$firstCategory} {$inFormulation}, et commandez sur aladin.farm`,
        [FARMER_EQUIPMENTS_KIND]: `Consultez les prix de votre coopérative ou négoce pour {$name}, {$supplier}, équipements de l'exploitant {$firstCategory}, et commandez sur aladin.farm`,
        [CULTURE_EQUIPMENT_KIND]: `Consultez les prix de votre coopérative ou négoce pour {$name}, {$supplier}, équipements de culture {$firstCategory}, et commandez sur aladin.farm`,
        [VINE_EQUIPMENT_KIND]: `Consultez les prix de votre coopérative ou négoce pour {$name}, {$supplier}, équipement vigne-arbo {$firstCategory}, et commandez sur aladin.farm`,
        [FARM_DEVELOPMENT_KIND]: `Consultez les prix de votre coopérative ou négoce pour {$name}, {$supplier}, aménagement de l'exploitation {$firstCategory}, et commandez sur aladin.farm`,
        default: `Consultez le prix de {$name}, {$supplier} proposé par votre coopérative ou négoce et commandez sur aladin.farm`,
      },
    },
  },
  'v-user': {
    'number-label': 'N° d‘adhérent',
    'user-section-label': 'Votre compte Aladin',
  },
  partenaire: {
    exploitation: 'partenaire',
    serviceClient: 'partenaire',
    negoce: 'partenaire ou négoce',
    entrepriseNoArticle: 'partenaire',
    entreprise: 'un partenaire',
    choix: 'votre coopérative ou négoce dans la liste',
  },
  adherent: {
    choix: 'd’adhérent ou client',
    client: 'adhérent',
  },
}
