import { provide, inject } from 'vue'
import VueRouter from 'vue-router'

export const routerSymbol = Symbol('router')

export function provideRouter(router) {
  provide(routerSymbol, router)
}

export const useRouter = (): VueRouter | undefined => {
  const router: VueRouter | undefined = inject(routerSymbol)
  return router
}
