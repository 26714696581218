import type { SearchService } from '@b2ag/search'
import { convertTag } from '@b2ag/utils/src/convertTag'
import type { IBannerRepository } from '../repository/IBannerRepository'
import type { Banner } from '../blocs/Banner'
import { NATIONAL_SCOPE } from '../utils/constantes'
import { isAvailableSupplierName } from '../utils/isAvailableSupplierName'

export interface BannersUseCase {
  convertKeywordToTag(keyword: string): string
  getBanners(tags: string[], isAuthenticated?: boolean, partnerId?: number): Promise<Banner[]>
}

export function getBannersUseCaseFactory(
  bannersRepository: IBannerRepository,
  supplierService: SearchService,
): BannersUseCase {
  function convertKeywordToTag(keyword: string): string {
    return convertTag(keyword)
  }

  async function getBanners(tags: string[], isAuthenticated?: boolean, partnerId?: number): Promise<Banner[]> {
    const partnerScope = isAuthenticated ? String(partnerId) : NATIONAL_SCOPE
    if (!tags?.length) return []
    const filteredByPartnerPromise = bannersRepository.getBannersByFilter(
      {
        tags,
      },
      partnerScope,
    )
    if (!isAuthenticated) return filteredByPartnerPromise

    const suppliersListPromise = supplierService.getSuppliers()

    return Promise.all([filteredByPartnerPromise, suppliersListPromise]).then(([banners, suppliers]) => {
      return banners.filter((banner) => isAvailableSupplierName(banner.supplierName ?? '', suppliers))
    })
  }

  return {
    convertKeywordToTag,
    getBanners,
  }
}
