import Big from 'big.js'
import {
  computeOfferQuantityDiscount,
  getBaseQuantity,
  getQuantityAlreadyInCart,
  variantAvailableStock,
  getQuantityMin,
  computeQuantityMax,
} from '@b2ag/quota/src/quantity.business'
import type { Offer } from '@b2ag/offers/src/offer'
import { computed, type ComputedRef, reactive, type Ref, type UnwrapRef } from 'vue'
import { getQuotaAsUnit, isQuotaSufficientFor } from '@b2ag/quota/src/quota.business'
import { isOfferBuyable, mainErrorMessage } from '@b2ag/offers/src/offer.business'
import { canDeliverWithClickAndCollect } from '@b2ag/stock'
import { ShippingMethodKind } from '@invivodf/module-logistic-offer-client-sdk/dist/convert-logistic-route-to-shipping-method-kind/convert-logistic-route-to-shipping-method-kind'
import { variantId } from '@b2ag/offers/src/offer'
import { useStore } from '@b2ag/store'
import { useCart } from '../useCart'
import { useStock } from './useStock'
import { useQuota } from './useQuota'
import { useOfferStock } from './useOfferStock'

export interface IUseOfferQuantity {
  quantityMin: ComputedRef<number>
  quantityMax: ComputedRef<number>
  quantityAlreadyInCart: ComputedRef<number>
  isQuantityMaxedOutInCart: ComputedRef<boolean>
  isQuantityMaxedOut: ComputedRef<boolean>
  isQuotaSufficient: ComputedRef<boolean>
  isOfferBuyable: ComputedRef<boolean>
  mainErrorMessage: ComputedRef<string | null>
  state: UnwrapRef<{ quantity: number; POfferQuantityKeyReset: number }>
  updateOfferQuantityInDiscountList: () => void
  resetQuantity: () => void
}

export function computeStockQuantityMax(
  offer: Offer,
  shippableStockQuantity: number,
  clickAndCollectStockQuantity: number,
) {
  const isOnlyClickAndCollect =
    offer.logisticOffers?.length === 1 &&
    offer.logisticOffers[0].shippingMethodKind === ShippingMethodKind.ClickAndCollect

  return isOnlyClickAndCollect ? clickAndCollectStockQuantity : shippableStockQuantity
}

export function getMinQuantity(offer: Offer): number {
  const { cartOrList } = useCart()
  const quantityAlreadyInCart = getQuantityAlreadyInCart(offer, cartOrList.value?.line_items || [])
  return getQuantityMin(offer, quantityAlreadyInCart)
}

export function useOfferQuantity(offer: Ref<Offer>): IUseOfferQuantity {
  const store = useStore()
  const { quotaAddableToCart, quotaRemainingAsUnit, isQuotaReached } = useQuota(offer.value)
  const { cartOrList } = useCart()
  const consumedStock = computed(() => variantAvailableStock(offer.value.offerId, cartOrList.value))
  const simplifiedVariant = computed(() => ({
    id: offer.value[variantId],
    measure_quantity: offer.value.measureQuantity || 1,
    isSoldAsUnit: offer.value.isSoldAsUnit,
  }))
  const { stockQuantity: shippableStockQuantity, clickAndCollectStockQuantity } = useStock(
    simplifiedVariant.value,
    consumedStock,
  )
  const { noStockAndNotAllowedWithoutStock } = useOfferStock(offer.value, simplifiedVariant.value)

  const quantityAlreadyInCart = computed(() =>
    getQuantityAlreadyInCart(offer.value, cartOrList.value?.line_items || []),
  )
  const baseQuantity = computed(() => getBaseQuantity(offer.value, quantityAlreadyInCart.value))
  const quantityMin = computed(() => getQuantityMin(offer.value, quantityAlreadyInCart.value))

  const stockQuantityMax = computed(() =>
    computeStockQuantityMax(offer.value, shippableStockQuantity.value, clickAndCollectStockQuantity.value),
  )
  const quantityMax = computed(() =>
    computeQuantityMax(
      offer.value,
      offer.value.stockManagementType,
      quantityAlreadyInCart.value,
      stockQuantityMax.value,
      quotaRemainingAsUnit.value,
    ),
  )

  const isQuotaSufficient = computed(
    () => !offer.value.quota || isQuotaSufficientFor(offer.value, quotaAddableToCart.value || 0, quantityMin.value),
  )

  const offerCanDeliverWithClickAndCollect = computed(() => canDeliverWithClickAndCollect(offer.value))

  const clickAndCollectQuantityMax = computed(() =>
    offerCanDeliverWithClickAndCollect.value
      ? Number(Big(clickAndCollectStockQuantity.value || 0).minus(quantityAlreadyInCart.value || 0))
      : 0,
  )

  const isQuantityMaxedOutInCart = computed(() => Math.max(quantityMax.value, clickAndCollectQuantityMax.value) <= 0)
  const isQuantityMaxedOut = computed(() => {
    const isQuantityMaxReached = quantityMin.value <= quantityMax.value
    return isQuantityMaxedOutInCart.value || !isQuantityMaxReached
  })

  const state = reactive({
    quantity: baseQuantity.value,
    POfferQuantityKeyReset: 0,
  })
  return {
    quantityMin,
    quantityMax,
    quantityAlreadyInCart,
    isQuantityMaxedOutInCart,
    isQuantityMaxedOut,
    isQuotaSufficient,
    isOfferBuyable: computed(() =>
      isOfferBuyable(
        isQuotaSufficient.value,
        isQuotaReached.value,
        noStockAndNotAllowedWithoutStock.value,
        isQuantityMaxedOutInCart.value,
      ),
    ),
    mainErrorMessage: computed(() =>
      mainErrorMessage(
        isQuotaSufficient.value,
        isQuotaReached.value,
        noStockAndNotAllowedWithoutStock.value,
        isQuantityMaxedOutInCart.value,
      ),
    ),
    state,
    updateOfferQuantityInDiscountList: () => {
      store.commit(
        'shop/UPDATE_OFFER_QUANTITY_IN_DISCOUNT_LIST',
        computeOfferQuantityDiscount(
          simplifiedVariant.value.isSoldAsUnit,
          offer.value.offerId,
          offer.value.discountId,
          state.quantity,
          simplifiedVariant.value.measure_quantity,
        ),
      )
    },
    resetQuantity: () => {
      state.quantity = baseQuantity.value
      state.POfferQuantityKeyReset += 1
      store.commit(
        'shop/UPDATE_OFFER_QUANTITY_IN_DISCOUNT_LIST',
        computeOfferQuantityDiscount(
          simplifiedVariant.value.isSoldAsUnit,
          offer.value.offerId,
          offer.value.discountId,
          state.quantity,
          simplifiedVariant.value.measure_quantity,
        ),
      )
    },
  }
}

export function getOfferInitialQuantity(offer: Offer) {
  const { cartOrList } = useCart()
  const quotaRemainingAsUnit = getQuotaAsUnit(offer?.quota?.remaining, offer.isSoldAsUnit, offer.measureQuantity)
  const consumedStock = computed(() => variantAvailableStock(offer.offerId, cartOrList.value))
  const simplifiedVariant = {
    id: offer[variantId],
    measure_quantity: offer.measureQuantity || 1,
    isSoldAsUnit: offer.isSoldAsUnit,
  }
  const { stockQuantity: shippableStockQuantity, clickAndCollectStockQuantity } = useStock(
    simplifiedVariant,
    consumedStock,
  )

  const quantityAlreadyInCart = getQuantityAlreadyInCart(offer, cartOrList.value?.line_items || [])
  const baseQuantity = getBaseQuantity(offer, quantityAlreadyInCart)

  const stockQuantityMax = computeStockQuantityMax(
    offer,
    shippableStockQuantity.value,
    clickAndCollectStockQuantity.value,
  )

  const quantityMax = computeQuantityMax(
    offer,
    offer.stockManagementType,
    quantityAlreadyInCart,
    stockQuantityMax,
    quotaRemainingAsUnit,
  )

  return { quantityMax, baseQuantity }
}
