import type {
  Discount,
  DiscountForPricing,
  Product as PricingProductProps,
  Variant as PricingVariantProps,
} from '@b2ag/pricing/src/domain/pricing.interface'
import type { FullProduct, FullVariant } from '@b2ag/product/src/services/shop.business'
import { DiscountApplicationProps, Offer as PricingOffer } from '@b2ag/pricing'
import { IOfferProps } from '@b2ag/pricing/src/domain/Offer'
import isEmpty from 'lodash/isEmpty'
import union from 'lodash/union'
import { useVariants } from '@b2ag/composables/src/useVariants'
import type { Offer } from './offer'

/** Overwrite existing offers in an array with offers from the second array,
 * but keeps any properties that didn't exist in the second array.
 * This is useful for updating OffersForSale with OffersForPrice. */
export function mergeOffers<T extends { offerId: string }>(list1: T[] = [], list2: T[] = []): T[] {
  if (list1.length === 0) return list2
  if (list2.length === 0) return []
  return list2.map((offer) => ({
    ...list1.find((o) => o.offerId === offer.offerId),
    ...offer,
  }))
}

/** When fetching discounts, currently, we get an offerIdList on each Discount that lists **all** associated offers.
 * That is costly and unnecessary to do for the Discount API. The API will change to return only offerIds among
 * the offerIds that were requested. Multiple responses with same Discount may therefore provide overlapping,
 * partial offerIdLists for that Discount, and this accumulates the offerIds. */
export function mergeOfferIdList(discount1: Discount, discount2?: Discount): Discount {
  if (discount2) {
    Object.assign(discount1.offerMap, discount2.offerMap)
    // eslint-disable-next-line no-param-reassign
    discount1.offerIdList = union(discount2.offerIdList, discount1.offerIdList)
  }
  return discount1
}

/** an optimized lookup for offers in the shop.store state */
export function getOfferById(offerListByVariantIdMap: Record<string, Offer[]>, offerId: string) {
  const variantOffers = offerListByVariantIdMap[offerId.split(':')[1]]
  return variantOffers?.find((offer) => offer.offerId === offerId)
}

export function mapDiscountForPricing(discount: DiscountForPricing): DiscountApplicationProps {
  return {
    type: discount.type,
    offerIdList: discount.offerIdList,
    amount: discount.amount
      ? {
          unit: discount.unit,
          isDiscountAppliedInMeasureUnit: discount.measureUnit !== 'unit',
          discount: discount.amount,
        }
      : null,
    slices: discount.scale
      ? discount.scale.map((slice) => ({
          min: slice.min,
          max: slice.max,
          discount: {
            unit: discount.unit,
            isDiscountAppliedInMeasureUnit: discount.measureUnit !== 'unit',
            discount: slice.discount,
          },
        }))
      : null,
  }
}

export function mapOfferForPricing(
  variant: PricingVariantProps,
  offer: Offer,
  discount?: DiscountForPricing,
): IOfferProps {
  const computeRdpPrice = () => {
    const rpdUnitUnit = '€/unité'
    if (offer.rpdUnitPrice && offer.rpdUnit) {
      if (offer.rpdUnit === rpdUnitUnit) return offer.rpdUnitPrice
      return offer.rpdUnitPrice / variant.measure_quantity
    }
    return undefined
  }
  return {
    isVariantSoldAsUnit: offer.isSoldAsUnit,
    variantMeasureUnit: variant.measure_unit,
    variantMeasureQuantity: variant.measure_quantity,
    unitPriceExcludingTaxes: offer.unitPrice,
    measurementPriceExcludingTaxes: offer.measurementPrice,
    rpdPrice: computeRdpPrice(),
    rpdUnit: offer.rpdUnit,
    discountApplication: discount && mapDiscountForPricing(discount),
    vat: variant.taxes?.tva || 0,
  }
}

function setPricingAndSortOffers<T extends PricingVariantProps>(
  variants: FullVariant<T>[],
  discountMap: Record<string, DiscountForPricing> = {},
): FullVariant<T>[] {
  const pricedVariants = variants.map((variant) => ({
    ...variant,
    offers: variant.offers
      .map((offer) => {
        const discount = discountMap[offer.discountId!]
        if (discount) discount.order = 0
        // @ts-ignore ça n'a aucun sens : Argument of type 'import("/Users/xjulien/Git/Invivo/front-b2ag/node_modules/@b2ag/offers/src/offer").Offer' is not assignable to parameter of type 'import("/Users/xjulien/Git/Invivo/front-b2ag/shared/contexts/offers/src/offer").Offer'.
        const offerForPricing = mapOfferForPricing(variant, offer, discount)
        return { ...offer, pricing: new PricingOffer(offerForPricing) }
      })
      .sort(
        (offer1, offer2) =>
          +offer1.pricing.measurementPriceExcludingTaxes - +offer2.pricing.measurementPriceExcludingTaxes,
      ),
  }))
  // Offers have been sorted by price ; now we attribute a number to every Discount on those offers,
  // based on the sorted order of offers for this product.
  let disCount = 1
  pricedVariants.forEach((variant) =>
    variant.offers.forEach((offer) => {
      const discount = discountMap[offer.discountId!]
      if (discount) discount.order ||= disCount++
    }),
  )
  return pricedVariants
}

function findBestOffer(pricedOffers: { pricing: PricingOffer; offerId: string; priceType: string }[]) {
  if (isEmpty(pricedOffers)) return null
  return pricedOffers.reduce((bestOffer, nextOffer) =>
    nextOffer.pricing.bestPossibleMeasurementPriceExcludingTaxes() <
    bestOffer.pricing.bestPossibleMeasurementPriceExcludingTaxes()
      ? nextOffer
      : bestOffer,
  )
}

export function enrichProduct<T extends PricingProductProps>(
  product: T,
  offerListByVariantId: Record<string, Offer[]>,
  discountMap: Record<string, DiscountForPricing> = {},
): FullProduct<T> {
  const variants = enrichVariants(product.variants, offerListByVariantId, discountMap)
  const getOfferPricingWithOfferId = (offer: Offer): { pricing: PricingOffer; offerId: string; priceType: string } => ({
    pricing: offer.pricing!,
    offerId: offer.offerId,
    priceType: offer.priceType,
  })
  // @ts-ignore ça n'a aucun sens : Argument of type 'import("/Users/xjulien/Git/Invivo/front-b2ag/node_modules/@b2ag/offers/src/offer").Offer' is not assignable to parameter of type 'import("/Users/xjulien/Git/Invivo/front-b2ag/shared/contexts/offers/src/offer").Offer'.
  const bestOffer = findBestOffer(variants.flatMap((variant) => variant.offers.map(getOfferPricingWithOfferId)))
  return {
    ...product,
    variants,
    bestOffer: bestOffer?.pricing ?? null,
    bestOfferId: bestOffer?.offerId ?? null,
    bestOfferPriceType: bestOffer?.priceType ?? null,
  }
}

export function enrichVariants<T extends PricingVariantProps>(
  variants: T[],
  offerListByVariantId: Record<string, Offer[]>,
  discountMap: Record<string, DiscountForPricing> = {},
): FullVariant<T>[] {
  const variantsWithOffer = variants.map((variant) => ({ ...variant, offers: offerListByVariantId[variant.id] || [] }))
  const { variantsToDisplay } = useVariants(variantsWithOffer)
  // @ts-ignore ça n'a aucun sens : Argument of type 'import("/Users/xjulien/Git/Invivo/front-b2ag/node_modules/@b2ag/offers/src/offer").Offer' is not assignable to parameter of type 'import("/Users/xjulien/Git/Invivo/front-b2ag/shared/contexts/offers/src/offer").Offer'.
  return setPricingAndSortOffers(variantsToDisplay || [], discountMap)
}
