// eslint-disable-next-line import/extensions, import/no-unresolved
import { AlgoliaSearchService } from '@b2ag/search'
import { convertTag } from '@b2ag/utils/src/convertTag'
import type { HomeAdvertorial } from '../blocs/HomeAdvertorial'
import type { IAdvertorialRepository } from '../repository/IAdvertorialRepository'

export interface GetAdvertorialBySupplierUseCase {
  (): Promise<HomeAdvertorial | null>
  (keyword: string | string[]): Promise<HomeAdvertorial | null>
}
export interface AdvertorialUseCaseFactory {
  getAdvertorialBySupplierUseCaseFactory(
    advertorialRepository: IAdvertorialRepository,
    searchService: AlgoliaSearchService,
  ): GetAdvertorialBySupplierUseCase
}

export function getAdvertorialBySupplierUseCaseFactory(
  advertorialRepository: IAdvertorialRepository,
  searchService: AlgoliaSearchService,
) {
  function filterAdvertorial(suppliers: string[], advertorial: HomeAdvertorial): HomeAdvertorial | null {
    return suppliers
      .map((supplier) => supplier.trim().toUpperCase())
      .includes(advertorial.supplierName.trim().toUpperCase())
      ? advertorial
      : null
  }

  async function getAdvertorialBySupplier(): Promise<HomeAdvertorial | null> {
    const [advertorial, suppliers] = await Promise.all([
      advertorialRepository.getSupplierAdvertorial(),
      searchService.getSuppliers(),
    ])
    if (!advertorial) return null
    return filterAdvertorial(suppliers, advertorial)
  }

  async function getAdvertorialBySupplierAndTags(keyword: string | string[]): Promise<HomeAdvertorial | null> {
    const tags: string[] = Array.isArray(keyword) ? keyword.map(convertTag) : [convertTag(keyword)]

    const [advertorial, suppliers] = await Promise.all([
      advertorialRepository.getSupplierAdvertorialByTags(tags),
      searchService.getSuppliers(),
    ])
    if (!advertorial) return null
    return filterAdvertorial(suppliers, advertorial)
  }

  return {
    getAdvertorialBySupplier,
    getAdvertorialBySupplierAndTags,
  }
}
