import Vue from 'vue'
import VueX from 'vuex'
import { useFeatureFlipping } from '@b2ag/feature-flipping'
import { Store } from '@b2ag/store'
import SentryCopy, { sentryIsEnabled } from '@b2ag/sentry'
import isNil from 'lodash/isNil'
import { useAuthService } from '@b2ag/auth'
import { Profile } from '@b2ag/micro-front-my-account/src/domain/Profile'
import addressesStore from '@b2ag/store/src/addresses.store'
import searchStore from '@b2ag/store/src/search.store'
import stockStore from '@b2ag/store/src/stock.store'
import recommendationStore from '@b2ag/store/src/recommendations.store'
import { featuresForCurrentBrand } from '@b2ag/app-brands/dist/features'
import { cartService, profileService, userService } from '@/services'
import shop from './shop/shop.store'
import cartStore from './cart.store'

export const defaultUser = {
  isLoading: false,
  crops: [],
  cooperatives: [],
  profiles: [],
  currentProfile: {} as Profile,
  currentProfileId: null,
}

export const defaultApp = {
  isFrontAgri: true,
  isOnline: true,
  isScannerVisible: false,
  isAuthenticated: false,
  validated_order_id: undefined,
  isStoreInitialized: false,
  mobileNavBreakpoint: 576,
  isMobileFiltersBlockDisplayed: false,
}

// TODO: should be empty
const getDefaultState = () => {
  return {
    App: defaultApp,
    user: defaultUser,
  }
}

export const mutations = {
  RESET_VALIDATED_ORDER_ID(state) {
    state.App.validated_order_id = null
  },
  RESET_USER(state) {
    state.user = defaultUser
    state.App.isAuthenticated = false
  },
  RESET_AUTHENTICATION_STATUS(state) {
    state.App.isAuthenticated = false
  },
  SET_SCANNER_VISIBILITY(state, isVisible) {
    state.App.isScannerVisible = isVisible
  },
  SET_IS_STORE_INITIALIZED(state, isStoreInitialized) {
    state.App.isStoreInitialized = isStoreInitialized
  },
  SET_ONLINE_STATUS(state, status) {
    state.App.isOnline = status
  },
  SET_USER_IS_LOADING(state, isLoading) {
    state.user.isLoading = isLoading
  },
  SET_VALIDATED_ORDER_ID(state, validatedOrderId) {
    state.App.validated_order_id = validatedOrderId
  },
  async SET_USER(state, user) {
    if (user) {
      const currentProfile = user.profiles.find((profile) => `${profile.id}` === `${user.currentProfileId}`)
      state.user = { ...user, currentProfile: currentProfile || user.profiles[0] }
      state.App.isAuthenticated = await useAuthService().isAuthenticated()

      if (sentryIsEnabled) {
        SentryCopy.setUser({
          id: user.uid,
          username: user.email,
          email: user.email,
          currentProfileId: user.currentProfileId,
          currentCooperativeId: currentProfile?.cooperative.id,
        })
        SentryCopy.setTag('userCooperative', currentProfile?.cooperative.name)
      }
      // Required because when linking farmer account to partner, the cooperative is not set.
      if (state.user.currentProfile?.cooperative?.id) {
        useFeatureFlipping().updateFeatureFlipping(state.user.currentProfile.cooperative.id)
      }
    }
  },
  SET_PENDING_PROFILE(state, pendingProfile) {
    Vue.set(state.user, 'pending_confirmation_profile', pendingProfile)
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_MOBILE_FILTERS_BLOCK_DISPLAYED(state, displayed) {
    state.App.isMobileFiltersBlockDisplayed = displayed
  },
}

export const getters = {
  currentMembershipNumber(state) {
    const { currentProfile } = getters
    return currentProfile(state) && currentProfile(state).membershipNumber
  },
  currentProfile(state) {
    return state.user.currentProfile
  },
  currentCooperative(state) {
    const { currentProfile } = getters
    const profile = currentProfile(state)
    return profile && profile.cooperative
  },
  currentCooperativeId(state) {
    const { currentProfile } = getters
    const profile = currentProfile(state)
    return profile && profile.cooperative && profile.cooperative.id
  },
  currentCooperativeName(state) {
    const { currentProfile } = getters
    const profile = currentProfile(state)
    return profile && profile.cooperative && profile.cooperative.name
  },
  currentTechnicalSales(state) {
    const { currentProfile } = getters
    const profile = currentProfile(state)
    return profile && profile.technicalSales
  },
  hasConfirmedProfile(state) {
    const userHasProfile = state.user && state.user.profiles && state.user.profiles.length > 0
    const userHasConfirmedProfile =
      state.user && state.user.profiles && state.user.profiles.some((profile) => !isNil(profile.confirmedAt))
    return userHasProfile && userHasConfirmedProfile
  },
  isScannerVisible(state) {
    return state.App.isScannerVisible
  },
  isOnline(state) {
    return state.App.isOnline
  },
  isStoreInitialized(state) {
    return state.App.isStoreInitialized
  },
  validatedOrderId(state) {
    return state.App.validated_order_id
  },
  user(state) {
    return state.user
  },
  currentCompanyName(state) {
    const { currentProfile } = getters
    return currentProfile(state) && currentProfile(state).companyName
  },
  isCropProtectionAllowed(_, { currentProfile }: { currentProfile?: Profile }) {
    if (!featuresForCurrentBrand.certificates.phyto) return true

    const profile = currentProfile ?? ({} as Profile)
    return !!profile.validCertiphyto && (!profile.certiphytoEndDate || new Date(profile.certiphytoEndDate) > new Date())
  },
  isExplosivesPrecursorAllowed(_, { currentProfile }: { currentProfile?: Profile }) {
    // @ts-ignore: MERCI DE DIRE POURQUOI CE TS IGNORE EST LÀ
    const isFeatureEnabled = window.env.EXPLOSIVES_PRECURSOR_FEATURE === 'yes'
    const profile = currentProfile ?? ({} as Profile)
    if (!isFeatureEnabled) {
      return true
    }
    const isValid = !!profile.validExplosivesPrecursor
    const isExpired = profile.explosivesPrecursorEndDate && new Date(profile.explosivesPrecursorEndDate) < new Date()
    return isValid && !isExpired
  },
  isAuthenticated(state) {
    return state.App.isAuthenticated
  },
  isFrontAgri(state) {
    return state.App.isFrontAgri
  },
  mobileNavBreakpoint(state) {
    return state.App.mobileNavBreakpoint
  },
  isMobileFiltersBlockDisplayed(state) {
    return state.App.isMobileFiltersBlockDisplayed
  },
  isCGUFarmerAccepted(state) {
    return !!state.user.farmer_cgu_accepted_at
  },
  isUserLoaded(state) {
    return state.user && state.user !== defaultUser
  },
}

export const actions = {
  async selectProfileFromExtranet(context, user) {
    // Used to force the selected profile when logged into the application
    // from the extranet-connector.
    // See 'login-extranet' in the router.
    const partnerId = localStorage.getItem('extranetPartnerId')
    const membershipNumber = localStorage.getItem('extranetMembershipNumber')
    localStorage.removeItem('extranetPartnerId')
    localStorage.removeItem('extranetMembershipNumber')
    if (!partnerId || !membershipNumber) {
      // No membership or partnerid, do nothing
      return
    }
    const selectedProfile = user.profiles.find((profile) => {
      return profile.membershipNumber === membershipNumber && profile.cooperative.id === partnerId
    })
    if (!selectedProfile) {
      return
    }
    await context.dispatch('switchProfile', selectedProfile)
  },
  async fetchUser(context) {
    context.commit('SET_USER_IS_LOADING', true)
    const apiUser = await userService.get().catch(() => defaultUser)
    context.commit('SET_USER', apiUser)
    context.commit('SET_USER_IS_LOADING', false)
    await Promise.all([context.dispatch('shop/reloadProducts'), context.dispatch('addresses/fetchAddresses')])
    const {
      getters: { currentMembershipNumber, currentCooperativeId },
    } = context
    if (currentMembershipNumber) {
      context.dispatch('shop/fetchHighlightedProductList', currentCooperativeId)
      context.dispatch('shop/fetchOffSeasonProductList', currentCooperativeId)
    }
    await context.dispatch('selectProfileFromExtranet', apiUser)
    await context.dispatch('cart/retrieveCart')
  },
  setScannerVisibility(context, isVisible) {
    context.commit('SET_SCANNER_VISIBILITY', isVisible)
  },
  setOnlineStatus(context, status) {
    context.commit('SET_ONLINE_STATUS', status)
  },
  setValidatedOrderId(context, validatedOrderId) {
    context.commit('SET_VALIDATED_ORDER_ID', validatedOrderId)
  },
  async setUser(context, user) {
    context.commit('SET_USER', user)
    await Promise.all([context.dispatch('shop/reloadProducts'), context.dispatch('addresses/fetchAddresses')])
  },
  resetValidatedOrderId(context) {
    context.commit('RESET_VALIDATED_ORDER_ID')
  },
  // XJU : à supprimer pour le passer dans le micro-front my-account
  async switchProfile(context, profile) {
    await context.dispatch('cart/resetCart')
    await context.dispatch('saveUser', { current_profile_id: profile.id })
    await context.dispatch('retrieveUserCategories')
    await context.dispatch('addresses/deleteCurrentAddress')
    await Promise.all([context.dispatch('shop/reloadProducts'), context.dispatch('addresses/fetchAddresses')])
  },
  // XJU : à supprimer pour le passer dans le micro-front my-account
  async dissociateProfile(context, profile) {
    const profileToDissociate = {
      user_email: context.getters.user.email,
      partner_id: profile.cooperative.id,
      membership_number: profile.membershipNumber,
    }
    await profileService.dissociate(profileToDissociate)
    // eslint-disable-next-line no-param-reassign
    context.getters.user.profiles = context.getters.user.profiles.filter((p) => p.id !== profile.id)
    return context.getters.user
  },
  async saveUser(context, user) {
    context.commit('cart/SET_CART_IS_LOADING', true, { root: true })
    const updatedUser = await userService.put(user)
    context.commit('SET_USER', updatedUser)
    await context.dispatch('shop/reloadProducts')
    const { currentMembershipNumber, currentCooperativeId } = context.getters
    const cart = await cartService.last(currentMembershipNumber, currentCooperativeId)
    context.commit('cart/SET_CART', cart, { root: true })
    return context.getters.user
  },
  async logout(_, returnTo?: string) {
    const auth = useAuthService()
    if (window.env.SENTRY_ENABLED === 'yes') SentryCopy.configureScope((scope) => scope.setUser(null))
    const opts = returnTo ? { returnTo } : undefined
    await auth.logout(opts)
  },
  async resetUser(context) {
    context.commit('RESET_USER')
    context.commit('addresses/setAddresses')
    context.commit('addresses/deleteCurrentAddress')
    return context.dispatch('shop/reloadProducts')
  },
  resetState(context) {
    context.commit('RESET_STATE')
    context.dispatch('shop/resetState')
    context.dispatch('resetSearchState')
    context.commit('addresses/setAddresses')
    // context.dispatch('payment/resetState')
    context.dispatch('resetRecommendationsState')
  },
  setMobileFiltersBlockDisplayed(context, displayed) {
    context.commit('SET_MOBILE_FILTERS_BLOCK_DISPLAYED', displayed)
  },
}

export const modules = {
  addresses: addressesStore,
  shop,
  search: searchStore,
  recommendation: recommendationStore,
  stock: stockStore,
  cart: cartStore,
}

Vue.use(VueX)

const store = new VueX.Store({
  actions,
  getters,
  mutations,
  state: getDefaultState(),
  // @ts-ignore: ne comprend pas pourquoi il y a une erreur
  modules,
}) as Store

export const getStore = () => store

export default store
