import type { Categories } from '../../domain/blocs/Categories'
import { BlocType } from '../../domain/BlocType'
import { mapCategoriesFromApi } from './homeCategories.mapper'

export const mapCategoriesContainerFromApi = (categoriesContainterDto): Categories | undefined => {
  if (!categoriesContainterDto) return undefined
  const categories = {
    blocType: BlocType.CATEGORIES_CONTAINER,
    anchorLabel: categoriesContainterDto.fields?.anchorLabel || '',
    items: [],
  } as Categories

  if (categoriesContainterDto.fields?.items?.length) {
    categories.items = mapCategoriesFromApi(categoriesContainterDto.fields?.items)
    return categories
  }

  return undefined
}
