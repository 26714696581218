import { computed } from 'vue'
import {
  isOffSeason,
  isCalculableProduct,
  hasOffers,
  hasDetail,
  hasDocumentation,
  hasCooperativeReview,
  getProductLogisticOffers,
  hasUsages,
  isOnDiscount,
  isExcluTech,
} from '@b2ag/product/src/services/product.business'
import { useStore } from '@b2ag/store'
import { useVariants } from '../useVariants'

export function useProduct(productId: string) {
  const store = useStore()
  const product = computed(() => store.getters['shop/getProductById'](productId))
  const { variantsToDisplay } = useVariants(product.value?.variants)

  return {
    isOffSeasonProduct: computed(() => isOffSeason(product.value)),
    isSellable: hasOffers(product.value) && variantsToDisplay.length > 0,
    isCalculableProduct: isCalculableProduct(product.value),
    hasDetail: hasDetail(product.value),
    hasDocumentation: hasDocumentation(product.value),
    hasCooperativeReview: hasCooperativeReview(product.value),
    hasUsages: hasUsages(product.value),
    bestOffer: computed(() => product.value?.bestOffer),
    product,
    shippingWayList: computed(() => (product.value ? getProductLogisticOffers(product.value) : [])),
    isOnDiscount: computed(() => isOnDiscount(product.value)),
    isHighlighted: computed(() => product.value?.isHighlighted),
    isInnovation: computed(() => product.value?.isSupplierHighlighted),
    isExclusive: computed(() => product.value?.exclusive),
    isExcluTech: computed(() => isExcluTech(product.value)),
  }
}
