import type { Offer, OfferSelection } from '@b2ag/offers/src/offer'
import type { DiscountApplication } from '@b2ag/pricing'
import { Offer as PricingOffer } from '@b2ag/pricing'

import type { Discountable } from './discount.business'

type OfferRelated = Pick<DiscountApplication, 'offerIdList'>

interface LineItemWithOfferInfo {
  offer_id: string
  is_sold_as_unit: boolean
  quantity: number
  measure_quantity: number
  unit_price_without_discount: number
  per_measure_price_without_discount: number
}

const mapOfferToPricingOffer = (offer: Offer) =>
  new PricingOffer({
    ...offer,
    isVariantSoldAsUnit: offer.isSoldAsUnit,
    variantMeasureUnit: offer.measureUnit!,
    variantMeasureQuantity: offer.measureQuantity!,
    unitPriceExcludingTaxes: offer.unitPrice,
    vat: offer.tvaUnitPrice,
  })

export const mapProductSelectionToDiscountable = ({ offer, quantity }: OfferSelection): Discountable => {
  const pricing = mapOfferToPricingOffer(offer)
  return {
    isSoldAsUnit: pricing.isVariantSoldAsUnit,
    quantity,
    measureQuantity: pricing.variantMeasureQuantity,
    unitPrice: +pricing.unitPriceExcludingTaxes,
    perMeasurePrice: +pricing.measurementPriceExcludingTaxes,
  }
}

const mapLineItemToDiscountable = (lineItem: LineItemWithOfferInfo): Discountable => ({
  isSoldAsUnit: lineItem.is_sold_as_unit,
  quantity: lineItem.quantity,
  measureQuantity: lineItem.measure_quantity,
  unitPrice: lineItem.unit_price_without_discount,
  perMeasurePrice: lineItem.per_measure_price_without_discount,
})

const isLineItemMatchingDiscount = (discount: OfferRelated) => (item: LineItemWithOfferInfo) =>
  discount.offerIdList.includes(item.offer_id)

const isOfferSelectionMatchingDiscount =
  (discount: OfferRelated) =>
  ({ offer }) =>
    discount.offerIdList.includes(offer.offerId)

export const getDiscountablesFromCartAndDiscount = (lineItems: LineItemWithOfferInfo[], discount: OfferRelated) =>
  lineItems?.filter(isLineItemMatchingDiscount(discount)).map(mapLineItemToDiscountable)

export const getDiscountablesFromOfferSelectionListAndDiscount = (
  offerSelectionList: OfferSelection[],
  discount: OfferRelated,
) => offerSelectionList.filter(isOfferSelectionMatchingDiscount(discount)).map(mapProductSelectionToDiscountable)
