import { AddressReactivitySettings } from '@b2ag/soufflet-pricer'
import { Store } from '@b2ag/store'

export function makeAddressReactivitySettings(store: Store): AddressReactivitySettings {
  return {
    cartTypeLabel: 'panier',
    shouldRevalidate: () => store.getters['cart/containsPricerOffers'],
    onRevalidatedConfirm: () => store.dispatch('cart/deleteCart'),
  }
}
