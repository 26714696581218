import { BLOCKS } from '@contentful/rich-text-types'
import type { Article } from '../../domain/Article'
import { contentfulContentToArticleHeader } from './article-header.mapper'
import { contentfulContentToAdvertorialInsert } from './advertorial-insert.mapper'
import { contentfulContentToMetadataSEO } from './metadata-seo.mapper'

const MAX_CHAR = 80
const NBSPS_REGEXP = /\u00a0/g

export const articleContentSummarize = (content: any[] = []) => {
  let summarized = ''
  content
    .filter((node) => {
      return node.nodeType === BLOCKS.PARAGRAPH && node.content[0]?.nodeType === 'text' && node.content[0]?.value !== ''
    })
    .forEach((node) => {
      summarized += `${node.content[0]?.value}\n`
    })

  if (summarized !== '') {
    summarized = summarized.substring(0, MAX_CHAR).replace(NBSPS_REGEXP, ' ')
    let lastIndexOfSpace = summarized.lastIndexOf(' ')
    if (lastIndexOfSpace <= 0) lastIndexOfSpace = MAX_CHAR - 3
    summarized = `${summarized.substring(0, lastIndexOfSpace)}...`
  }
  return summarized
}

export function contentfulContentToArticle(dto: any): Article {
  return {
    slug: dto.fields.slug,
    title: dto.fields.title,
    header: contentfulContentToArticleHeader(dto, dto.fields.publishedAt),
    content: dto.fields?.content,
    summary: articleContentSummarize(dto.fields?.content?.content || []),
    tags: dto?.metadata?.tags?.map((tag) => tag.sys.id),
    advertorialInserts: dto.fields?.advertorials?.map((advertorial) =>
      contentfulContentToAdvertorialInsert(advertorial),
    ),
    seo: contentfulContentToMetadataSEO(dto),
  } as Article
}
