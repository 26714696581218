import type { AxiosInstance } from '@b2ag/axios'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'

class ProfileService {
  api: AxiosInstance

  constructor(userApiHost: string) {
    this.api = createAxiosClientWithAladinBearer(`${userApiHost}/v1`, { callerId: buildCallerId() })
  }

  dissociate(profile): Promise<any | null> {
    if (!profile) return Promise.resolve(null)

    return this.api
      .post(`/profiles/dissociate`, {
        user_email: profile.user_email,
        partner_id: profile.partner_id,
        membership_number: profile.membership_number,
      })
      .then(({ data }) => data)
  }
}

export default ProfileService
