import Big from 'big.js'

import Discount from '../Discount'

const numberFormatter = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

export default class Price {
  private readonly internalValue: Big

  constructor(value: number | Big | Price) {
    this.internalValue = Big(+value)
  }

  applyDiscount(discount: Discount): Price {
    return new Price(
      discount.unit === '%'
        ? this.internalValue.minus(this.internalValue.mul(discount.discount).div(100))
        : this.internalValue.minus(discount.discount),
    )
  }

  toString() {
    return numberFormatter.format(+this)
  }

  valueOf() {
    return +this.internalValue
  }

  applyQuantity(quantity: number): Price {
    return new Price(this.internalValue.mul(quantity))
  }

  /**
   * Ce getter ne sert qu'à garantir de ne rien casser dans le front suite au renommage de la propriété privée "value" en "internalValue"
   * @deprecated
   */
  get value() {
    return +this
  }
}
