import ContentfulClient from '@b2ag/contentful'
import type { IHubRepository } from '../../domain/repository/IHubRepository'
import type { Hub } from '../../domain/Hub'
import { contentfulContentToHub } from '../mapper/hub.mapper'

export class HubRepository implements IHubRepository {
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(public readonly client: ContentfulClient) {}

  getBook = (id: string = 'annuaire'): Promise<Hub> => {
    return this.client.api
      .getEntries<any>({
        content_type: 'hub',
        'fields.isRoot': true,
        'fields.contentName': id,
        limit: 1,
      })
      .then((content) => {
        if (content.total === 0) {
          throw new Error('aucun annuaire trouvé')
        }
        return content.items.map(contentfulContentToHub)[0]
      })
  }

  getHub = (hubId: string): Promise<Hub> => {
    return this.client.api
      .getEntries<any>({
        include: 1,
        content_type: 'hub',
        'fields.contentName': hubId,
        limit: 1,
      })
      .then((content) => {
        if (content.total === 0) {
          throw new Error(`hub ${hubId} inconnu`)
        }
        return contentfulContentToHub(content.items[0])
      })
  }

  getHubsByTags = (tags: string[]): Promise<Hub[]> => {
    return this.client.api
      .getEntries<any>({
        include: 1,
        content_type: 'hub',
        'metadata.tags.sys.id[in]': tags.join(','),
      })
      .then((content) => {
        return content.items.map(contentfulContentToHub)
      })
  }
}
