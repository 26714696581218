import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import type { AxiosInstance } from '@b2ag/axios'
import type { StoreModel } from '@b2ag/stock'
import { buildCallerId } from '@b2ag/utils/src/caller-id'

export interface IStoreService {
  findAll(cooperativeId: number): Promise<StoreModel[]>
}

export class StoreService {
  api: AxiosInstance

  constructor({ userApiHost }: { userApiHost: string }) {
    this.api = createAxiosClientWithAladinBearer(`${userApiHost}/v1/cooperatives`, { callerId: buildCallerId() })
  }

  async findAll(cooperativeId: number): Promise<StoreModel[]> {
    const response = await this.api.get<StoreModel[]>(`/${cooperativeId}/stores`)
    return response.data
  }
}
