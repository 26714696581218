import Discount from '../Discount'

export interface AmountProps {
  discount: number
  unit: string
  isDiscountAppliedInMeasureUnit: boolean
}

export default class DiscountAmount {
  public readonly amount: Discount

  constructor(amount: AmountProps) {
    this.amount = new Discount(amount)
  }

  getDiscount(): Discount {
    return this.amount
  }
}
