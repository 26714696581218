import Big from 'big.js'
import { type InjectionKey, inject, provide, type Ref, computed, type ComputedRef, reactive } from 'vue'
import type { FullVariant } from '@b2ag/product/src/services/shop.business'
import type { OfferSelection, Offer } from '@b2ag/offers/src/offer'
import type { CooperativeProduct } from '@b2ag/product/src/services/product'
import { getOfferInitialQuantity } from './useOfferQuantity'
// eslint-disable-next-line import/no-cycle
import { useDiscount } from './useDiscount'

export interface IUseOfferSelection {
  selections: ComputedRef<OfferSelection[]>
}

export const productSelectionsSymbol: InjectionKey<IUseOfferSelection> = Symbol('productSelections')

const returnsNaN = () => NaN

const generateOfferSelections = (
  variants: FullVariant<CooperativeProduct['variants'][number]>[],
  computeDesiredInitialQuantity: (offer: Offer) => number = returnsNaN,
) => {
  return variants.flatMap((variant) =>
    variant.offers!.map((offer) => {
      const offerQuantity = getOfferInitialQuantity(offer)
      return {
        offer,
        quantity:
          Math.min(computeDesiredInitialQuantity(offer), offerQuantity.quantityMax) || offerQuantity.baseQuantity,
      }
    }),
  )
}

export const provideOfferSelections = (
  variants: Ref<FullVariant<CooperativeProduct['variants'][number]>[]>,
  computeDesiredInitialQuantity?: (offer: Offer) => number,
): IUseOfferSelection => {
  const selections = computed(() => {
    if (!variants.value) return []
    return reactive(generateOfferSelections(variants.value, computeDesiredInitialQuantity)) as OfferSelection[]
  })

  provide(productSelectionsSymbol, { selections })
  return { selections }
}

export const useOfferSelectionList = () => inject(productSelectionsSymbol)!

export const useOfferSelection = (offerId: Ref<string>) => {
  const { selections } = useOfferSelectionList()

  const selection = computed(() => selections.value.find(({ offer }) => offer.offerId === offerId.value))
  const offer = computed(() => selection.value?.offer)
  // @ts-ignore: incompatibilité avec Vue 3
  const quantity = computed<number | undefined>({
    get() {
      return selection.value?.quantity
    },
    set(val: number) {
      if (!selection.value) return
      selection.value.quantity = val
    },
  })

  const discountApplication = computed(() => offer.value?.pricing!.discountApplication)
  // @ts-ignore: MERCI DE DIRE POURQUOI CE TS IGNORE EST LÀ
  const { discountProjection } = useDiscount(discountApplication, offer.value?.offerId)
  const price = computed(() => {
    return offer.value?.pricing!.totalPriceHT(quantity.value || 0, discountProjection.value)
  })
  const computePaymentDueDateCost = (rate = 0, offerQuantity = 1) => {
    return Big(offer.value!.pricing!.totalPriceHT(offerQuantity, discountProjection.value).valueOf())
      .mul(rate)
      .div(100)
      .toFixed(2)
  }

  return { offer, quantity, price, computePaymentDueDateCost }
}
