import type { Hub } from '../../domain/Hub'
import { contentfulContentToAdvertorialInsert } from './advertorial-insert.mapper'
import { contentfulAssetUrlToUrl } from './url.mapper'
import { contentfulContentToMetadataSEO } from './metadata-seo.mapper'

function toHubNotDeep(dto: any): Hub {
  return {
    id: dto.fields.contentName,
    title: dto.fields.title,
    description: dto.fields.description,
    imageUrl: contentfulAssetUrlToUrl(dto.fields?.image?.fields?.file?.url),
    imageAlt: dto.fields?.image?.fields?.description,
    isSupplier: dto.fields.isSupplier,
  } as Hub
}

export function contentfulContentToHub(dto: any): Hub {
  return {
    ...toHubNotDeep(dto),
    hubs: dto.fields?.hubs?.map(toHubNotDeep),
    tags: dto?.metadata?.tags?.map((tag) => tag.sys.id),
    advertorialInserts: dto.fields?.advertorials?.map((advertorial) =>
      contentfulContentToAdvertorialInsert(advertorial),
    ),
    seo: contentfulContentToMetadataSEO(dto),
  } as Hub
}
