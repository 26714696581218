import type { AdvertorialInsert } from '../../domain/Advertorialnsert'
import { contentfulAssetUrlToUrl } from './url.mapper'

export function contentfulContentToAdvertorialInsert(dto: any): AdvertorialInsert {
  return {
    id: dto.fields.contentName,
    backgroundImageUrl: contentfulAssetUrlToUrl(dto.fields.backgroundImage.fields.file.url),
    redirectUrl: dto.fields?.url,
    buttonName: dto.fields?.buttonName,
    buttonIconName: dto.fields?.iconName,
    catchphrase: dto.fields?.catchphrase,
  } as AdvertorialInsert
}
