import { AlgoliaSearchService } from '@b2ag/search'
import algoliasearch from 'algoliasearch/lite'
import { onMounted, provide, ref } from 'vue'
import { getConfig } from '@b2ag/micro-front-tools'
import { createInMemoryCache } from '@algolia/cache-in-memory'

const searchAppId = getConfig('ALGOLIA_APP_ID')
const searchApiKey = getConfig('ALGOLIA_API_KEY')
const searchIndexCategories = getConfig('ALGOLIA_INDEX_CATEGORIES')

export function useCategoriesSlugs() {
  const algoliaClient = algoliasearch(searchAppId, searchApiKey, {
    responsesCache: createInMemoryCache(),
  })
  const algoliaService = new AlgoliaSearchService(algoliaClient)
  algoliaService.initSearchIndexCategories(searchIndexCategories)

  const categoriesBySlug = ref(new Map())
  const categoriesByPath = ref(new Map())
  const categoriesFetched = ref(false)

  async function fetchCategories() {
    const categoriesHit = await algoliaService.getCategories()
    categoriesBySlug.value = new Map(categoriesHit.map((category) => [category.slug, category]))
    categoriesByPath.value = new Map(categoriesHit.map((category) => [category.path, category]))
    categoriesFetched.value = true
  }

  function getSlugFromCategories(categoryArray: string[]) {
    const path = categoryArray.join(' > ')
    return categoriesByPath.value.get(path).slug
  }

  function getCategoriesArrayFromSlug(slug: string) {
    const category = categoriesBySlug.value.get(slug)
    return category?.path.split(' > ')
  }

  function getFamilyBySlug(slug: string) {
    const category = categoriesBySlug.value.get(slug)
    const categories = category?.path.split(' > ')
    return categories?.[0]
  }

  function getLastCategoryBySlug(slug: string) {
    const category = categoriesBySlug.value.get(slug)
    const categories = category?.path.split(' > ')
    return categories?.[categories.length - 1]
  }

  onMounted(fetchCategories)

  provide('categoriesBySlug', categoriesBySlug)
  provide('categoriesByPath', categoriesByPath)
  provide('categoriesFetched', categoriesFetched)
  provide('getSlugFromCategories', getSlugFromCategories)
  provide('getFamilyBySlug', getFamilyBySlug)
  provide('getLastCategoryBySlug', getLastCategoryBySlug)
  provide('getCategoriesArrayFromSlug', getCategoriesArrayFromSlug)

  return {
    categoriesBySlug,
    categoriesByPath,
    categoriesFetched,
    getSlugFromCategories,
    getFamilyBySlug,
    getLastCategoryBySlug,
    getCategoriesArrayFromSlug,
  }
}
