import { type Profile } from '../domain/product'

export enum PRODUCT_TYPES {
  SINGLE_PRODUCT = 'SingleProduct',
  PACK = 'Pack',
}

interface ActiveSubstance {
  name: string
  unit: string
  value: string
}

export interface ProductCategory {
  code: string
  label: string
}

export type ProductCategories = ProductCategory[][]

interface Toxicity {
  id: string
  name: string
}

interface UsageByCrop {
  crop_id: string
  crop_name: string
}

interface AssociatedProduct {
  _id: string
  categories: ProductCategories
  thumbnail: string
  name: string
  supplier: string
  kind: string
  sellable: boolean
}

export interface ProductVariant {
  /** @deprecated redundant with base info at Product level */
  ballType?: string[]
  capacityInL?: string
  color?: string
  flowRateInLPerMn?: number[]
  supplier?: string
  cropProtectionLabel?: string
  diameterInM?: number
  ean_code?: string
  heightInM?: number
  id: string
  isSoldAsUnit: boolean
  knotResistance?: number
  length?: number
  lengthInM?: number
  lengthInMeterPerKilo?: number
  measure_quantity: number
  measure_unit: string
  name: string
  packaging?: string
  perforationResistance?: number
  precocity?: string
  productId: string
  productKind: string
  qtyPerPackagingUnit?: string
  sellable?: boolean
  surface?: number
  thicknessMm?: number
  widthMm?: number
  lengthMm?: number
  sectionMm?: number
  heightMm?: number
  meshSizeMm?: string
  totalLengthM?: number
  offline?: boolean
  tearResistance?: number
  type?: string
  unitDesignation?: string
  /** Weight in kilograms */
  weight: number
  weightInGrammPerSquareMeter?: number
  width?: number
  widthUnit?: string
  restrictedToProfiles?: Array<Profile>
  sellable_standalone?: boolean
}

interface BaseProduct {
  _id: string
  alternativity?: string
  associatedProducts?: AssociatedProduct[]
  bio_control?: boolean
  categories: ProductCategories
  characteristics?: string
  chlortoluron?: boolean
  color?: string
  compatibilities?: string[]
  contributionMode?: string
  cooperative_product_code?: string
  cropName?: string
  culturesWithVegetativeStage?: {
    culture: string
    vegetative_stage?: string
  }[]
  description?: string
  destination?: string
  energyType?: string[]
  equipmentType?: string
  exclusive?: boolean
  family: string
  generation?: string
  images: string[]
  isHighlighted?: boolean
  isCooperativeProduct?: boolean
  isRestrictedExplosivesPrecursor: boolean
  isSupplierHighlighted?: boolean
  kind: string
  material?: string
  mixComponents?: string[]
  mixed?: boolean
  name: string
  nutritive_elements?: {
    composition: {
      name: string
      value: string
    }[]
    name: string
    value: string
  }[]
  nutritiveElementsUnit?: string
  organic?: boolean
  origin?: string
  productManualUrls?: string[]
  productSheetUrls?: string[]
  profile?: string
  provider?: string
  resistance?: string
  shape?: string
  specificity?: string
  standard?: string
  stimulatingSubstance?: string
  supplier?: string
  supplierLogo?: string
  supplierUniversePageLink?: string
  supplierScore?: number
  targets?: {
    id: string
    name: string
  }[]
  type?: PRODUCT_TYPES
  typeEng?: string
  variants: ProductVariant[]
  version?: string
}

interface SingleProductAttributes {
  active_substances?: ActiveSubstance[]
  formulation?: string
  market_authorization?: string
  safetySheetUrls?: string[]
  toxicities?: Toxicity[]
  usages_by_crops?: UsageByCrop[]
}

interface FlattenedAttribute<T> {
  product_name: string
  value: T
}

export interface FlattenedPackProductAttributes {
  active_substances: FlattenedAttribute<ActiveSubstance[]>[]
  safetySheetUrls: FlattenedAttribute<string[]>[]
  formulation: FlattenedAttribute<string>[]
  market_authorization: FlattenedAttribute<string>[]
  toxicities: FlattenedAttribute<Toxicity[]>[]
  usages_by_crops: {
    id: number
    product_name: string
    value: UsageByCrop[]
  }[]
}

export interface CooperativeVariantFields {
  associated?: boolean
  cooperativeVariantId?: number
  isSoldAsUnit: boolean
  published?: boolean
  stockManagementType?: 'managed' | 'notManaged'
  stockQuantity?: number
  taxes?: {
    rpd: number
    rpdUnit: string
    tva: number
  }
}

export type CooperativeVariant = ProductVariant & CooperativeVariantFields

interface CooperativeProductFields {
  cooperativeReview?: {
    tips?: string
    upsides?: string
  }
  variants: CooperativeVariant[]
  associatedProducts?: AssociatedProduct[]
}

type SingleProduct = BaseProduct & SingleProductAttributes

export type FlattenedPackProduct = BaseProduct & FlattenedPackProductAttributes
export type Product = SingleProduct | FlattenedPackProduct

export type CooperativeProduct = Omit<Product, 'variants'> & CooperativeProductFields

export type ProductRedirect = {
  redirectUrl: string
}

export class ProductRedirectError extends Error {
  constructor(public redirectUrl: string) {
    super(redirectUrl)
    Object.setPrototypeOf(this, ProductRedirectError.prototype)
  }
}
