import Vue from 'vue'
import {
  getOfferById,
  mergeOfferIdList,
  mergeOffers,
  enrichProduct,
  enrichVariants,
} from '@b2ag/offers/src/mergeOffers'
import type { Discount } from '@b2ag/pricing/src/domain/pricing.interface'
import { type Offer, type OfferBase, variantId } from '@b2ag/offers/src/offer'
import type { FullProduct, FullVariant } from '@b2ag/product/src/services/shop.business'
import type { StoreModel } from '@b2ag/stock'
import type { CooperativeProduct } from '@b2ag/product/src/services/product'
import { getProductDefaultImageFull } from '@b2ag/product/src/services/products-images.constants'
import { type ProductHit } from '@b2ag/search'
import { OFF_SEASON_OFFER_TYPE } from '@b2ag/offers/src/offers.constants'
import { Offer as PricingOffer } from '@b2ag/pricing'
import { VariantHit } from '@b2ag/search/src/search.service'
import groupBy from '@b2ag/utils/src/group-by'
import { type ShopStoreActions, type ShopStoreGetters } from './interfaces/shop.interface'
import { type Commit, type RootGetters, getServicesForStore } from '.'

// ------ ** ------- CORE ------ ** -------

export const initialShopState = {
  isShopLoading: false,
  areDiscountsLoading: false,
  areOffersLoading: false,
  discountMap: {} as { [id: string]: Discount },
  offerListByVariantIdMap: {} as { [id: string]: Offer[] },
  productMap: {} as { [id: string]: CooperativeProduct },
  algoliaMap: {} as { [id: string]: FullProduct<ProductHit> },
  productsForPrice: new Set<string>(),
  cooperativeStoresMap: {} as { [id: string]: StoreModel },
  cartOfferIdsPromise: undefined as Promise<string[]> | undefined,
  resolveCartPromise(_offerIds: string[]) {},

  // SPECIFIC AGRI
  areSupplierProductsLoading: false,
  highlightedProductIdList: [] as string[],
  offSeasonProductIdList: [] as string[],
  supplierProductIdsMap: {} as { [id: string]: string[] },
}

export const shopGetters: { [K in keyof ShopStoreGetters]: GetterImplementation<K> } = {
  getDiscountFromOffer:
    (state: ShopState) =>
    (offer: OfferBase): Discount | null =>
      state.discountMap[offer.discountId!] || null,
  getProductsForPrice: (state: ShopState) => state.productsForPrice,
  getAlgoliaProductById:
    (state) =>
    (productId: string): FullProduct<ProductHit> | undefined => {
      const productHit = state.algoliaMap[decodeURI(productId)]
      return productHit ? enrichProduct(productHit, state.offerListByVariantIdMap, state.discountMap) : undefined
    },
  getAlgoliaProductsByIds:
    (_, getters) =>
    (productIds: string[]): FullProduct<ProductHit>[] => {
      return productIds
        .map((productId) => getters.getAlgoliaProductById(productId))
        .filter((product): product is FullProduct<ProductHit> => product !== undefined)
    },
  areDiscountsLoading: (state) => state.areDiscountsLoading,
  isShopLoading: (state) => state.isShopLoading,
  getProductById:
    (state: ShopState) =>
    (id: string): FullProduct<CooperativeProduct> | undefined => {
      if (!state.areDiscountsLoading && id in state.productMap) {
        return enrichProduct(state.productMap[id], state.offerListByVariantIdMap, state.discountMap)
      }
      if (!state.areOffersLoading && id in state.productMap) {
        return enrichProduct(state.productMap[id], state.offerListByVariantIdMap, {})
      }
      return undefined
    },
  /** @returns unique discounts for a product, in the same order they first appear in the offers block. */
  discountList: (state, getters) => (productId: string) => {
    const idSet = new Set<string>()
    return (
      getters
        .getProductById(productId)
        ?.variants.flatMap((variant) => variant.offers)
        .filter((offer) => {
          if (!offer.discountId || idSet.has(offer.discountId)) return false
          idSet.add(offer.discountId)
          return true
        })
        .map((offer) => state.discountMap[offer.discountId!]) || []
    )
  },
  packagingOptions: (_, getters) => (productId: string) => {
    const variants: PackagingVariant[] = getters
      .getProductById(productId)!
      .variants.map(({ measure_quantity, measure_unit, weight, packaging, offers }) => ({
        pmg: measure_unit === 'MG' ? weight / measure_quantity : undefined,
        quantity: measure_quantity,
        unit: measure_unit,
        packageName: packaging?.toLowerCase(),
        hasOffers: offers?.length > 0,
      }))
    const packages = new Map<string, PackagingVariant>()
    variants.forEach((variant) => {
      if (variant.packageName) {
        const base = packages.get(variant.packageName)
        if (base) base.hasOffers = base.hasOffers || variant.hasOffers
        else packages.set(variant.packageName, variant)
      }
    })
    return packages
  },
  getCooperativeStores: (state: ShopState): StoreModel[] => {
    return Object.values(state.cooperativeStoresMap)
  },
  getCooperativeStoreByCode: (state: ShopState) => (code: string) => {
    return state.cooperativeStoresMap[code]
  },
  getOffersById: (state: ShopState) => (ids: string[]) =>
    ids.map((offerId) => getOfferById(state.offerListByVariantIdMap, offerId)).filter(Boolean) as Offer[],
  getProductIsOffSeasonInfo: (_, getters) => (productId: string) => {
    const product = getters.getProductById(productId)! || getters.getAlgoliaProductById(productId)!
    return !!product?.variants.some((variant) => variant.offers.some((offer) => offer.offerType === 'off_season'))
  },

  // SPECIFIC AGRI

  getHighlightedProductList: (state) =>
    !state.isShopLoading
      ? state.highlightedProductIdList.map((highlightedProductId) =>
          enrichProduct(state.algoliaMap[highlightedProductId], state.offerListByVariantIdMap, state.discountMap),
        )
      : [],
  areHighlightedProductsLoaded: (state) => !state.isShopLoading,
  getOffSeasonProductList: (state) =>
    state.isShopLoading
      ? []
      : state.offSeasonProductIdList.map((offSeasonProductId) =>
          enrichProduct(state.algoliaMap[offSeasonProductId], state.offerListByVariantIdMap, state.discountMap),
        ),
  areOffSeasonProductsLoaded: (state) => !state.isShopLoading,
  getVisitedProducts: (state, _, __, rootGetters) => {
    const visitedProducts = rootGetters.lastVisitedProducts as ProductHit[]
    return visitedProducts.map((product) => enrichProduct(product, state.offerListByVariantIdMap, state.discountMap))
  },
  getSupplierProducts: (state, getters) => (productId) => {
    const supplierProductIds = state.supplierProductIdsMap[productId] || []
    return getters.getAlgoliaProductsByIds(supplierProductIds)
  },
  areSupplierProductsLoading: (state) => state.areSupplierProductsLoading,
  getPushedProduct: (_, getters) => (productId: string) => {
    const product: FullProduct<CooperativeProduct> | undefined = getters.getProductById(productId)
    let pushedProduct: {
      name: string
      imageUrl?: string
      imageAlt?: string
      properties: { label: string; value: string }[]
      offer: PricingOffer | undefined
    }
    if (product) {
      pushedProduct = {
        name: product.name || '',
        imageUrl: product.images[0] || getProductDefaultImageFull(product.kind || '', product.type),
        imageAlt: product.name,
        properties: [{ label: 'Fournisseur', value: product.supplier || '' }],
        offer: product.bestOffer || undefined,
      }

      if (product.variants[0]?.precocity) {
        pushedProduct.properties.push({
          label: 'Précocité',
          value: product.variants[0]?.precocity,
        })
      }

      return pushedProduct
    }
    return null
  },

  // SPECIFIC TECH

  getVariants:
    (state: ShopState) =>
    (variantParams): FullVariant<CooperativeProduct['variants'][number]>[] => {
      const productIds = [...new Set(variantParams.map((variant) => variant.product?._id))]
      const variantIds = new Set(variantParams.map((variant) => variant.id))
      if (state.areOffersLoading || !productIds.some((id) => id in state.productMap)) return []
      const variants = productIds
        .flatMap((id) => state.productMap[id]?.variants ?? [])
        .filter((variant) => variantIds.has(variant.id))

      return enrichVariants(variants, state.offerListByVariantIdMap, state.areDiscountsLoading ? {} : state.discountMap)
    },
  getFarmerActualProductIdsViewed: (state: ShopState) => (productIds) => {
    return productIds.filter((productId) => !!state.algoliaMap[productId])
  },
}

export const shopMutations = {
  SET_PRODUCT_MAP(
    state: ShopState,
    { productList, forPrice }: { productList: CooperativeProduct[]; forPrice?: boolean },
  ) {
    productList.forEach((product) => {
      // Dev note: Vue.set is use because only one element of the array is modified, reactivity will not work otherwise
      Vue.set(state.productMap, product._id, product)
      if (forPrice) state.productsForPrice.add(product._id)
      else state.productsForPrice.delete(product._id)
    })
  },
  SET_OFFER_LIST_BY_VARIANT(state: ShopState, offerListByVariantId: Record<string, OfferBase[]>) {
    // eslint-disable-next-line
    for (const variantId in offerListByVariantId) {
      Vue.set(
        state.offerListByVariantIdMap,
        variantId,
        mergeOffers(state.offerListByVariantIdMap[variantId], offerListByVariantId[variantId]),
      )
    }
  },
  SET_DISCOUNT_MAP(state: ShopState, discountList: Discount[]) {
    discountList.forEach((discount) => {
      // eslint-disable-next-line
      for (const offerId in discount.offerMap) {
        const offer = getOfferById(state.offerListByVariantIdMap, offerId)
        if (offer) offer.discountId = discount.id
      }
      Vue.set(state.discountMap, discount.id, mergeOfferIdList(discount, state.discountMap[discount.id]))
    })
  },
  SET_COOPERATIVE_STORE_MAP(state: ShopState, stores: Map<string, StoreModel>) {
    stores.forEach((store, code) => {
      Vue.set(state.cooperativeStoresMap, code, store)
    })
  },
  SUB_OFFER_QUANTITY_IN_DISCOUNT_LIST(
    state: ShopState,
    { discountId, offerId, quantity }: { discountId: string; offerId: string; quantity: number },
  ) {
    const discount = state.discountMap[discountId]
    if (discount && offerId in discount.offerMap) discount.offerMap[offerId] -= quantity
  },
  START_SHOP_LOADING(state: ShopState) {
    state.isShopLoading = true
  },
  STOP_SHOP_LOADING(state: ShopState) {
    state.isShopLoading = false
  },
  START_DISCOUNTS_LOADING(state: ShopState) {
    state.areDiscountsLoading = true
  },
  STOP_DISCOUNTS_LOADING(state: ShopState) {
    state.areDiscountsLoading = false
  },
  START_OFFERS_LOADING(state: ShopState) {
    state.areOffersLoading = true
  },
  STOP_OFFERS_LOADING(state: ShopState) {
    state.areOffersLoading = false
  },
  UPDATE_OFFER_QUANTITY_IN_DISCOUNT_LIST(
    state: ShopState,
    { offerId, discountId, quantity }: { offerId: string; discountId: string; quantity: number },
  ) {
    const discount = state.discountMap[discountId]
    if (discount) discount.offerMap[offerId] = quantity
  },
  RESET_SHOP(state: ShopState) {
    state.productMap = {}
    state.algoliaMap = {}
    state.offerListByVariantIdMap = {} as Record<typeof variantId, Offer[]>

    state.cooperativeStoresMap = {}

    state.discountMap = {}
    state.highlightedProductIdList = []
    state.offSeasonProductIdList = []
  },
  SET_ALGOLIA_MAP(state: ShopState, productHits: ProductHit[]) {
    productHits.forEach((product) => {
      Vue.set(state.algoliaMap, product._id, product)
      state.productsForPrice.add(product._id)
    })
  },

  // SPECIFIC AGRI

  SET_IS_OFF_SEASON_ASSOCIATED_PRODUCT(
    state: ShopState,
    { productId, offers }: { productId: string; offers: readonly Pick<OfferBase, 'productId' | 'offerType'>[] },
  ) {
    const product = state.productMap[productId]
    product.associatedProducts!.forEach((associatedProduct) => {
      const isOffSeason = offers.some(
        (offer) => offer.productId === associatedProduct._id && offer.offerType === OFF_SEASON_OFFER_TYPE,
      )
      Vue.set(associatedProduct, 'isOffSeasonProduct', isOffSeason)
    })
  },

  SET_HIGHLIGHTED_PRODUCT_ID_LIST(state: ShopState, productIds: string[]) {
    state.highlightedProductIdList = productIds
  },

  SET_OFF_SEASON_PRODUCT_ID_LIST(state: ShopState, productIds: string[]) {
    state.offSeasonProductIdList = productIds
  },

  SET_SUPPLIER_PRODUCT_IDS_MAP(state: ShopState, { productId, relatedProductIds }) {
    Vue.set(state.supplierProductIdsMap, productId, relatedProductIds)
  },
  SET_SUPPLIER_PRODUCTS_LOADING(state, isLoading) {
    state.areSupplierProductsLoading = isLoading
  },
}

// @ts-expect-error pas compris
export const shopActions: { [K in keyof ShopStoreActions]: ActionImplementation<K> } = {
  async fetchAlgoliaProducts({ dispatch, rootGetters }, { productIds, nbItemsToFetch }) {
    if (productIds.length === 0) {
      return
    }
    const { currentCooperativeId } = rootGetters
    const { searchService } = getServicesForStore()
    if (!searchService) throw new Error('Recommendation service is not available')

    if (!currentCooperativeId) {
      searchService.initSearchIndex(window.env.ALGOLIA_INDEX)
    } else {
      searchService.initSearchIndex(window.env.ALGOLIA_INDEX_COOP_PREFIX + currentCooperativeId)
    }
    const filters = productIds.length > 0 ? `objectID:${productIds.join(' OR objectID:')}` : ''
    const productHits = (
      await searchService.fetchAlgoliaProducts(!!currentCooperativeId, `(${filters})`, nbItemsToFetch)
    ).hits as ProductHit[]

    await dispatch('fetchMissingProductsPrice', {
      productHits,
      membershipNumber: rootGetters.targetCustomerMembership || rootGetters.currentMembershipNumber,
    })
  },
  setCartOffers({ state }, offerIdList) {
    if (state.cartOfferIdsPromise) state.resolveCartPromise(offerIdList)
  },
  async reloadProducts({ commit, state, dispatch }) {
    const oldProductsIdList = Object.keys(state.productMap)
    const oldAlgoliaProductsIdList = Object.keys(state.algoliaMap)
    commit('RESET_SHOP')
    await dispatch('fetchMissingProducts', oldProductsIdList)
    await dispatch('fetchAlgoliaProducts', { productIds: oldAlgoliaProductsIdList })
  },
  async fetchCooperativeStores({ commit, rootGetters }) {
    const { currentCooperativeId } = rootGetters
    const { storeService } = getServicesForStore()
    if (!storeService) throw new Error('Store service is not available')
    const stores = await storeService.findAll(currentCooperativeId)

    commit('SET_COOPERATIVE_STORE_MAP', new Map(stores.map((store) => [store.code, store])))
  },
  resetState({ commit }) {
    commit('RESET_SHOP')
  },
  resetShop({ commit }) {
    commit('RESET_SHOP')
  },
  async setAssociatedProducts({ state, rootGetters, dispatch, commit }, { productId, membershipNumber }) {
    const product = state.productMap[productId]
    if (product && product.associatedProducts?.length) {
      const associatedProductIds = product.associatedProducts.map((associatedProduct) => associatedProduct._id)
      const { currentCooperativeId } = rootGetters
      const cityCode = rootGetters['addresses/currentCityCode']
      const { offerService } = getServicesForStore()
      if (!offerService) throw new Error('Offer service is not available')
      const offers = await offerService.findOffersBase(associatedProductIds, currentCooperativeId, membershipNumber, {
        cityCode,
      })
      await dispatch('fetchAlgoliaProducts', { productIds: associatedProductIds })

      const { discountService } = getServicesForStore()
      if (!discountService) throw new Error('Discount service is not available')
      const discountList = await discountService.findDiscounts(
        offers.map((offer) => offer.offerId),
        currentCooperativeId,
      )
      commit('SET_PRODUCT_MAP', { productList: [product], forPrice: true })
      commit('SET_DISCOUNT_MAP', discountList)
      commit('SET_IS_OFF_SEASON_ASSOCIATED_PRODUCT', { productId, offers })
    }
  },
  async fetchMissingProductsPrice(
    { commit, rootGetters, state },
    { productHits, membershipNumber }: { productHits: ProductHit[]; membershipNumber: string },
  ) {
    const { currentCooperativeId } = rootGetters
    const missingProductHits = productHits.filter((productHit) => !(productHit._id in state.algoliaMap))
    commit('SET_ALGOLIA_MAP', missingProductHits)
    if (!currentCooperativeId) return
    const variantsMap = new Map(
      missingProductHits.flatMap((productHit) => productHit.variants.map((variant) => [variant.id, variant])),
    )
    const variantIds = Array.from(variantsMap.keys())
    if (variantIds.length) {
      commit('START_SHOP_LOADING')
      commit('START_OFFERS_LOADING')
      const cityCode = rootGetters['addresses/currentCityCode']

      const { offerService } = getServicesForStore()
      if (!offerService) throw new Error('Offer service is not available')

      let offers = await offerService.findOfferBaseByVariants(variantIds, currentCooperativeId, membershipNumber, {
        cityCode,
      })
      offers = offers.map((offer) => {
        const variant: VariantHit | undefined = variantsMap.get(offer[variantId])!
        return {
          ...offer,
          isSoldAsUnit: variant?.is_sold_as_unit || false,
          measureQuantity: variant?.measure_quantity,
          measureUnit: variant?.measure_unit,
        }
      })

      commit('SET_OFFER_LIST_BY_VARIANT', groupBy(variantId, offers))
      commit('STOP_OFFERS_LOADING')

      commit('START_DISCOUNTS_LOADING')

      const { discountService } = getServicesForStore()
      if (!discountService) throw new Error('Discount service is not available')

      const discounts = await discountService.findDiscounts(
        offers.map((offer) => offer.offerId),
        currentCooperativeId,
      )
      commit('SET_DISCOUNT_MAP', discounts)
      commit('STOP_DISCOUNTS_LOADING')
      commit('STOP_SHOP_LOADING')
    }
  },
  async fetchMissingOffers({ getters, commit, rootGetters, state }, offerIdList: string[]) {
    if (state.cartOfferIdsPromise) state.resolveCartPromise(offerIdList)
    const { currentCooperativeId } = rootGetters
    const membershipNumber = rootGetters.targetCustomerMembership || rootGetters.currentMembershipNumber
    const existingOffers = getters.getOffersById(offerIdList)
    const existingIds = new Set(existingOffers.map(({ offerId }) => offerId))
    const missingIdList = offerIdList.filter((offerId) => !existingIds.has(offerId))

    if (missingIdList.length === 0) return
    if (!currentCooperativeId || !membershipNumber) {
      const message = `Offers with ids ${missingIdList} can not be loaded without cooperativeId (currentValue = ${currentCooperativeId}) and targetCustomerMembership = (currentValue = ${membershipNumber})`
      throw new Error(message)
    }
    if (missingIdList.some((id) => !id)) throw new Error('Empty Offer IDs')

    const { offerService } = getServicesForStore()
    if (!offerService) throw new Error('Offer service is not available')
    const offers = await offerService.findByOfferIdList(missingIdList, currentCooperativeId, membershipNumber)
    commit('SET_OFFER_LIST_BY_VARIANT', groupBy(variantId, offers))
  },
}

const shop = {
  namespaced: true,
  state: () => initialShopState,
  mutations: shopMutations,
  actions: shopActions,
  getters: shopGetters,
}

export default shop

// ------ ** ------- TYPE ------ ** -------

interface PackagingVariant {
  pmg: number | undefined
  quantity: number
  unit: string
  packageName?: string
  hasOffers: boolean
}

export type ShopState = typeof initialShopState

export type GetterImplementation<T extends keyof ShopStoreGetters> = (
  state: ShopState,
  getters: ShopStoreGetters,
  rootState?: any,
  rootGetters?: any,
) => ShopStoreGetters[T]

type Dispatch = <K extends keyof ShopStoreActions>(
  key: K,
  ...args: Parameters<ShopStoreActions[K]>
) => ReturnType<ShopStoreActions[K]>

export interface Context {
  commit: Commit<typeof shopMutations>
  state: ShopState
  dispatch: Dispatch
  rootGetters: Record<RootGetters, any>
  getters: ShopStoreGetters
}

export type ActionImplementation<T extends keyof ShopStoreActions> = (
  context: Context,
  ...args: Parameters<ShopStoreActions[T]>
) => ReturnType<ShopStoreActions[T]>
