import type { Page } from '@b2ag/articles/src/domain/Page'
import type { RequestPageOption } from '@b2ag/articles/src/domain/RequestPageOption'
import { NATIONAL_SCOPE } from '@b2ag/marketing/src/domain/utils/constantes'
import { isAvailableForPartner } from '@b2ag/marketing/src/domain/utils/isAvailableForPartner'
import type { SearchService } from '@b2ag/search'
import { convertTag } from '@b2ag/utils/src/convertTag'
import type { Article } from '../Article'
import { NotForNationalError } from '../NotForNationalError'
import { NotForPartnerError } from '../NotForPartnerError'
import type { IArticleRepository } from '../repository/IArticleRepository'
import type { ArticleThumbnails } from '../ArticleThumbnails'
import { searchIndex, searchIndexCoop } from '../../application/services'

export function isAvalaibleArticle(article: Article, availableSuppliers: string[] = []): boolean {
  return (
    !article.header.supplierName ||
    availableSuppliers
      .map((supplier) => supplier.trim().toUpperCase())
      .includes(article.header.supplierName.trim().toUpperCase())
  )
}

export class ArticleUseCase {
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(
    private articleRepository: IArticleRepository,
    private searchService: SearchService,
  ) {}

  // eslint-disable-next-line class-methods-use-this
  convertKeywordToTag(keyword: string): string {
    return convertTag(keyword)
  }

  getArticle(slug: string, isAuthenticated: boolean = false, partnerId?: number) {
    return this.articleRepository.getArticle(slug).then((article) => {
      if (isAvailableForPartner(article.header?.partnerFilter, isAuthenticated, partnerId)) {
        return article
      }
      if (!isAuthenticated) {
        throw new NotForNationalError('Article non disponible pour le national')
      }
      throw new NotForPartnerError('Article non disponible pour les partenaires')
    })
  }

  async getArticlesPage(
    articleThumbnails: ArticleThumbnails,
    isAuthenticated: boolean = false,
    partnerId?: number, // currentCooperativeId
    options: RequestPageOption = { itemPerPage: 20, page: 1 },
  ): Promise<Page<Article>> {
    const partnerScope = isAuthenticated ? String(partnerId) : NATIONAL_SCOPE
    let filteredByPartnerPromise: Promise<Page<Article>>

    if (articleThumbnails.isManual) {
      const filteredByPartner = articleThumbnails.articles?.filter(
        (article) => isAvailableForPartner(article.header?.partnerFilter, isAuthenticated, partnerId) ?? [],
      )
      filteredByPartnerPromise = Promise.resolve({
        total: filteredByPartner?.length || 0,
        items: filteredByPartner,
        itemPerPage: 20,
        current: 1,
      })
    } else {
      filteredByPartnerPromise = this.articleRepository.getArticlesByFilter(
        {
          supplierName: articleThumbnails.supplierName,
          tags: articleThumbnails.tags,
          excludeTags: ['newsactualite', 'newsnote', 'newsmessage', 'newsmarche', 'newscultures'],
        },
        partnerScope,
        options,
      )
    }

    if (!isAuthenticated) return filteredByPartnerPromise

    this.searchService.initSearchIndex(partnerId && searchIndexCoop ? searchIndexCoop + partnerId : searchIndex)

    const suppliersListPromise = this.searchService.getSuppliers()

    return Promise.all([filteredByPartnerPromise, suppliersListPromise]).then(([pageArticle, suppliers]) => {
      return {
        ...pageArticle,
        items: pageArticle.items.filter((article) => isAvalaibleArticle(article, suppliers)),
      }
    })
  }

  async getArticles(
    articleThumbnails: ArticleThumbnails,
    isAuthenticated: boolean = false,
    partnerId?: number,
    options: RequestPageOption = { itemPerPage: 20, page: 1 },
  ): Promise<Article[]> {
    if (!articleThumbnails) return []
    return this.getArticlesPage(articleThumbnails, isAuthenticated, partnerId, options).then(
      (pageArticle) => pageArticle.items,
    )
  }
}
