import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { useAuthService } from '@b2ag/auth'
import { buildCallerId } from '@b2ag/utils/src/caller-id'

class CartService {
  constructor({ cartApiHost }) {
    this.cartApiHost = cartApiHost
  }

  get api() {
    return createAxiosClientWithAladinBearer(`${this.cartApiHost}/v1/bff/appro`, { callerId: buildCallerId() })
  }

  async createCart({
    membership_number,
    cooperative_id,
    product_id,
    quantity,
    variant,
    offer_id,
    discount_id,
    pre_shippings,
    payment_due_date_reference,
  }) {
    const response = await this.api.post('/carts', {
      membership_number,
      cooperative_id,
      line_items: [
        {
          product_id,
          quantity,
          ean_code: variant.ean_code,
          offer_id,
          discount_id,
          payment_due_date_reference,
        },
      ],
      pre_shippings,
    })
    return response.data
  }

  async get(cartId) {
    const response = await this.api.get(`/carts/${cartId}`)
    return response.data
  }

  // eslint-disable-next-line camelcase
  async addProductToCart(
    cartId,
    productId,
    quantity,
    variant,
    offer_id,
    discount_id,
    pre_shipping,
    payment_due_date_reference,
  ) {
    const response = await this.api.post(`/carts/${cartId}/line-items`, {
      product_id: productId,
      quantity,
      ean_code: variant.ean_code,
      offer_id,
      discount_id,
      pre_shipping,
      payment_due_date_reference,
    })
    return response.data
  }

  async destroyLineItem(cartId, lineItemId) {
    const response = await this.api.delete(`/carts/${cartId}/line-items/${lineItemId}`)
    return response.data
  }

  async deleteCart(cartId) {
    const response = await this.api.delete(`/carts/${cartId}`)
    return response.data
  }

  async index(membershipNumber, cooperativeId) {
    const response = await this.api.get('/carts', {
      params: { membership_number: membershipNumber, cooperative_id: cooperativeId },
    })
    return response.data
  }

  async last(membershipNumber, cooperativeId) {
    const list = await this.index(membershipNumber, cooperativeId)
    if (!list || list.length === 0) {
      return null
    }
    return list[0]
  }

  // eslint-disable-next-line class-methods-use-this
  async remakeCheckoutGetForSafari(fullURL) {
    const fetchOptions = {
      mode: 'cors',
      cache: 'default',
    }

    const auth = useAuthService()
    const token = await auth.getAccessToken()
    if (token) {
      fetchOptions.headers = { authorization: `Bearer ${token}` }
    }

    const cartRequest = new Request(fullURL, { ...fetchOptions, method: 'POST' })
    const cartResponse = await fetch(cartRequest)
    const checkoutURL = cartResponse.url
    const checkoutRequest = new Request(checkoutURL, { ...fetchOptions, method: 'GET' })
    const checkoutResponse = await fetch(checkoutRequest)
    return checkoutResponse.json()
  }

  async postCheckout(cartId) {
    try {
      const response = await this.api.post(`/carts/${cartId}/checkouts`)
      return response.data
    } catch (error) {
      if (error.response.status === 401) {
        const fullUrl = `${this.cartApiHost}/v1/bff/appro/carts/${cartId}/checkouts`

        return this.remakeCheckoutGetForSafari(fullUrl)
      }
      throw error
    }
  }

  async createCheckout({ id: cartId, checkout_id: checkoutId }) {
    if (!checkoutId) return this.postCheckout(cartId)
    try {
      const response = await this.api.get(`/checkouts/${checkoutId}`)
      return response.data
    } catch (error) {
      if (error.response.status === 404) return this.postCheckout(cartId)
      throw error
    }
  }
}

export default CartService
