import type { AxiosError, AxiosInstance } from '@b2ag/axios'

interface ProductThumbnail {
  public_id: string
  url: string
  resource_type: string // maybe enum possible here ?
  width: number
  height: number
}

export interface ProductMediasApi {
  thumbnail: ProductThumbnail
}

export class MediaService {
  private readonly client: AxiosInstance

  constructor(mediaApiClient: AxiosInstance) {
    this.client = mediaApiClient
  }

  async getProductThumbnailUrl(partnerId: number, productId: string): Promise<string | null> {
    try {
      const result = await this.client.get<ProductMediasApi>(`/partners/${partnerId}/products/${productId}/medias`)
      return result.data?.thumbnail?.url ?? null
    } catch (err: any) {
      const error: AxiosError = err
      // eslint-disable-next-line no-console
      console.error(error)
      return null
    }
  }

  async getProductThumbnailUrls(productIdList: string[], partnerId: number): Promise<Map<string, string>> {
    const idMap = new Map<string, string>()
    try {
      await Promise.allSettled(
        productIdList.map(async (productId) => {
          const url = await this.getProductThumbnailUrl(partnerId, productId)
          if (url) idMap.set(productId, url)
        }),
      )
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    return idMap
  }
}
