import { computed, type ComputedRef, ref } from 'vue'
import { getProductDefaultImageFull } from '@b2ag/product/src/services/products-images.constants'
import type { Product } from '@b2ag/product/src/domain/product'
import { useMediaService } from '@b2ag/medias'

export function useProductImage(product: ComputedRef<Product>) {
  const mediaService = useMediaService()
  const customPartnerProductImage = ref('')
  const defaultImage = computed(() => getProductDefaultImageFull(product.value.kind, product.value.type))
  const image = computed(
    () => customPartnerProductImage.value ?? product?.value.images?.[0] ?? defaultImage.value ?? '',
  )

  async function fetchCustomPartnerProductImage(productId: string, partnerId: number): Promise<void> {
    customPartnerProductImage.value = (await mediaService.getProductThumbnailUrl(partnerId, productId)) ?? ''
  }

  return {
    image,
    fetchCustomPartnerProductImage,
  }
}
