import type { IHubRepository } from './domain/repository/IHubRepository'
import { HubRepository } from './infra/repository/hub.repository'
import type { IArticleRepository } from './domain/repository/IArticleRepository'
import { contentfulClient, searchService } from './application/services'
import { ArticleRepository } from './infra/repository/article.repository'
import { ArticleUseCase } from './domain/use-case/article.use-case'

const iArticleRepository = new ArticleRepository(contentfulClient) as IArticleRepository
const iHubRepository = new HubRepository(contentfulClient) as IHubRepository
const articleUseCase = new ArticleUseCase(iArticleRepository, searchService)

export { articleUseCase, ArticleUseCase, iArticleRepository, iHubRepository }
