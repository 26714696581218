import type { AxiosInstance } from '@b2ag/axios'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { mapFromApi } from './cooperative.mapper'

class CooperativeService {
  api: AxiosInstance

  constructor(userApiHost: string) {
    this.api = createAxiosClientWithAladinBearer(`${userApiHost}/v1`, { callerId: buildCallerId() })
  }

  // TODO: doublon avec partner service
  async get() {
    const response = await this.api.get('/cooperatives')
    return response.data.map(mapFromApi)
  }

  newPartnerAlert(partnerName: string): Promise<unknown> {
    return this.api.post('/partners/send-new-partner-request-email-alert', { partner_name: partnerName })
  }
}

export default CooperativeService
