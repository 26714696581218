import { DISCOUNT_TYPE } from '@b2ag/pricing'
import Big from 'big.js'

export interface Discountable {
  isSoldAsUnit: boolean
  quantity: number
  measureQuantity: number
  unitPrice: number
  perMeasurePrice: number
}

const calculateTotalQuantity = (acc: Big, value: Discountable): Big => {
  const quantity = value.isSoldAsUnit ? Big(value.quantity) : Big(value.quantity).times(value.measureQuantity)
  return quantity.add(acc)
}

const getTotalQuantityFromDiscountables = (discountables: Discountable[] = []) => {
  return +discountables.reduce(calculateTotalQuantity, Big(0))
}

const getTotalRevenueFromDiscountables = (discountables: Discountable[]) => {
  return discountables.reduce((a, lineItemFilteredByDiscount) => {
    if (lineItemFilteredByDiscount.isSoldAsUnit)
      return a + lineItemFilteredByDiscount.quantity * lineItemFilteredByDiscount.unitPrice
    return (
      a +
      lineItemFilteredByDiscount.quantity *
        lineItemFilteredByDiscount.perMeasurePrice *
        lineItemFilteredByDiscount.measureQuantity
    )
  }, 0)
}

function getAdvancement(discountables: Discountable[], type: DISCOUNT_TYPE): number {
  return type === DISCOUNT_TYPE.REVENUE
    ? getTotalRevenueFromDiscountables(discountables)
    : getTotalQuantityFromDiscountables(discountables)
}

function isDiscountSimple({ type }) {
  return type === DISCOUNT_TYPE.SIMPLE
}

export const discount = {
  getAdvancement,
  isDiscountSimple,
}
