/* eslint-disable max-classes-per-file */

import Discount from './Discount'
import { DISCOUNT_TYPE } from './constants/DISCOUNT_TYPE'
import DiscountAmount, { type AmountProps } from './domain-objects/DiscountAmount'
import { DiscountScale } from './domain-objects/DiscountScale'
import DiscountScaleSlice, { type DiscountScaleSliceProps } from './domain-objects/DiscountScaleSlice'

export interface DiscountApplication<T extends DISCOUNT_TYPE = DISCOUNT_TYPE> {
  readonly type: T
  readonly value: T extends DISCOUNT_TYPE.SIMPLE ? DiscountAmount : DiscountScale
  readonly offerIdList: string[]
  getDiscount(number?: number): Discount | undefined
  bestDiscount(): Discount
}

export interface DiscountApplicationProps {
  type: DISCOUNT_TYPE
  slices: DiscountScaleSliceProps[] | null
  amount: AmountProps | null
  offerIdList: string[]
}

export class DiscountOnRevenue implements DiscountApplication<DISCOUNT_TYPE.REVENUE> {
  readonly type = DISCOUNT_TYPE.REVENUE

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public readonly value: DiscountScale,
    public readonly offerIdList: string[],
  ) {}

  getDiscount(number: number) {
    return this.value.getDiscount(number)
  }

  bestDiscount(): Discount {
    let bestDiscount = this.value.slices[0].discount
    this.value.slices.forEach((discountScaleSlice: DiscountScaleSlice) => {
      if (discountScaleSlice.discount.discount > bestDiscount.discount) {
        bestDiscount = discountScaleSlice.discount
      }
    })
    return bestDiscount
  }
}

export class DiscountOnQuantity implements DiscountApplication<DISCOUNT_TYPE.QUANTITATIVE> {
  public readonly type = DISCOUNT_TYPE.QUANTITATIVE

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public readonly value: DiscountScale,
    public readonly offerIdList: string[],
  ) {}

  getDiscount(number: number) {
    return this.value.getDiscount(number)
  }

  bestDiscount(): Discount {
    let bestDiscount = this.value.slices[0].discount
    this.value.slices.forEach((discountScaleSlice: DiscountScaleSlice) => {
      if (discountScaleSlice.discount.discount > bestDiscount.discount) {
        bestDiscount = discountScaleSlice.discount
      }
    })
    return bestDiscount
  }
}

export class DiscountSimple implements DiscountApplication<DISCOUNT_TYPE.SIMPLE> {
  public readonly type = DISCOUNT_TYPE.SIMPLE

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public readonly value: DiscountAmount,
    public readonly offerIdList: string[],
  ) {}

  getDiscount(): Discount {
    return this.value.getDiscount()
  }

  bestDiscount(): Discount {
    return this.getDiscount()
  }
}
