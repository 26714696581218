declare interface IStorageKey<T> {}
export type StorageKey<T extends {} = {}> = IStorageKey<T> & string
type ExtractType<K> = K extends StorageKey<infer T> ? T : {}

export function setLocalStorageJSON<K extends StorageKey>(key: K, data: ExtractType<K>): void {
  localStorage.setItem(key, JSON.stringify(data))
}

export function getLocalStorageJSON<K extends StorageKey>(key: K): ExtractType<K> | null {
  const itemFromLocalStorage = localStorage.getItem(key)
  return itemFromLocalStorage ? JSON.parse(itemFromLocalStorage) : null
}

export function removeLocalStorage<K extends StorageKey>(key: K): void {
  localStorage.removeItem(key)
}
