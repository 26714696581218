import Big from 'big.js'

import { type Cart, getPreShippingLineItemIds, getPreShippingStoreCode } from '@b2ag/cart/src/cart.business'
import type { LineItem } from '@b2ag/cart/src/lineItem'
import type { Offer } from '@b2ag/offers/src/offer'

export type OfferQuantity = Pick<Offer, 'offerId' | 'quantityMax' | 'quantityMin' | 'quantityStep' | 'pricing'>

export function getQuantityAlreadyInCart(offer: OfferQuantity, lineItems: LineItem[] = []) {
  return lineItems.find((lineItem) => lineItem.offer_id === offer.offerId)?.quantity || 0
}

export function computeQuantityMax(
  offer: Pick<Offer, 'quantityMax' | 'allowedWithoutStock'>,
  stockManagementType,
  quantityAlreadyInCart: number,
  managedStockQuantity: number | undefined,
  quotaRemaining: number | undefined,
) {
  const stockQuantity = stockManagementType === 'managed' ? managedStockQuantity : Infinity

  const offerQuantityMax = offer.quantityMax ?? Infinity
  const stockQuantityMax = stockQuantity ?? Infinity
  const quotaQuantityMax = quotaRemaining ?? Infinity

  const smallestQuantity = offer.allowedWithoutStock
    ? Math.min(offerQuantityMax, quotaQuantityMax)
    : Math.min(offerQuantityMax, stockQuantityMax, quotaQuantityMax)

  return smallestQuantity === Infinity ? smallestQuantity : +Big(smallestQuantity).minus(quantityAlreadyInCart)
}

export function getBaseQuantity(offer: OfferQuantity, quantityAlreadyInCart: number) {
  if (quantityAlreadyInCart > 0) return offer.quantityStep
  return offer.quantityMin
}

export function getQuantityMin(offer: OfferQuantity, quantityAlreadyInCart: number) {
  if (quantityAlreadyInCart > 0) return offer.quantityStep
  return offer.quantityMin
}

export function computeOfferQuantityDiscount(isSoldAsUnit, offerId, discountId, quantity, measureQuantity) {
  return { offerId, discountId, quantity: isSoldAsUnit ? quantity : quantity * measureQuantity }
}

export function variantAvailableStock(offerId: string, cart: Cart) {
  const preShippingLineItemIds = getPreShippingLineItemIds(cart)
  const offerVariantId = offerId.split(':')[1]
  return cart
    ? cart.line_items.reduce(
        (acc, lineItem) => {
          const lineItemVariantId = lineItem.offer_id.split(':')[1]
          const lineItemQuantity = lineItem.is_sold_as_unit
            ? lineItem.quantity
            : lineItem.quantity * lineItem.measure_quantity
          if (
            lineItemVariantId === offerVariantId &&
            lineItem.offer_id !== offerId &&
            !lineItem.allowed_without_stock
          ) {
            if (preShippingLineItemIds?.includes(lineItem.id)) {
              // eslint-disable-next-line no-param-reassign
              acc.clickAndCollect += lineItemQuantity
            } else {
              // eslint-disable-next-line no-param-reassign
              acc.quantity += lineItemQuantity
            }
          }

          return acc
        },
        { quantity: 0, clickAndCollect: 0, storeCode: getPreShippingStoreCode(cart) },
      )
    : { quantity: 0, clickAndCollect: 0, storeCode: undefined }
}
