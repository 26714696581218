import { reactive } from 'vue'
import { useRouter } from './useRouter'

interface Route {
  path: string
  name: string
  params: any
  query: string
  hash: string
  fullPath: string
  matched: string
  meta: string
  redirectedFrom: string
}

let currentRoute: Route

function assign(target: Record<string, any>, source: Record<string, any>) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(source)) {
    // eslint-disable-next-line no-param-reassign
    target[key] = source[key]
  }
  return target
}

// Inspire by abmit-tsai version
// @see https://github.com/ambit-tsai/vue2-helpers/blob/main/src/vue-router.ts
export const useRoute = () => {
  const router = useRouter()
  if (!currentRoute && router) {
    currentRoute = reactive(assign({}, router.currentRoute)) as any
    router?.afterEach((to) => {
      assign(currentRoute, to)
    })
  }
  return currentRoute!
}
