import { type Offer, variantId } from '@b2ag/offers/src/offer'
import type { CooperativeVariant } from '@b2ag/product/src/services/product'
import { type EnhancedLogisticOfferForSale, LogisticRoute } from '@invivodf/module-logistic-offer-client-sdk'
import { ShippingMethodKind } from '@invivodf/module-logistic-offer-client-sdk/dist/convert-logistic-route-to-shipping-method-kind/convert-logistic-route-to-shipping-method-kind'
import Big from 'big.js'
import uniq from 'lodash/uniq'

import { parseAccepts, storeAccepts, type StoreModel } from './store.business'

export enum ShippingMethodShortTitles {
  Farm = 'À domicile',
  PickupPoint = 'Livraison relais coopérative',
  ClickAndCollect = 'Retrait magasin',
}

export enum ShippingMethodTitles {
  Farm = 'Livraison à la ferme',
  PickupPoint = 'Livraison relais coopérative',
  ClickAndCollect = 'Retrait en magasin',
}

export interface StockShippingMethod {
  kind: ShippingMethodKind
  short_title: ShippingMethodShortTitles
  title: ShippingMethodTitles
}

export interface Store {
  code: string
  quantity: number
  shipping_methods: StockShippingMethod[]
}

export interface Stock {
  variant_id: string
  cooperative_id: number
  quantity: number
  click_and_collect?: number
  updated_at: string
  stores: Store[]
}

// Ce code est un copié collé du back et est destiné à être supprimé dès l'activation de la feature des stocks
// Les cooperatives ont demandés d'adapter le code actuel pour savoir
// si un store accepte ou non un packaging mais sans la feature des stocks
// Ce fichier n'est pas testé car il est destiné à être supprimé rapidement et il est déjà
// entierement testé unitairement dans le code back

export const filterStoresShippingMethods =
  (offerLogisticOffers: EnhancedLogisticOfferForSale[]) => (storeShippingMethod: StockShippingMethod) =>
    logisticRoutesToStockKinds(offerLogisticOffers).includes(storeShippingMethod.kind)

export function computeStockQuantityByMeasure(measureQuantity: number, stockQuantity: number): number {
  return +Big(stockQuantity || 0)
    .div(measureQuantity)
    .round(0, 0)
}

export function computeClickAndCollectStockQuantity(
  quantity: number,
  isSoldAsUnit: boolean,
  measureQuantity: number,
): number {
  if (isSoldAsUnit) return quantity
  return +Big(quantity || 0).div(measureQuantity ?? 1)
}

function storeHasShippingMethods(store: Store, shippingMethods: ShippingMethodKind[]): boolean {
  return store.shipping_methods.some(({ kind }) => shippingMethods.includes(kind))
}

function offerHasOnlyShippingMethods(offer, shippingMethods: ShippingMethodKind[]): boolean {
  return (
    offer.logisticOffers?.every(({ shippingMethodKind }) => shippingMethods.includes(shippingMethodKind)) &&
    offer.logisticOffers?.length === shippingMethods.length
  )
}

export const isClickAndCollectStockAvailable =
  (offer: Offer) =>
  (store: Store): boolean => {
    const computedStoreStockQty =
      !offer.isSoldAsUnit && offer.measureQuantity
        ? computeStockQuantityByMeasure(offer.measureQuantity, store.quantity)
        : store.quantity

    if (storeHasShippingMethods(store, [ShippingMethodKind.ClickAndCollect])) {
      return computedStoreStockQty >= offer.quantityMin
    }

    return false
  }

export const isShippingStockAvailable = (offer: Offer, shippableStockQuantity: number): boolean => {
  const computedStockQty =
    !offer.isSoldAsUnit && offer.measureQuantity
      ? computeStockQuantityByMeasure(offer.measureQuantity, shippableStockQuantity)
      : shippableStockQuantity

  if (!offerHasOnlyShippingMethods(offer, [ShippingMethodKind.ClickAndCollect])) {
    return computedStockQty >= offer.quantityMin
  }

  return false
}

export const hasStockAvailable =
  (offer: Offer, shippableStockQuantity: number) =>
  (store: Store): boolean => {
    return isClickAndCollectStockAvailable(offer)(store) || isShippingStockAvailable(offer, shippableStockQuantity)
  }

export const filterLogisticOffers =
  ({ accepts, packaging }: { accepts: string[]; packaging: string }) =>
  (logisticOffer: Pick<EnhancedLogisticOfferForSale, 'logisticRoute'>): boolean =>
    logisticOffer.logisticRoute !== LogisticRoute.PickupPoint || storeAccepts({ accepts, packaging })

export const filterAllowedLogisticOffers = (
  stores: StoreModel[],
  variants: Pick<CooperativeVariant, 'packaging' | 'id'>[],
  offers: Offer[],
): void => {
  const accepts = uniq(stores.flatMap(parseAccepts))
  // eslint-disable-next-line no-restricted-syntax
  for (const offer of offers)
    if (offer.stockManagementType === 'notManaged' && offer.productIsRestrictedExplosivesPrecursor) {
      const packaging = variants.find((variant) => variant.id === offer[variantId])?.packaging || ''
      offer.logisticOffers = offer.logisticOffers?.filter(filterLogisticOffers({ accepts, packaging }))
    }
}

export function computeOrderableQuantity(offer: Offer, storeStock: number): number {
  let offerQuantityMax = Infinity
  if (offer.quantityMax && offer.measureQuantity) {
    offerQuantityMax = offer.isSoldAsUnit ? offer.quantityMax : +Big(offer.measureQuantity).mul(offer.quantityMax)
  }
  return Math.min(storeStock, offerQuantityMax)
}

export function getMeasuredQuantity(quantity: number, isSoldAsUnit: boolean, measureQuantity: number): number {
  const measureFactor = isSoldAsUnit ? 1 : measureQuantity ?? 1

  return +Big(measureFactor).mul(quantity)
}

function logisticRoutesToStockKinds(offers: EnhancedLogisticOfferForSale[]): ShippingMethodKind[] {
  return offers.map((offer) => offer.shippingMethodKind)
}

export function canDeliverWithClickAndCollect(offer: Offer) {
  return offer.logisticOffers?.some((lo) => lo.canDeliverByClickAndCollect) || false
}

export function computeIsOfferOutOfStock(offer: Offer, stock?: Stock) {
  if (offer.stockManagementType === 'notManaged' || offer.allowedWithoutStock) return false
  const stockAvailableInClickAndCollect =
    !offer.isSoldAsUnit && offer.measureQuantity
      ? computeStockQuantityByMeasure(offer.measureQuantity, stock?.click_and_collect || 0)
      : stock?.click_and_collect ?? 0

  const hasStockClickAndCollect = canDeliverWithClickAndCollect(offer) && stockAvailableInClickAndCollect > 0

  return !(isShippingStockAvailable(offer, stock?.quantity || 0) || hasStockClickAndCollect)
}

export function noStockAndNotAllowedWithoutStock(offer: Offer, isOfferOutOfStock: boolean) {
  return isOfferOutOfStock && !offer.allowedWithoutStock
}

export const canStoreDeliverInFarm = (store: Store) => {
  return store.shipping_methods.map((shippingMethod) => shippingMethod.kind).includes(ShippingMethodKind.Farm)
}

export function recomputeStock(
  stock: Stock,
  consumedStock: { quantity: number; clickAndCollect: number; storeCode: string | undefined },
) {
  let remainingStock = stock.quantity - consumedStock.quantity
  const remainingClickAndCollect = stock.click_and_collect! - consumedStock.clickAndCollect
  const storeMap = new Map(stock.stores.map((store) => [store.code, store]))
  storeMap.forEach((store, code, map) => {
    const canDeliverInFarm = canStoreDeliverInFarm(store)
    if (store.code === consumedStock.storeCode) {
      map.set(consumedStock.storeCode, {
        ...store,
        quantity: store.quantity - consumedStock.clickAndCollect,
      } as Store)
      if (canDeliverInFarm) remainingStock -= consumedStock.clickAndCollect
    }
    if (canDeliverInFarm && map.get(code)!.quantity > remainingStock) {
      map.set(store.code, {
        ...store,
        quantity: remainingStock,
      } as Store)
    }
  })

  return {
    ...stock,
    quantity: remainingStock,
    click_and_collect: remainingClickAndCollect,
    stores: Array.from(storeMap.values()),
  }
}
