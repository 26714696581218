import { ref, onBeforeMount, onBeforeUnmount } from 'vue'

const DESKTOP_WIDTH = 992

export function useMobileListener(mobileThreshold = DESKTOP_WIDTH) {
  const isMobile = ref(window.innerWidth < mobileThreshold)

  function onContainerResize() {
    isMobile.value = window.innerWidth < mobileThreshold
  }

  onBeforeMount(() => {
    onContainerResize()
    window.addEventListener('resize', onContainerResize, { passive: true })
  })

  onBeforeUnmount(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', onContainerResize)
    }
  })

  return {
    isMobile,
  }
}
