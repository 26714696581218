import algoliasearch from 'algoliasearch/lite'
import ContentfulClient from '@b2ag/contentful'
import { AlgoliaSearchService } from '@b2ag/search'
import ProductService from '@b2ag/product/src/services/product.service'
import { getConfig } from '@b2ag/micro-front-tools'

const contentfulSpaceId = getConfig('CONTENTFUL_SPACE_ID')
const contentfulApiKey = getConfig('CONTENTFUL_API_KEY')
const environmentName = getConfig('ENVIRONMENT_NAME')
const searchAppId = getConfig('ALGOLIA_APP_ID')
const searchApiKey = getConfig('ALGOLIA_API_KEY')
export const searchIndex = getConfig('ALGOLIA_INDEX')
export const searchIndexCoop = getConfig('ALGOLIA_INDEX_COOP_PREFIX')

const productApiHost = getConfig('PRODUCT_API_HOST')

const algoliaClient = algoliasearch(searchAppId, searchApiKey)

const contentfulClient = new ContentfulClient({ contentfulSpaceId, contentfulApiKey, environmentName })
const searchService = new AlgoliaSearchService(algoliaClient)
const productService = new ProductService(productApiHost)

export { contentfulClient, searchService, productService }
