import ContentfulClient from '@b2ag/contentful'
import { NATIONAL_SCOPE } from '../../domain/utils/constantes'
import type { IBannerRepository } from '../../domain/repository/IBannerRepository'
import type { Banner } from '../../domain/blocs/Banner'
import { mapBannerFromApi } from '../mapper/homeBanner.mapper'

const MAX_BANNERS = 2

export class BannerRepository implements IBannerRepository {
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(public readonly client: ContentfulClient) {}

  async getBannersByFilter(
    filter: {
      tags?: string[]
    } = {
      tags: [],
    },
    partnerScope: string = NATIONAL_SCOPE,
  ): Promise<Array<Banner>> {
    const query = {
      include: 1,
      content_type: 'banner',
      'fields.partnerFilter[in]': partnerScope,
      limit: MAX_BANNERS,
    }
    if (filter.tags?.length) query['metadata.tags.sys.id[in]'] = filter.tags.join(',')

    return this.client.api
      .getEntries<any>(query)
      .then((content) => content.items.map(mapBannerFromApi).filter(Boolean) as Banner[])
  }
}
