import { inject, provide, reactive } from 'vue'
import { CategoryDescription } from '../../domain/blocs/CategoryDescription'
import { categoryDescriptionUseCase } from '../../dependency-injection'

let categoryDescriptionStore

export interface CategoryDescriptionStore {
  state: CategoryDescriptionState
  updateCurrentDescription(categoryName: string): Promise<CategoryDescription | null>
  resetDescriptions(): void
}

export interface CategoryDescriptionState {
  current: CategoryDescription | undefined
  isLoadingDescription: boolean
}

export function createCategoryDescriptionStore(): CategoryDescriptionStore {
  const state = reactive<CategoryDescriptionState>({
    isLoadingDescription: false,
    current: undefined,
  })
  let descriptionMap: Record<string, CategoryDescription | undefined> = {}

  async function updateCurrentDescription(categoryName: string): Promise<CategoryDescription | null> {
    if (!state.isLoadingDescription) {
      state.isLoadingDescription = true
      if (!(categoryName in descriptionMap)) {
        descriptionMap[categoryName] = await categoryDescriptionUseCase(categoryName)
      }
      state.current = descriptionMap[categoryName]
    }

    state.isLoadingDescription = false
    return state.current ?? null
  }

  function resetDescriptions() {
    state.current = undefined
    descriptionMap = {}
  }

  return {
    state,
    resetDescriptions,
    updateCurrentDescription,
  }
}

const CATEGORY_DESCRIPTION_SYMBOL = Symbol('CATEGORY_DESCRIPTION_SYMBOL')

export function provideCategoryDescription() {
  if (!categoryDescriptionStore) {
    categoryDescriptionStore = createCategoryDescriptionStore()
  }
  provide<CategoryDescriptionStore>(CATEGORY_DESCRIPTION_SYMBOL, categoryDescriptionStore)
}

export function useCategoryDescription(): CategoryDescriptionStore {
  const store = inject<CategoryDescriptionStore>(CATEGORY_DESCRIPTION_SYMBOL)
  if (!store) {
    throw new Error('CategoryDescription store was not initialized.')
  }
  return store
}

export function useCategoryDescriptionOptionsApi(): CategoryDescriptionStore {
  if (!categoryDescriptionStore) {
    throw new Error('CategoryDescriptionStore was not initialized.')
  }
  return categoryDescriptionStore
}
