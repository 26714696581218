import { BlocType } from '@b2ag/marketing/src/domain/BlocType'
import type { ArticleThumbnails } from '../../domain/ArticleThumbnails'
import { contentfulContentToArticle } from './article.mapper'

export function mapArticleThumbnails(dto: any): ArticleThumbnails {
  return {
    blocType: BlocType.PUSH_ARTICLES,
    title: dto?.fields?.title,
    description: dto?.fields?.description,
    backgroundColor: dto?.fields?.backgroundColor,
    anchorLabel: dto?.fields?.anchorLabel,
    highlighted: dto?.fields?.highlighted || false,
    articles: dto?.fields?.articles?.map(contentfulContentToArticle),
    buttonLabel: dto?.fields?.buttonLabel,
    buttonUrl: dto?.fields?.buttonUrl,
    isManual: dto?.fields?.isManual ?? true,
    supplierName: dto?.fields?.partnerName,
    tags: dto?.metadata?.tags?.map((tag) => tag.sys.id),
  } as ArticleThumbnails
}
