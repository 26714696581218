import { i18n as _i18n } from '@lingui/core'

import { type AppBrand } from '@b2ag/app-brands'
import allMessages from '../../messages'

export const loadLocaleFor = (appContext: AppBrand, lang = 'fr-FR') => {
  const customLocale = localStorage.getItem('customLocale')

  // @ts-ignore
  _i18n.load(lang, allMessages[lang + '_' + (customLocale || appContext)])
  _i18n.activate(lang)
}
