import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import type { AxiosInstance } from '@b2ag/axios'
import pick from 'lodash/pick'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { mapFromApi as mapCooperativeFromApi } from './cooperative.mapper'
import { mapFromApi as mapProfileFromApi, mapToApi as mapProfileToApi } from './profile.mapper'

function formatResponseData({ data }) {
  const { cooperatives = [], profiles = [], current_profile_id: currentProfileId, ...others } = data
  return {
    ...others,
    currentProfileId,
    cooperatives: cooperatives.map(mapCooperativeFromApi),
    profiles: profiles.map(mapProfileFromApi),
  }
}

class UserService {
  api: AxiosInstance

  apiv2: AxiosInstance

  constructor(userApiHost: string) {
    this.api = createAxiosClientWithAladinBearer(`${userApiHost}/v1/users`, { callerId: buildCallerId() })
    this.apiv2 = createAxiosClientWithAladinBearer(`${userApiHost}/v2/users`, { callerId: buildCallerId() })
  }

  get(): Promise<any> {
    return this.api.get('/me').then(formatResponseData)
  }

  createProfile(cooperative): Promise<number> {
    return this.apiv2
      .post('/me/profiles', {
        profile: {
          membershipNumber: cooperative.membershipNumber,
          partnerId: cooperative.id,
        },
      })
      .then(({ data }) => data.id)
  }

  put(data): Promise<any | null> {
    if (!data) return Promise.resolve(null)
    if (data.profiles && data.profiles.length < 1) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ detail: 'Vous devez conserver au moins un partenaire' })
    }
    const dataToSend = { ...data }

    if (dataToSend.profiles) {
      dataToSend.profiles = dataToSend.profiles.map(mapProfileToApi)
    }

    if (dataToSend.current_profile) {
      const currentProfile = pick(dataToSend.current_profile, ['id', 'cooperative'])
      dataToSend.current_profile = mapProfileToApi(currentProfile)
    }

    return this.api.put('/me', dataToSend).then(formatResponseData)
  }
}

export default UserService
