import type { CategoryFacet } from '@b2ag/search'
import { SearchStoreGetters } from './interfaces/search.interface'
import { getServicesForStore } from '.'

const DEFAULT_FAMILY_NAME = ''
const DEFAULT_CATEGORY_NAME = ''

export interface SearchState {
  search: {
    currentIndex: string
    searchQuery: string
  }
  navigation: {
    categories: CategoryFacet[]
    selectedCategoryName: string
    selectedCategoryRoute: string[]
    selectedFamilyName: string
    selectedSupplier: { name: string } | null
  }
}

const initialSearchState: SearchState = {
  search: {
    currentIndex: '',
    searchQuery: '',
  },
  navigation: {
    categories: [] as CategoryFacet[],
    selectedCategoryName: DEFAULT_CATEGORY_NAME,
    selectedCategoryRoute: [],
    selectedFamilyName: DEFAULT_FAMILY_NAME,
    selectedSupplier: null as { name: string } | null,
  },
}

const getDefaultState = () => {
  return initialSearchState
}

export const actions = {
  switchSearchIndex(context, index) {
    const { searchService } = getServicesForStore()
    searchService?.initSearchIndexes(index, (window as any).env.ALGOLIA_INDEX_CATEGORIES)
    context.commit('UPDATE_INDEX', index)
  },
  search(context, query) {
    context.commit('SEARCH', query)
  },
  async reloadCategories(context) {
    const { searchService } = getServicesForStore()
    context.commit('SET_CATEGORIES', await searchService?.getCategoriesFacets())
  },
  async retrieveUserCategories(context) {
    const { currentCooperativeId, currentIndex } = context.getters
    const newIndex = (window as any).env.ALGOLIA_INDEX_COOP_PREFIX + currentCooperativeId
    if (currentIndex !== newIndex) {
      await context.dispatch('switchSearchIndex', newIndex)
    }
    await context.dispatch('reloadCategories')
  },
  async retrieveNationalCategories(context) {
    const { currentIndex } = context.getters
    const newIndex = (window as any).env.ALGOLIA_INDEX
    if (currentIndex !== newIndex) {
      await context.dispatch('switchSearchIndex', newIndex)
    }
    await context.dispatch('reloadCategories')
  },
  setCategory(context, categoryName) {
    context.commit('SET_CATEGORY', categoryName)
  },
  setCategoryLevelRoute(context, categoryNameRoute) {
    context.commit('SET_CATEGORY_ROUTE', categoryNameRoute)
  },
  setFamily(context, familyName) {
    context.commit('SET_FAMILY', familyName)
  },
  resetSelectedFamily(context) {
    context.commit('RESET_SELECTED_FAMILY')
  },
  resetSelectedCategory(context) {
    context.commit('RESET_SELECTED_CATEGORY')
  },
  setSelectedSupplier(context, supplier) {
    context.commit('SET_SELECTED_SUPPLIER', supplier)
  },
  resetSelectedSupplier(context) {
    context.commit('RESET_SELECTED_SUPPLIER')
  },
  resetSearchState(context) {
    context.commit('RESET_STATE')
  },
}

export const mutations = {
  UPDATE_INDEX(state: SearchState, index) {
    state.search.currentIndex = index
  },
  SEARCH(state: SearchState, query) {
    state.search.searchQuery = query
  },
  SET_CATEGORIES(state: SearchState, categories) {
    state.navigation.categories = categories
  },
  SET_FAMILY(state: SearchState, familyName) {
    state.navigation.selectedFamilyName = familyName
  },
  RESET_SELECTED_FAMILY(state: SearchState) {
    state.navigation.selectedFamilyName = DEFAULT_FAMILY_NAME
  },
  SET_CATEGORY(state: SearchState, categoryName) {
    state.navigation.selectedCategoryName = categoryName
  },
  SET_CATEGORY_ROUTE(state: SearchState, categoryNameRoute) {
    state.navigation.selectedCategoryRoute = categoryNameRoute
  },
  RESET_SELECTED_CATEGORY(state: SearchState) {
    state.navigation.selectedCategoryName = DEFAULT_CATEGORY_NAME
    state.navigation.selectedCategoryRoute = []
  },
  SET_SELECTED_SUPPLIER(state: SearchState, supplier) {
    state.navigation.selectedSupplier = supplier
  },
  RESET_SELECTED_SUPPLIER(state: SearchState) {
    state.navigation.selectedSupplier = null
  },
  RESET_STATE(state: SearchState) {
    Object.assign(state, getDefaultState())
  },
}

type GetterImplementation<T extends keyof SearchStoreGetters> = (
  state: SearchState,
  getters?: SearchStoreGetters,
) => SearchStoreGetters[T]

export const getters: { [K in keyof SearchStoreGetters]: GetterImplementation<K> } = {
  currentIndex(state: SearchState) {
    return state.search.currentIndex
  },
  searchQuery(state: SearchState) {
    return state.search.searchQuery
  },
  getCategories(state: SearchState) {
    return state.navigation.categories
  },
  selectedFamilyName(state: SearchState) {
    return state.navigation.selectedFamilyName
  },
  selectedCategoryName(state: SearchState) {
    return state.navigation.selectedCategoryName
  },
  selectedCategoryRoute(state: SearchState) {
    return state.navigation.selectedCategoryRoute
  },
  selectedSupplierName(state: SearchState) {
    return state.navigation.selectedSupplier?.name || ''
  },
  getSelectedCategories(state: SearchState) {
    const { categories, selectedFamilyName, selectedCategoryRoute } = state.navigation
    const root = categories?.find((cat) => cat.label === selectedFamilyName)
    return (root && selectedCategoryRoute) || []
  },
  selectedSupplier(state) {
    return state.navigation.selectedSupplier
  },
}

const moduleSearch = {
  state: getDefaultState(),
  actions,
  mutations,
  getters,
}

export default moduleSearch
