import type { SupplierHighlightDto } from '../../domain/blocs/SupplierHighlightDto'
import type { SupplierHighlight } from '../../domain/blocs/SupplierHighlight'

export const supplierHighlightMapper = (dto: SupplierHighlightDto | null): SupplierHighlight | null => {
  if (!dto) return null
  return {
    productsIdList: dto.fields.articlesCodes,
    categoryName: dto.fields.categoryCode,
    imageUrl: dto.fields.highlightURL,
    supplierLogoUrl: dto.fields.supplierLogoURL,
    supplierName: dto.fields.supplierName,
    text: dto.fields.text,
  }
}
