// eslint-disable-next-line import/extensions
import { useAnalyticService } from '@b2ag/plugin-aladin-gtm'
import { logUnit } from '@b2ag/price/src/helpers/unit'
import type { CooperativeProduct } from '@b2ag/product/src/domain/product'
import type { FullProduct } from '@b2ag/product/src/services/shop.business'
import type { AddToCartOptions } from '@b2ag/shared-components/src/presenters/offer/POfferAddToCartBtn/AddToCartOptions'
import { useStore } from '@b2ag/store'
import { computed, ref } from 'vue'
import { useEnv } from './useEnv'
import { useLogger } from './useLogger'

export type LineOptionFromOption = ReturnType<typeof buildLineItemFromOption>
export type AddToCartTrackingData = ReturnType<typeof getAddToCartTrackingData>

export const getAddToCartTrackingData = (
  lineItemToAdd: LineOptionFromOption,
  currentProduct: FullProduct<CooperativeProduct> | undefined,
  options: AddToCartOptions,
) => {
  const product = {
    _id: currentProduct?._id,
    name: currentProduct?.name,
    supplier: currentProduct?.supplier,
    family: currentProduct?.family,
    categories: currentProduct?.categories,
  }

  const { variant, quantity = 1 } = lineItemToAdd
  const filterOffers = options.variant.offers.filter((offer) => offer.offerId === lineItemToAdd.offer_id)
  const cropProtectionLabel = variant.cropProtectionLabel ? `${variant.cropProtectionLabel} -` : ''
  const packagingLabel = `${cropProtectionLabel} ${variant.measure_quantity} ${logUnit(variant.measure_unit)}`
  const price = filterOffers[0].unitPrice * quantity
  const { unitPrice } = filterOffers[0]
  const variantId = options.variant.id

  return {
    product,
    quantity,
    packagingLabel,
    price,
    unitPrice,
    variantId,
    offerId: lineItemToAdd.offer_id,
  }
}

function addToCartPayload(eventPayload: AddToCartTrackingData) {
  const { product, quantity, packagingLabel, price, unitPrice, variantId, offerId } = eventPayload
  const category =
    product.categories && product.categories[0] && product.categories[0][1] && product.categories[0][1].label
  const category3 =
    product.categories && product.categories[0] && product.categories[0][2] && product.categories[0][2].label
  const category4 =
    product.categories && product.categories[0] && product.categories[0][3] && product.categories[0][3].label

  const payload = {
    ecommerce: {
      value: price,
      currency: 'EUR',
      items: [
        {
          offer_id: offerId,
          item_name: product.name,
          item_id: product._id,
          item_brand: product.supplier,
          item_category: product.family,
          item_category2: category,
          item_category3: category3,
          item_category4: category4,
          item_variant: variantId,
          variant_packaging: packagingLabel,
          price: unitPrice,
          quantity,
        },
      ],
    },
  }
  return payload
}

function buildLineItemFromOption(options: Omit<AddToCartOptions, 'shoppingListContext'>) {
  const offerDiscountMatching = options.variant.offers.find((offer) => offer.offerId === options.offerId)
  const discountId = offerDiscountMatching?.discountId || undefined

  const lineItemToAdd = {
    quantity: options.quantity,
    offer_id: options.offerId,
    variant: options.variant,
    product: { _id: options.variant.productId },
    discount_id: discountId,
  }

  if (options.preShipping) {
    // @ts-ignore: MERCI DE DIRE POURQUOI CE TS IGNORE EST LÀ
    lineItemToAdd.pre_shipping = {
      store_code: options.preShipping.storeCode,
      offer_id: options.preShipping.offerId,
      kind: options.preShipping.kind,
    }
  }

  if (options.paymentDueDateReference) {
    // @ts-ignore: MERCI DE DIRE POURQUOI CE TS IGNORE EST LÀ
    lineItemToAdd.payment_due_date_reference = options.paymentDueDateReference
  }

  return lineItemToAdd
}

function buildLine(params) {
  return [
    {
      offer: {
        id: params.offerId,
      },
      quantity: params.quantity,
      payment_due_date:
        params.paymentDueDateReference !== undefined
          ? {
              reference: params.paymentDueDateReference,
            }
          : undefined,
      click_and_collect:
        params.preShipping?.kind === 'click_and_collect'
          ? {
              store_code: params.preShipping.storeCode,
            }
          : undefined,
    },
  ]
}

// eslint-disable-next-line import/prefer-default-export
export function useCart(externalStore?: any) {
  const store = externalStore || useStore()
  const logger = useLogger()
  const { isFrontAgri } = useEnv(externalStore)

  let list
  if (!isFrontAgri) {
    list = computed(() => store.getters['shoppingList/shoppingListToList'])
  }

  const isCartLoading = computed(() => {
    if (isFrontAgri) {
      return store.getters['cart/isCartLoading']
    }
    return false
  })
  const isCartSaving = ref(false)
  const cart = computed(() => store.getters['cart/cart'])
  // Dev note: dispatch logic in store
  const prepareAddToCart = (options: AddToCartOptions) => async () => {
    const { shoppingListContext, ...rest } = options
    const lineItemToAdd = buildLineItemFromOption(rest)
    isCartSaving.value = true
    try {
      if (isFrontAgri) {
        await store.dispatch('cart/addToCart', lineItemToAdd)
      } else {
        const lines = buildLine(options)
        if (store.getters['shoppingList/isListInitialized'] && store.getters['shoppingList/id'] !== '') {
          await store.dispatch('shoppingList/addLineToShoppingList', lines[0])
        } else {
          await store.dispatch('shoppingList/createShoppingListWithLine', {
            lines,
            shoppingListContext,
          })
        }
      }
    } finally {
      isCartSaving.value = false
    }
    isCartSaving.value = false
    // eslint-disable-next-line no-underscore-dangle
    const product = store.getters['shop/getProductById'](lineItemToAdd.product._id)
    try {
      useAnalyticService().resetEcommerce()
      const data = getAddToCartTrackingData(lineItemToAdd, product, options)
      useAnalyticService().trackEvent('add_to_cart', addToCartPayload(data))
      let quantityToSub = lineItemToAdd.quantity || 0
      if (!lineItemToAdd.variant.isSoldAsUnit) quantityToSub *= lineItemToAdd.variant.measure_quantity
      store.commit('shop/SUB_OFFER_QUANTITY_IN_DISCOUNT_LIST', {
        offerId: lineItemToAdd.offer_id,
        discountId: lineItemToAdd.discount_id,
        quantity: quantityToSub,
      })
    } catch (error: any) {
      logger.error('Error on Tracking data:', error.message)
    }
  }

  return {
    prepareAddToCart,
    isCartLoading,
    isCartSaving,
    cart,
    list,
    cartOrList: list || cart,
  }
}
