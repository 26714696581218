import Vue from 'vue'
import { mapToProductHit, ProductHit } from '@b2ag/search'
import { getServicesForStore } from '.'

const MAX_LAST_VISITED_STORED = 18
const expirationAsDay = 1
const oneDayInMilliseconds = 24 * 60 * 60 * 1000

const getDefaultState = () => {
  let lastVisitedProducts = JSON.parse(localStorage.getItem('lastVisitedProducts') || '[]') as ProductHit[]
  if (typeof lastVisitedProducts[0] === 'string') {
    localStorage.removeItem('lastVisitedProducts')
    lastVisitedProducts = []
  }
  return {
    abTestingProperties: {
      group: localStorage.getItem('group') || 'A',
      groupExpirationTimestamp:
        localStorage.getItem('groupExpirationTimestamp') ||
        (Date.now() + expirationAsDay * oneDayInMilliseconds).toString(10),
    },
    areRecommendedProductsLoaded: false,
    lastVisitedProducts,
    recommendedProductsIds: [],
    modelVersionRecommendations: null,
    modelVersionComplementaryProducts: null,
    complementaryProducts: {},
  }
}

const recommendationStore = {
  state: getDefaultState(),
  mutations: {
    ADD_COMPLEMENTARY_PRODUCTS(state, { productId, complementaryProductsId }) {
      Vue.set(state.complementaryProducts, productId, complementaryProductsId)
    },
    ASSIGN_AB_TESTING_GROUP(state) {
      const groupExpirationTimestamp = Date.now() + expirationAsDay * oneDayInMilliseconds
      const groupExpirationTimestampAsString = groupExpirationTimestamp.toString(10)
      Vue.set(state.abTestingProperties, 'groupExpirationTimestamp', groupExpirationTimestampAsString)
      localStorage.setItem('groupExpirationTimestamp', groupExpirationTimestampAsString)

      const availableLetters = ['A', 'B']
      const group = availableLetters[Math.floor(Math.random() * availableLetters.length)]
      const groupAsString = group.toString()
      Vue.set(state.abTestingProperties, 'group', groupAsString)
      localStorage.setItem('group', groupAsString)
    },
    RESET_VISITED_PRODUCTS(state) {
      state.lastVisitedProducts = []
      localStorage.removeItem('lastVisitedProducts')
    },
    SET_ARE_RECOMMENDED_PRODUCTS_LOADED(state, isLoaded) {
      state.areRecommendedProductsLoaded = isLoaded
    },
    SET_RECOMMENDED_PRODUCTS_IDS(state, recommendedProductsIds) {
      state.recommendedProductsIds = recommendedProductsIds || []
    },
    SET_MODEL_VERSION_RECOMMENDATION(state, modelVersionRecommendations) {
      state.modelVersionRecommendations = modelVersionRecommendations ? modelVersionRecommendations.toString() : null
    },
    SET_MODEL_VERSION_COMPLEMENTARY_PRODUCTS(state, modelVersionComplementaryProducts) {
      state.modelVersionComplementaryProducts = modelVersionComplementaryProducts.toString()
    },
    SET_VISITED_PRODUCTS(state, visitedProducts) {
      state.lastVisitedProducts = visitedProducts.map(mapToProductHit)
      localStorage.setItem('lastVisitedProducts', JSON.stringify(state.lastVisitedProducts))
    },
    RESET_STATE(state) {
      localStorage.clear()
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    addVisitedProduct(context, product) {
      if (product._id.toString().startsWith('COOP')) return
      const localStorageVisitedProducts = context.getters.lastVisitedProducts
      const productIndex = localStorageVisitedProducts.findIndex((p) => p._id === product._id)
      if (productIndex > -1) {
        localStorageVisitedProducts.splice(productIndex, 1)
      }
      localStorageVisitedProducts.unshift(product)
      context.commit('SET_VISITED_PRODUCTS', localStorageVisitedProducts.slice(0, MAX_LAST_VISITED_STORED))
    },
    async initABTestingProperties(context) {
      const { areABTestingPropertiesValid } = context.getters
      if (!areABTestingPropertiesValid) {
        context.commit('ASSIGN_AB_TESTING_GROUP')
      }
    },
    async fetchRecommendedProducts(context) {
      const recommendedProductsIds = context.getters.recommendedProducts
      if (recommendedProductsIds.length === 0) {
        await context.dispatch('initABTestingProperties')
        const group = context.getters.getABTestingGroup
        const { recommendationService } = getServicesForStore()
        if (!recommendationService) throw new Error('Recommendation service is not available')
        const result = await recommendationService.getRecommendations(group)
        context.commit('SET_RECOMMENDED_PRODUCTS_IDS', result.top_products_ids)
        context.commit('SET_MODEL_VERSION_RECOMMENDATION', result.model_version)
        context.commit('SET_ARE_RECOMMENDED_PRODUCTS_LOADED', true)
      }
    },
    async fetchRecommendedProductsByProduct(context, productId) {
      const complementaryProductsFromStore = context.getters.getComplementaryProducts(productId)
      if (!complementaryProductsFromStore) {
        await context.dispatch('initABTestingProperties')
        const group = context.getters.getABTestingGroup
        const { recommendationService } = getServicesForStore()
        if (!recommendationService) throw new Error('Recommendation service is not available')
        const result = await recommendationService.findRecommendations(productId, group)
        const complementaryProductsId = result.complementary_products_id
        context.commit('ADD_COMPLEMENTARY_PRODUCTS', { productId, complementaryProductsId })
        context.commit('SET_MODEL_VERSION_COMPLEMENTARY_PRODUCTS', result.model_version)
      }
    },
    resetRecommendationsState(context) {
      context.commit('RESET_STATE')
    },
  },
  getters: {
    areABTestingPropertiesValid: (state) =>
      !!(state.abTestingProperties.group && Date.now() < state.abTestingProperties.groupExpirationTimestamp),
    areRecommendedProductsLoaded: (state) => state.areRecommendedProductsLoaded,
    getABTestingGroup: (state) => state.abTestingProperties.group,
    getABTestingGroupExpirationTimestamp: (state) => state.abTestingProperties.groupExpirationTimestamp,
    getComplementaryProducts: (state) => (productId) => state.complementaryProducts[productId],
    lastVisitedProducts: (state) => state.lastVisitedProducts,
    recommendedProductsIds: (state) => state.recommendedProductsIds,
    getABTestDescription: (state) => `AB-reco-V${state.abTestingProperties.group}`,
    recommendedProducts: (state, _, __, rootGetters) => {
      const productsIds = state.recommendedProductsIds
      return rootGetters['shop/getAlgoliaProductsByIds'](productsIds)
    },
    modelVersionRecommendations: (state) => state.modelVersionRecommendations,
    modelVersionComplementaryProducts: (state) => state.modelVersionComplementaryProducts,
  },
}

export default recommendationStore
