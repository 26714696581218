import { RootGetters } from '@b2ag/store'
import { ShopState } from '@b2ag/store/src/shop.store'

export function getMissingProductsIds(
  productIdList: string[],
  alreadyLoadedProductList: { _id: string; isCooperativeProduct?: boolean }[],
  productsForPrice: Set<string>,
) {
  const alreadyLoadedProductHashMap = new Map(alreadyLoadedProductList.map((product) => [product._id, product]))
  return productIdList.filter(
    (productId) =>
      !alreadyLoadedProductHashMap.has(productId) ||
      !alreadyLoadedProductHashMap.get(productId)!.isCooperativeProduct ||
      productsForPrice.has(productId),
  )
}

export async function getCartOfferIds(
  rootGetters: Record<RootGetters, any>,
  state: Pick<ShopState, 'cartOfferIdsPromise' | 'resolveCartPromise'>,
) {
  if (rootGetters['cart/isCartInitialized']) return rootGetters['cart/initialOfferIds']
  state.cartOfferIdsPromise = new Promise((resolve) => {
    state.resolveCartPromise = resolve
  })
  const cartOfferIds = await state.cartOfferIdsPromise
  state.cartOfferIdsPromise = undefined
  return cartOfferIds
}
