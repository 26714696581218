import { articleUseCase } from '@b2ag/articles'
import type { ArticleThumbnails } from '@b2ag/articles/src/domain/ArticleThumbnails'
import type { Home } from '../Home'
import type { IHomeRepository } from '../repository/IHomeRepository'

export class HomeUseCase {
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(private iHomeRepository: IHomeRepository) {}

  async getHome(): Promise<Home> {
    const home = await this.iHomeRepository.getHome()
    const articlesFilteredByPartner = await articleUseCase.getArticles(home.pushArticles!)
    const homePushArticle = { ...home.pushArticles, articles: articlesFilteredByPartner } as ArticleThumbnails
    return { ...home, pushArticles: homePushArticle }
  }

  async getPartnerHome(partnerId: string, frontContext: string): Promise<Home | null> {
    const frontEnd = frontContext.toLowerCase() === 'agri' ? 'Agri' : 'Tech'
    const home = await this.iHomeRepository.getPartnerHome(partnerId, frontEnd)
    if (!home) {
      return null
    }
    const partnerHomeBanner = this.iHomeRepository.getPartnerHomeBanner(partnerId) || Promise.resolve(null)
    return Promise.all([
      articleUseCase.getArticles(home.pushArticles as ArticleThumbnails, true, +partnerId),
      partnerHomeBanner,
    ]).then(async ([filteredArticles, homeBanner]) => {
      if (homeBanner) {
        // eslint-disable-next-line no-param-reassign
        home.partnerHomeBanner = homeBanner
      }
      if (home.pushArticles?.articles) {
        const homePushArticle = {
          ...home.pushArticles,
          articles: filteredArticles,
        } as ArticleThumbnails
        return { ...home, pushArticles: homePushArticle }
      }
      return home
    })
  }
}
