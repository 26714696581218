import ContentfulClient from '@b2ag/contentful'
import type { EntryCollection } from '@b2ag/contentful'

import { UniversePageModelTypes, mapGeneralInformationsFromApi, mapBlocFromApi } from './universePage.mapper'
import type { UniversePageGeneralInformation } from './universePage.mapper'

const NUMBER_OF_LEVELS = 4

export interface UniverseServiceModel {
  name: string
  informations: UniversePageGeneralInformation | null
  blocs: Array<any>
}

class UniversePageService {
  contentfulClient: ContentfulClient

  constructor({ contentfulClient }: { contentfulClient: ContentfulClient }) {
    this.contentfulClient = contentfulClient
  }

  async getEntries(slug: string): Promise<UniverseServiceModel> {
    const emptyResult = { name: '', informations: null, blocs: [] }
    if (!slug) return emptyResult
    try {
      const entries: UniverseServiceModel = { ...emptyResult }
      const rawEntries: EntryCollection<any> = await this.contentfulClient.api.getEntries({
        include: NUMBER_OF_LEVELS,
        content_type: UniversePageModelTypes.page,
        'fields.informations.sys.contentType.sys.id': UniversePageModelTypes.generalInformation,
        'fields.informations.fields.slug': slug,
      })
      const flattenEntries = await this.contentfulClient.api.parseEntries(rawEntries)
      if (flattenEntries.items[0]?.fields) {
        const { name, informations, blocs } = flattenEntries.items[0].fields as UniverseServiceModel
        entries.name = name
        entries.informations = mapGeneralInformationsFromApi(informations)
        entries.blocs = blocs ? blocs.map((bloc) => mapBlocFromApi(bloc)).filter((bloc) => !!bloc) : []
      }
      return entries
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('failed to get universe page datas for :', slug)
      return emptyResult
    }
  }
}

export default UniversePageService
