import { computed } from 'vue'
import { useStore } from '@b2ag/store'

export function useRecommendation(productId) {
  const store = useStore()

  const complementaryProductsId = computed(() => store.getters.getComplementaryProducts(productId))
  const productRecommendations = computed(() =>
    complementaryProductsId.value ? store.getters['shop/getAlgoliaProductsByIds'](complementaryProductsId.value) : [],
  )
  const getProductRecommendations = async () => {
    await store.dispatch('fetchRecommendedProductsByProduct', productId)
    await store.dispatch('shop/fetchMissingProducts', complementaryProductsId.value || [])
  }

  return {
    productRecommendations,
    getProductRecommendations,
  }
}
