import { ref, onMounted, onUnmounted } from 'vue'
import debounce from 'lodash/debounce'
import { useAnalyticService } from '@b2ag/plugin-aladin-gtm'

export function useScrollAnalytics(scrollEventName?) {
  const SCROLLTRACKING_EVENT_NAME = scrollEventName || 'APP_Scroll'
  let debouncedScrollTrackingListener: () => void

  const scrollTracking = ref({ 10: false, 25: false, 50: false, 75: false, 100: false })

  function findTrackingThreshold(value) {
    let currentValue = 0
    Object.keys(scrollTracking.value).forEach((key) => {
      if (key <= value && currentValue <= value) {
        currentValue = +key
      }
    })

    return currentValue
  }

  function onTrackingScroll() {
    const scrollPercentage = Math.ceil(
      (document.documentElement.scrollTop /
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100,
    )
    const threshHold = findTrackingThreshold(scrollPercentage)

    // Check if we have sent this event
    if (!scrollTracking.value[threshHold]) {
      scrollTracking.value[threshHold] = true
      useAnalyticService().trackEvent(SCROLLTRACKING_EVENT_NAME, {
        position: threshHold,
      })
    }
  }

  onMounted(() => {
    debouncedScrollTrackingListener = debounce(onTrackingScroll, 200)
    window.addEventListener('scroll', debouncedScrollTrackingListener)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', debouncedScrollTrackingListener)
  })

  return {
    scrollTracking,
    findTrackingThreshold,
    onTrackingScroll,
    SCROLLTRACKING_EVENT_NAME,
  }
}
