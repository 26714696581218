import { appBrands, type AppBrand } from '@b2ag/app-brands'

import aladin from './aladin'
import farmi from './farmi'
import episens from './episens'

export function getAppTypeLocale(context?: AppBrand) {
  switch (context) {
    case appBrands.Episens:
      return episens
    case appBrands.Farmi:
      return farmi
    case appBrands.Aladin:
      return aladin
    default:
      return aladin
  }
}
