import { useStore } from '@b2ag/store'

const COMPONENT_NAME_TO_EEC_LIST_NAME = {
  'substitution-products-gallery': 'Produits de substitution',
  'visited-products-gallery': 'Vos derniers produits consultes',
  'top-product-views-gallery': 'Les produits les plus consultes',
  'cross-sell-products-gallery': 'Cross-sell - reco produit à la mise en panier',
  'associated-products-gallery': 'Cross-sell - produits associés depuis la Page Produit',
}

export function stepChangeStepperPayload(actionField) {
  const data = {
    ecommerce: {
      checkout: {
        actionField,
      },
    },
  }
  return data
}

// eslint-disable-next-line import/prefer-default-export
export function useAnalyticsPayloadConstructor() {
  const store = useStore()
  const productThumbnailClick = (eventPayload) => {
    const { product, index, galleryId } = eventPayload
    const category =
      product.categories && product.categories[0] && product.categories[0][1] && product.categories[0][1].label
    const listName = COMPONENT_NAME_TO_EEC_LIST_NAME[galleryId] || galleryId

    let modelVersion
    if (galleryId === 'top-product-views-gallery')
      modelVersion = store.getters['recommandations/modelVersionRecommendations']
    if (galleryId === 'substitution-products-gallery')
      modelVersion = store.getters['recommandations/modelVersionComplementaryProducts']

    const productClickData = {
      ecommerce: {
        click: {
          actionField: {
            list: modelVersion ? `${listName} - ${modelVersion}` : listName,
          },
          products: [
            {
              // eslint-disable-next-line no-underscore-dangle
              id: product._id,
              name: product.name,
              category: `${product.family}|${category}`,
              variant: product.variants[0].name,
              brand: product.supplier,
              position: index,
              // @ts-ignore: aucune idée d'où vient ce champ
              price: product.priceFrom ? product.priceFrom : null,
            },
          ],
        },
      },
    }
    return productClickData
  }
  return {
    productThumbnailClick,
  }
}
