import { type InjectionKey, inject, provide } from 'vue'

type SendProductInfoRequest = (payload: any) => Promise<{ status: boolean; message?: string }>

export const sendProductInfoRequestSymbol: InjectionKey<SendProductInfoRequest> = Symbol('sendProductInfoRequest')

export function provideSendProductInfoRequest(sendFunction: SendProductInfoRequest) {
  provide(sendProductInfoRequestSymbol, sendFunction)
}

export function useSendProductInfoRequest() {
  const sendFunction = inject(sendProductInfoRequestSymbol)
  if (!sendFunction) {
    return () => Promise.resolve()
  }
  return sendFunction
}
