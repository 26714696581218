import type { AxiosInstance } from '@b2ag/axios'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'

class LineItemService {
  api: AxiosInstance

  constructor({ cartApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${cartApiHost}/v1/bff/appro`, { callerId: buildCallerId() })
  }

  async update(orderId, { id, quantity }) {
    // eslint-disable-next-line prefer-promise-reject-errors
    if (!id) return Promise.reject('lineId is mandatory')

    const response = await this.api.put(`/carts/${orderId}/line-items/${id}`, { id, quantity })
    return response.data
  }
}

export default LineItemService
