import ContentfulClient from '@b2ag/contentful'
import type { SupplierHighlight } from '../../domain/blocs/SupplierHighlight'
import type { SupplierHighlightFieldsDto } from '../../domain/blocs/SupplierHighlightDto'
import type { ISupplierHighlightRepository } from '../../domain/repository/ISupplierHighlightRepository'
import { supplierHighlightMapper } from '../mapper/supplierHighlight.mapper'

export class SupplierHighlightRepository implements ISupplierHighlightRepository {
  // eslint-disable-next-line no-useless-constructor
  constructor(public readonly client: ContentfulClient) {}

  async getSupplierHighlightByCategoryAndPartner(
    category: string,
    partnerId = 'national',
  ): Promise<SupplierHighlight | null> {
    try {
      const content = await this.client.api.getEntries<SupplierHighlightFieldsDto>({
        content_type: 'supplierHighlight',
        'fields.categoryCode': category,
        'fields.partnerFilter': partnerId,
        limit: 1,
        include: 1,
      })

      return supplierHighlightMapper(content.items[0])
    } catch (error: any) {
      return null
    }
  }
}
