export enum Packaging {
  BIG_BAG = 'bigbag',
  SAC = 'sac',
  CAMION_BIG_BAG = 'camion bigbag',
  CUVE = 'cuve',
  FUT = 'fut',
  CAMION_VRAC = 'camion vrac',
  VRAC = 'vrac',
  BIDON = 'bidon',
  BOITE = 'boite',
  CARTON = 'carton',
}
