import { computeStockQuantityByMeasure } from '@b2ag/stock'
import type { Offer } from '@b2ag/offers/src/offer'
import { cartService, lineItemService } from '../services'

function offerManagedByStockApi(offer) {
  return offer?.stockManagementType === 'managed' && !offer.allowedWithoutStock
}

// TODO (MAGR: 11/12/20) Faire test unitaire et Refacto !
async function getStockByVariant(offers, context) {
  const filteredOffers = offers.filter(offerManagedByStockApi)
  const variantMap = filteredOffers
    .map((offer) => ({ id: offer.offerId.split(':')[1], offers: [offer] }))
    .reduce((acc, variant) => {
      // eslint-disable-next-line no-param-reassign
      acc[variant.id] = acc[variant.id]
        ? { ...acc[variant.id], offers: acc[variant.id].offers.concat(variant.offers) }
        : variant
      return acc
    }, {})
  await context.dispatch('stock/fetch', { variants: Object.values(variantMap) }, { root: true })

  const stocks = context.rootGetters['stock/stocks']

  return new Map(
    Object.values(stocks)
      .filter((stock) => !!stock)
      // @ts-ignore: MERCI DE DIRE POURQUOI CE TS IGNORE EST LÀ
      .map((stock) => [stock?.variant_id, stock]),
  )
}

function computeQuantity({ lineItem, quantity = 0 }) {
  return !lineItem.is_sold_as_unit && lineItem.measure_quantity && quantity
    ? computeStockQuantityByMeasure(lineItem.measure_quantity, quantity)
    : quantity
}

function mergeOffersIntoLineItems(offers: Offer[] = [], lineItems, stockByVariant = new Map(), preShippings = []) {
  return lineItems.map((lineItem) => {
    let computedQuantity
    const lineItemOffer = offers.find((innerOffer) => innerOffer.offerId === lineItem.offer_id)

    if (offerManagedByStockApi(lineItemOffer)) {
      const stock = stockByVariant.get(lineItem.variant_invivo_id)
      computedQuantity = computeQuantity({ lineItem, quantity: stock?.quantity })

      if (lineItem.pre_shipping_id) {
        const preShipping = preShippings.find(({ id }) => id === lineItem.pre_shipping_id)
        // @ts-ignore: MERCI DE DIRE POURQUOI CE TS IGNORE EST LÀ => PARCE QUE PRESHIPPING EST MAL TYPE DANS L'API
        const storeQuantity = stock.stores.find(({ code }) => code === preShipping?.store_code)?.quantity

        computedQuantity = computeQuantity({ lineItem, quantity: storeQuantity })
      }
    }

    return {
      ...lineItem,
      quantityMin: lineItemOffer?.quantityMin || 1,
      quantityMax: lineItemOffer?.quantityMax,
      quantityStep: lineItemOffer?.quantityStep || 1,
      productIsRestrictedExplosivesPrecursor: lineItemOffer?.productIsRestrictedExplosivesPrecursor || false,
      quotaRemaining: lineItemOffer?.quota?.remaining,
      stockQuantity: computedQuantity,
      stockManagementType: lineItemOffer?.stockManagementType,
      logisticOffers: lineItemOffer?.logisticOffers,
    }
  })
}
export const defaultCart = { line_items: [] as any[] }

const defaultState = {
  cart: defaultCart,
  isCartPageReady: false,
  isCartLoading: false,
  isCartInitialized: false,
  initialOfferIds: [],
}

export const mutations = {
  // TODO(JOTA,SIBE): Sortir le cartService.last et l'appeler dans le resetCart
  // l'enlever du fetchUser
  RESET_CART(state) {
    state.cart = defaultCart
  },
  SET_CART(state, cart) {
    state.cart = cart ? { ...cart } : { ...defaultCart }
    state.isCartLoading = false
    state.isCartPageReady = true
  },
  SET_CART_IS_LOADING(state, isLoading) {
    state.isCartPageReady = !isLoading
    state.isCartLoading = isLoading
  },
  SET_CART_PAGE_READY(state, cartPageReady) {
    state.isCartPageReady = cartPageReady
  },
  RESET_STATE(state) {
    Object.assign(state, defaultState)
  },
  SET_INITIAL_OFFER_IDS(state, offerIds) {
    if (!state.isCartInitialized) state.initialOfferIds = offerIds
    state.isCartInitialized = true
  },
}

export const actions = {
  async addToCart(context, options) {
    context.commit('SET_CART_IS_LOADING', true)
    const { cartId } = context.getters
    const { product, quantity, variant, offer_id, discount_id, pre_shipping, payment_due_date_reference } = options
    if (cartId) {
      await cartService.addProductToCart(
        cartId,
        product._id,
        quantity,
        variant,
        offer_id,
        discount_id,
        pre_shipping,
        payment_due_date_reference,
      )
    } else {
      const cart = await cartService.createCart({
        membership_number: context.rootGetters.currentMembershipNumber,
        cooperative_id: context.rootGetters.currentCooperativeId,
        product_id: product._id,
        quantity,
        variant,
        offer_id,
        discount_id,
        pre_shippings: pre_shipping ? [pre_shipping] : [],
        payment_due_date_reference: payment_due_date_reference || undefined,
      })
      context.commit('SET_CART', cart)
    }

    context.dispatch('retrieveCart')
  },
  async retrieveCart({ dispatch, getters, rootGetters }) {
    const { cartId } = getters
    if (cartId) {
      const cart = await cartService.get(cartId)
      await dispatch('setCart', cart)
    } else {
      const { currentMembershipNumber, currentCooperativeId } = rootGetters
      if (currentMembershipNumber) {
        const cart = await cartService.last(currentMembershipNumber, currentCooperativeId)
        await dispatch('setCart', cart)
      }
    }
  },
  async setCart(context, cart = defaultCart) {
    const { line_items: lineItems = [] } = cart || defaultCart
    let lineItemsWithOfferInfos = []
    const offerIds = lineItems.map(({ offer_id }) => offer_id)
    context.commit('SET_INITIAL_OFFER_IDS', offerIds)
    // sert à notifier le ShopStore que le panier est chargé, à appeler même avec une liste vide
    await context.dispatch('shop/fetchMissingOffers', offerIds, { root: true })
    if (lineItems.length > 0) {
      const offers = context.rootGetters['shop/getOffersById'](offerIds)

      const stocksByVariant = await getStockByVariant(offers, context)
      // @ts-ignore: MERCI DE DIRE POURQUOI CE TS IGNORE EST LÀ
      lineItemsWithOfferInfos = mergeOffersIntoLineItems(offers, lineItems, stocksByVariant, cart.pre_shippings) || []
    }
    context.commit('SET_CART', {
      ...cart,
      line_items: lineItemsWithOfferInfos,
    })
  },
  resetCart(context) {
    context.commit('RESET_CART')
  },
  deleteCart(context) {
    return cartService.deleteCart(context.getters.cartId).finally(() => {
      context.commit('RESET_CART')
    })
  },
  // XJU : à supprimer pour le passer dans le micro-front cart
  async updateLineItem(context, lineItem) {
    const { cartId } = context.getters
    await lineItemService.update(cartId, lineItem)
    context.dispatch('retrieveCart')
  },
}

export const getters = {
  isCartLoading(state) {
    return state.isCartLoading
  },
  isCartPageReady(state) {
    return state.isCartPageReady
  },
  // devient `true` après le chargement initial du cart pour un adhérent ;
  // ne repasse pas à `false` pendant les mises à jour du panier (sauf changement d'adhérent)
  isCartInitialized(state) {
    return state.isCartInitialized
  },
  initialOfferIds(state) {
    return state.initialOfferIds
  },
  cart(state) {
    return state.cart || defaultCart
  },
  cartId(state) {
    return state.cart && state.cart.id
  },
  hasCart(state) {
    return (state.cart && state.cart.line_items && state.cart.line_items.length > 0) || false
  },
  lineItemCount(state) {
    return state.cart && state.cart.line_items && state.cart.line_items.length
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: defaultState,
}
