import { frenchPlural, invariable, pluralize } from '../pluralize/pluralize'

// https://invivo-digital-factory.atlassian.net/browse/NOOK-290?atlOrigin=eyJpIjoiZTcwNmY0MDViMzMxNGUyOGFjOWMxMmVlZDRjZGNkMmYiLCJwIjoiaiJ9

export const unitLabel = {
  l: invariable('L'),
  ml: invariable('mL'),
  g: invariable('g'),
  kg: invariable('kg'),
  q: invariable('q'),
  m3: invariable('m³'),
  '1u': frenchPlural('unité'),
  unit: frenchPlural('unité'),
  t: invariable('t'),
  tn: invariable('t'), // "tonne" dans le Pricer Soufflet
  mg: invariable('MG'),
  dose: frenchPlural('dose'),
  bigdose: frenchPlural('Bigdose'),
  sac: frenchPlural('sac'),
  bigbag: frenchPlural('Bigbag'),
  // shouldn't be here but *someone* decided to be lazy and use measureUnit for something else
  any: invariable('Toutes les unités'),
}

export function measureUnit(unit = '', n = 1) {
  return pluralize(unitLabel[unit.toLowerCase()] || unit, n)
}
