import { getStore } from '@/store/store'
import { emitMessageToChild, messagesForParent, messagesForChild } from '@b2ag/micro-front-tools'
import type { DataForParent } from '@b2ag/micro-front-tools'

async function callbackRetrieveCart() {
  const store = getStore()

  await store.dispatch('cart/retrieveCart')

  emitMessageToChild('cart', { message: messagesForChild.CART_RETRIEVED })
}

async function callbackReloadProducts() {
  const store = getStore()

  await store.dispatch('shop/reloadProducts')

  callbackRetrieveCart()
}

export function callbacksFromCartMicroFrontIframe(data: DataForParent) {
  switch (data.message) {
    case messagesForParent.RETRIEVE_CART:
      callbackRetrieveCart()
      break
    case messagesForParent.RELOAD_PRODUCTS:
      callbackReloadProducts()
      break
    default:
      break
  }
}
