import { inject, provide, ref, type Ref } from 'vue'

export type CartChangedParams = Ref<string | null>

export type CartReactions = {
  addToCart: CartChangedParams
  updateAddToCart: (params: string | null) => void
}

const addToCart: CartChangedParams = ref(null)

function updateAddToCart(params: string | null): void {
  addToCart.value = params
}

/**
 *
 * Internal provide/inject with defaults
 */

/**
 * CartReactions internal provide/inject key.
 * @internal
 * @remarks Should only be used externally in tests to mock the provided reactions.
 * @prefer {@link useCartReactions().provide()} or {@link useCartReactions().inject()}
 */
export const CartReactionsKey = Symbol('CartReactions')
const noop = async () => {}

function provideCartReactions(): void {
  provide(CartReactionsKey, {
    addToCart,
    updateAddToCart,
  })
}

function injectCartReactions(): CartReactions {
  return (
    inject(CartReactionsKey) ?? {
      addToCart: ref(null),
      updateAddToCart: noop,
    }
  )
}

/**
 * Public `use`
 */
export type CartReactionsHandle = {
  provide: () => void
  inject: () => CartReactions
}

export function useCartReactions(): CartReactionsHandle {
  return {
    provide: provideCartReactions,
    inject: injectCartReactions,
  }
}
