import ContentfulClient from '@b2ag/contentful'
import type { Home } from '../../domain/Home'
import type { IHomeRepository } from '../../domain/repository/IHomeRepository'
import { contentfulToHome } from '../mapper/home.mapper'
import { contentfulHomeBlocsToHome } from '../mapper/homeBlocs.mapper'

import type { PartnerHomeBanner } from '../../domain/blocs/PartnerHomeBanner'
import { mapPartnerHomeBannerFromApi } from '../mapper/partnerHomeBanner.mapper'

export class HomeRepository implements IHomeRepository {
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(public readonly client: ContentfulClient) {}

  async getHome(): Promise<Home> {
    try {
      const content = await this.client.api.getEntries({
        content_type: 'home',
        limit: 1,
        include: 4,
      })
      if (content.total === 0) {
        return {
          mainHeader: [],
          articles: [],
          categories: [],
          banners: [],
        }
      }
      return contentfulToHome(content.items[0])
    } catch (error: any) {
      return {
        mainHeader: [],
        articles: [],
        categories: [],
      }
    }
  }

  getPartnerHome(partnerId: string, frontContext: string): Promise<Home | null> {
    return this.client.api
      .getEntries({
        content_type: 'homeCooperative',
        'fields.cooperativeId': partnerId,
        'fields.frontEnd': frontContext,
        limit: 1,
        include: 4,
      })
      .then(
        (content) => {
          if (content.total === 0) {
            return null
          }
          return contentfulHomeBlocsToHome(content.items[0])
        },
        () => null,
      )
  }

  async getPartnerHomeBanner(partnerId: string): Promise<PartnerHomeBanner> {
    return this.client.api
      .getEntries({
        content_type: 'partnerMainBanner',
        'fields.partnerId': partnerId,
        limit: 1,
        include: 4,
      })
      .then(
        (content) => {
          const dto = content.total === 0 ? null : content.items[0]
          return mapPartnerHomeBannerFromApi(partnerId, dto)
        },
        () => mapPartnerHomeBannerFromApi(partnerId),
      )
  }
}
