import { contentfulAssetUrlToUrl } from '@b2ag/articles/src/infra/mapper/url.mapper'
import type { Banner } from '../../domain/blocs/Banner'
import { BlocType } from '../../domain/BlocType'

export const mapBannerFromApi = (banner): Banner | undefined => {
  if (!banner?.fields) return undefined
  const bannerObj: Banner = {
    contentName: '',
    desktopImage: '',
    mobileImage: '',
    description: '',
    link: '',
    isSmall: false,
    supplierName: '',
    partnerFilter: [],
  }
  bannerObj.blocType = BlocType.BANNER
  bannerObj.contentName = banner.fields.contentName || ''
  bannerObj.desktopImage = contentfulAssetUrlToUrl(banner.fields.desktopImage?.fields?.file?.url) || ''
  bannerObj.mobileImage = contentfulAssetUrlToUrl(banner.fields.mobileImage?.fields?.file?.url) || ''
  bannerObj.description = banner.fields.description || ''
  bannerObj.isSmall = banner.fields.isSmall || false
  bannerObj.link = banner.fields.link || ''
  bannerObj.supplierName = banner.fields.supplierName
  bannerObj.partnerFilter = banner.fields.partnerFilter
  return bannerObj
}
