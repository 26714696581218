import Discount, { type DiscountProps } from '../Discount'

export interface DiscountScaleSliceProps {
  min: number
  max?: number
  discount: DiscountProps
}

export default class DiscountScaleSlice {
  public readonly min: number

  public readonly max: number | null

  public readonly discount: Discount

  constructor(discountScaleSliceProps: DiscountScaleSliceProps) {
    this.min = discountScaleSliceProps.min
    this.max = discountScaleSliceProps.max || null
    this.discount = new Discount(discountScaleSliceProps.discount)
  }
}
