import type { Offer } from '@b2ag/offers/src/offer'
import { isPreorderPriceType, hasPaymentDueDates } from '@b2ag/offers/src/offer.business'
import { computed, type ComputedRef } from 'vue'

interface IUseOffer {
  isOnlyTCOffer: ComputedRef<boolean>
  isPreorderPriceType: ComputedRef<boolean>
  hasPaymentDueDates: ComputedRef<boolean>
}

export function useOffer(offer: Offer): IUseOffer {
  return {
    isOnlyTCOffer: computed(() => !!offer.isTechExclusive),
    isPreorderPriceType: computed(() => isPreorderPriceType(offer)),
    hasPaymentDueDates: computed(() => hasPaymentDueDates(offer)),
  }
}
