import { contentfulAssetUrlToUrl } from '@b2ag/articles/src/infra/mapper/url.mapper'
import type { HomeMainHeader } from '../../domain/blocs/HomeMainHeader'
import { BlocType } from '../../domain/BlocType'

export const mapMainHeaderFromApi = (mainHeaderField: any): HomeMainHeader[] => {
  if (!mainHeaderField) return [] as HomeMainHeader[]
  return mainHeaderField.map((mainHeaderItem) => {
    const cards = mainHeaderItem.fields.cards
      ? mainHeaderItem.fields.cards.map((card) => {
          return {
            backgroundImage: contentfulAssetUrlToUrl(card.fields.backgroundImage?.fields?.file?.url),
            url: card.fields.url || '',
            tag: card.fields.tag || '',
            title: card.fields.title || '',
            blockTitle: card.fields.blockTitle,
          }
        })
      : []
    return {
      blocType: BlocType.HOME_MAIN_HEADER,
      backgroundImageDesktop:
        contentfulAssetUrlToUrl(mainHeaderItem.fields.backgroundImageDesktop?.fields?.file?.url) || '',
      backgroundImageMobile:
        contentfulAssetUrlToUrl(mainHeaderItem.fields.backgroundImageMobile?.fields?.file?.url) || '',
      url: mainHeaderItem.fields.url || '',
      cards,
      title: mainHeaderItem.fields.title || '',
    }
  }) as HomeMainHeader[]
}
