import type { Variant as PricingVariantProps } from '@b2ag/pricing/src/domain/pricing.interface'
import { getConfig } from '@b2ag/micro-front-tools'

export function useVariants<T extends PricingVariantProps>(variants?: T[]): { variantsToDisplay: T[] } {
  const restriction = getConfig('PRODUCTS_TO_DISPLAY_RESTRICTION')
  const variantsToDisplay =
    (!restriction
      ? variants
      : variants?.filter(
          (variant) => !variant.restrictedToProfiles || variant.restrictedToProfiles?.includes(restriction),
        )) || []

  return { variantsToDisplay }
}
