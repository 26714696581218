import { computed, type ComputedRef, inject, type InjectionKey, provide, type Ref, ref } from 'vue'
import { discount as discountBusiness } from '@b2ag/discount/src/discount.business'
import type { DiscountApplication } from '@b2ag/pricing'
import {
  getDiscountablesFromCartAndDiscount,
  getDiscountablesFromOfferSelectionListAndDiscount,
} from '@b2ag/discount/src/discountable.mapper'
import { useCart } from '../useCart'
// eslint-disable-next-line import/no-cycle
import { useOfferSelectionList } from './useOfferSelection'

export interface IUseDiscount {
  discountProjection: ComputedRef<number>
  cartAdvancement: ComputedRef<number>
}

export const focusIndexSymbol: InjectionKey<Ref<string | null>> = Symbol('focusIndexSymbol')

export function provideFocusIndex() {
  provide(focusIndexSymbol, ref(null))
}

export const useDiscountFocusedIndex = () => inject(focusIndexSymbol) || ref(null)

export const useDiscount = (
  discount: Ref<Pick<DiscountApplication, 'offerIdList' | 'type'> | null>,
  offerId: string,
): IUseDiscount => {
  const { cartOrList } = useCart()
  const { selections } = useOfferSelectionList()

  const offerSelection = computed(() => selections.value.filter(({ offer }) => offer.offerId === offerId))

  const discountablesFromCart = computed(() =>
    discount.value ? getDiscountablesFromCartAndDiscount(cartOrList.value?.line_items || [], discount.value) : [],
  )
  const discountableFromCurrentProduct = computed(() =>
    discount.value ? getDiscountablesFromOfferSelectionListAndDiscount(offerSelection.value, discount.value) : [],
  )

  const discountProjection = computed(() => {
    if (!discount.value) return 0
    const discountables = [...discountablesFromCart.value, ...discountableFromCurrentProduct.value]
    return discountBusiness.getAdvancement(discountables, discount.value.type)
  })

  const cartAdvancement = computed(() => {
    if (!discount.value) return 0
    return discountBusiness.getAdvancement(discountablesFromCart.value, discount.value.type)
  })

  return { discountProjection, cartAdvancement }
}
