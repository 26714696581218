import type { AxiosInstance } from '@b2ag/axios'

export interface FeatureFlippingParam {
  id: string
  active: boolean
  parameters: {
    customers: string[]
  }
}

class FeatureFlippingService {
  private readonly client: Pick<AxiosInstance, 'post'>

  constructor(
    featureFlippingApiClient: Pick<AxiosInstance, 'post'>,
    private appBrand: string,
  ) {
    this.client = featureFlippingApiClient
  }

  async checkAllFeatures(partnerId?: number): Promise<FeatureFlippingParam[]> {
    // ((?!app-).) : permet de filtrer les features qui sont spécifique aux apps Android et iOS

    const { data } = await this.client.post(
      `/api/features/_checks?pattern=${this.appBrand}:((?!app-).)*&pageSize=100&page=1`,
      {
        id: partnerId?.toString(),
      },
    )
    return data.results
  }
}

export default FeatureFlippingService
