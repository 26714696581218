export enum AuthErrorKeys {
  STATE_INVALID = '`state` does not match.',
  COOPERATIVE_NOT_FOUND_ERROR = 'ssoNotActivatedForThisCooperative',
  MEMBERSHIP_NOT_FOUND_ERROR = 'unknownMembershipNumber',
  ZERO_PROFILE_FOUND_ERROR = 'noUserFoundForThisMembershipNumber',
  TOO_MANY_PROFILE_FOUND_ERROR = 'moreThanOneUserForThisMembershipNumber',
  EXTRANET_AUTHENTICATION_FAILED = 'notAuthenticatedInPartnerExtranet',
  INVALID_SCOPE = 'invalidScope',
  IS_NOT_AUTHENTICATED = 'isNotAuthenticated',
}
