import type { SearchService } from '@b2ag/search'
import type { SupplierHighlight } from '../blocs/SupplierHighlight'
import type { ISupplierHighlightRepository } from '../repository/ISupplierHighlightRepository'
import { isAvailableSupplierName } from '../utils/isAvailableSupplierName'

export interface IGetSupplierHighlightByPartnerUseCase {
  execute(categories: string[], partnerId: string): Promise<SupplierHighlight | null>
}

export class GetSupplierHighlightByPartnerUseCase implements IGetSupplierHighlightByPartnerUseCase {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public repository: ISupplierHighlightRepository,
    public searchService: SearchService,
  ) {}

  async execute(categories: string[], partnerId: string): Promise<SupplierHighlight | null> {
    const [_, category1, category2, ...rest] = categories

    if (rest.length) return null
    const suppliersList = await this.searchService.getSuppliers()

    if (category2) {
      const category2Items = await this.repository
        .getSupplierHighlightByCategoryAndPartner(category2, partnerId)
        .catch(() => null)
      if (category2Items && isAvailableSupplierName(category2Items.supplierName ?? '', suppliersList)) {
        return category2Items
      }
    }
    if (category1) {
      const category1Items = await this.repository
        .getSupplierHighlightByCategoryAndPartner(category1, partnerId)
        .catch(() => null)
      if (category1Items && isAvailableSupplierName(category1Items.supplierName ?? '', suppliersList)) {
        return category1Items
      }
    }

    return null
  }
}
