import { getConfig } from '@b2ag/micro-front-tools'
import type { PartnerHomeBanner } from '../../domain/blocs/PartnerHomeBanner'
import { BlocType } from '../../domain/BlocType'

export const mapPartnerHomeBannerFromApi = (partnerId: string, dto?: any): PartnerHomeBanner => {
  const imageUrl = `https://res.cloudinary.com/${getConfig(
    'CLOUDINARY_BUCKET_NAME',
  )}/image/upload/dpr_auto/home-banners/partner_${partnerId}.jpg?${Math.random().toString(36).substring(7)}`

  return {
    blocType: BlocType.PARTNER_HOME_BANNER,
    title: dto?.fields?.title,
    description: dto?.fields?.description,
    isLogoActive: dto?.fields.partnerLogo,
    imageUrl,
  }
}
