// eslint-disable-next-line import/extensions, import/no-unresolved
import ContentfulClient from '@b2ag/contentful'
import type { HomeAdvertorial } from '../../domain/blocs/HomeAdvertorial'
import type { IAdvertorialRepository } from '../../domain/repository/IAdvertorialRepository'

import { mapAdvertorialFromApi } from '../mapper/homeAdvertorials.mapper'

export class AdvertorialRepository implements IAdvertorialRepository {
  // eslint-disable-next-line no-useless-constructor
  constructor(public readonly client: ContentfulClient) {}

  async getSupplierAdvertorial(): Promise<HomeAdvertorial | null> {
    return this.client.api
      .getEntries({
        content_type: 'advertorial',
        'fields.supplierName[exists]': true,
      })
      .then((content) => {
        if (content.total === 0) return null
        return mapAdvertorialFromApi(content.items[0])
      })
  }

  async getSupplierAdvertorialByTags(tags: string[]): Promise<HomeAdvertorial | null> {
    return this.client.api
      .getEntries({
        content_type: 'advertorial',
        'fields.supplierName[exists]': true,
        'metadata.tags.sys.id[in]': tags.join(','),
      })
      .then((content) => {
        if (content.total === 0) return null
        return mapAdvertorialFromApi(content.items[0])
      })
  }
}
