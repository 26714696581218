import { type ShippingAddressesDto } from '@aladin/universcoop-interfaces'
import { provide } from 'vue'

export function isSouffletPricerOfferRef(offerReference: string) {
  return /^\w{2}_\d{5}~.*/.test(offerReference)
}

export function isSouffletPricerOfferId(offerId: string) {
  return /^.+:.+:\w{2}_\d{5}~.+/.test(offerId)
}

export function getCityCodeFromPricerOfferId(offerId: string) {
  return isSouffletPricerOfferId(offerId) ? offerId.match(/\w{2}_\d{5}/)?.[0] : null
}

export type ShouldRevalidate = () => Promise<boolean>
export type OnRevalidatedConfirm = () => Promise<void>

export type AddressReactivitySettings = {
  cartTypeLabel: string
  shouldRevalidate: ShouldRevalidate
  onRevalidatedConfirm: OnRevalidatedConfirm
}

export const ADDRESS_REACTIVITY_SETTINGS = Symbol('ADDRESS_REACTIVITY_SETTINGS')

export function provideAddressReactivitySettings(settings: AddressReactivitySettings) {
  provide<AddressReactivitySettings>(ADDRESS_REACTIVITY_SETTINGS, settings)
}

export interface ShippingMethod {
  title: string
  short_title: string
  cost: number
  line_item_ids: string[]
  kind: string
  shipping_addresses: ShippingAddressesDto[]
}
