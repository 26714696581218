import { inject, type InjectionKey, provide } from 'vue'
import type { MembershipApiClient } from '@aladin/shared-universcoop-sdk'
import type { SearchService } from '@b2ag/search'
import type { IStockService } from '@b2ag/stock'
import type { IStoreService } from '@b2ag/coop-store'
import type { IDiscountService } from '@b2ag/discount'
import type { IOfferService } from '@b2ag/offers/src/services/offer.service'
import type { IRecommendationService } from '@b2ag/product/src/services/recommendation.service'
import type { Store } from './interfaces/store.interface'

export * from './interfaces/store.interface'

export const storeSymbol: InjectionKey<Store> = Symbol('store')

/* ---------- STORE ---------- */

export function provideStore(store) {
  provide(storeSymbol, store)
}

export function useStore(): Store {
  const store = inject(storeSymbol)
  if (!store) throw new Error('Store was not provided')
  return store
}

/* -------- SERVICES for STORE ---------- */

interface ServicesForStore {
  membershipApiClient?: MembershipApiClient
  searchService?: SearchService
  stockService?: IStockService
  recommendationService?: IRecommendationService
  storeService?: IStoreService
  offerService?: IOfferService
  discountService?: IDiscountService
}

let singl_servicesForStore = undefined as ServicesForStore | undefined

export function initServicesForStore(servicesForStore: ServicesForStore) {
  singl_servicesForStore = servicesForStore
}

export function getServicesForStore(): ServicesForStore {
  if (!singl_servicesForStore) throw new Error('Services for store were not initialized')
  return singl_servicesForStore
}

/* ---------- DEFAULT ---------- */

export default {}
