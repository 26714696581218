import type { ArticleThumbnails } from '@b2ag/articles/src/domain/ArticleThumbnails'
import { mapArticleThumbnails } from '@b2ag/articles/src/infra/mapper/articleThumbnails.mapper'
import type { Home } from '../../domain/Home'
import type { Categories } from '../../domain/blocs/Categories'
import type { HomePushProducts } from '../../domain/blocs/HomePushProducts'
import { BlocType } from '../../domain/BlocType'
import type { HomeBloc } from '../../domain/HomeBloc'
import { mapCategoriesContainerFromApi } from './homeCategoriesContainer.mapper'
import { DtoBlocType } from './DtoBlocType'
import { mapPushProductsFromApi } from './homePushProducts.mapper'
import { mapBannerFromApi } from './homeBanner.mapper'
import type { Banner } from '../../domain/blocs/Banner'

const mapperForType = {
  [DtoBlocType.BANNER]: mapBannerFromApi,
  [DtoBlocType.CATEGORIES_CONTAINER]: mapCategoriesContainerFromApi,
  [DtoBlocType.PUSH_ARTICLES]: mapArticleThumbnails,
  [DtoBlocType.PUSH_PRODUCTS]: mapPushProductsFromApi,
}

function getBlocs(homeBlocs: Array<HomeBloc>, type: BlocType, getFirst = false): HomeBloc | HomeBloc[] | undefined {
  const theBloc = homeBlocs?.filter((blocItem) => blocItem.blocType === type)
  if (theBloc?.length) {
    if (getFirst) {
      return theBloc[0]
    }
    return theBloc
  }
  return undefined
}

export function mapToHomeBlocs(blocDto: Array<any>): Array<HomeBloc> {
  if (!blocDto?.length) return []
  return blocDto
    .map((bloc) => {
      const typeOfBloc = bloc.sys?.contentType?.sys?.id
      if (typeOfBloc && Object.values(DtoBlocType).includes(typeOfBloc) && mapperForType[typeOfBloc]) {
        return mapperForType[typeOfBloc](bloc)
      }
      return undefined
    })
    .filter(Boolean) as Array<HomeBloc>
}

export function contentfulHomeBlocsToHome(dto: any): Home {
  const blocs = mapToHomeBlocs(dto.fields.blocs)
  return {
    mainHeader: [],
    articles: [],
    categories: (getBlocs(blocs, BlocType.CATEGORIES_CONTAINER, true) as Categories)?.items || [],
    pushArticles: getBlocs(blocs, BlocType.PUSH_ARTICLES, true) as ArticleThumbnails,
    pushProducts: getBlocs(blocs, BlocType.PUSH_PRODUCTS, true) as HomePushProducts,
    banners: getBlocs(blocs, BlocType.BANNER) as Banner[],
  } as Home
}
