export enum BlocType {
  BANNER = 'Banner',
  CATEGORY = 'Category',
  CATEGORIES_CONTAINER = 'CategoriesContainer',
  HOME_ARTICLE = 'HomeArticle',
  HOME_ADVERTORIAL = 'HomeAdvertorial',
  HOME_MAIN_HEADER = 'HomeMainHeader',
  PUSH_ARTICLES = 'ArticleThumbnailsBloc',
  PUSH_PRODUCTS = 'HomePushProducts',
  PARTNER_HOME_BANNER = 'PartnerHomeBanner',
}
