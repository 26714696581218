import type { CategoryDescription } from '../blocs/CategoryDescription'
import type { ICategoryDescriptionRepository } from '../repository/ICategoryDescriptionRepository'

export interface CategoryDescriptionUseCase {
  (categoryName: string, isAuthenticated: boolean, partnerId?: number): Promise<CategoryDescription | undefined>
}

export interface CategoryDescriptionUseCaseFactory {
  categoryDescriptionUseCaseFactory(
    categoryDescriptionRepository: ICategoryDescriptionRepository,
  ): CategoryDescriptionUseCase
}

export function categoryDescriptionUseCaseFactory(categoryDescriptionRepository: ICategoryDescriptionRepository) {
  return async (categoryName: string): Promise<CategoryDescription | undefined> => {
    return categoryDescriptionRepository.getDescription(categoryName)
  }
}
