import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

const getTextNodesContent = (node, acc) => {
  if (Array.isArray(node)) {
    return node.forEach((n) => getTextNodesContent(n, acc))
  }

  if (node?.nodeType === 'text') {
    return acc.push(`${node?.value} `)
  }
  return getTextNodesContent(node?.content, acc)
}

export const concactTextNodes = (content: any[] = []) => {
  const summarizedTab = []
  getTextNodesContent(content, summarizedTab)

  return summarizedTab.join('')
}

export enum UniversePageModelTypes {
  article = 'universeArticle',
  articles = 'universeArticles',
  banner = 'banner',
  categories = 'universeCategories',
  generalInformation = 'universeGeneralInformation',
  highlightedProducts = 'universeHighlightedProducts',
  page = 'universePage',
  productsIllustration = 'universeProductsIllustration',
  textArea = 'universeTextArea',
}

interface ImageProperties {
  width?: number
  height?: number
  fileFormat?: string
  progressive?: boolean
  quality?: number
  fitMode?: string
}

interface UniversePageBloc {
  type: UniversePageModelTypes
}

export interface UniversePageGeneralInformation extends UniversePageBloc {
  name: string
  slug: string
  logo: string
  description: string
  descriptionSummary: string
  backgroundImage: string
  themeColor: string
  anchorColor: string
  level: string
}

export interface UniverseProductsIllustration extends UniversePageBloc {
  title: string
  descriptionOne: string
  buttonLink: string
  descriptionTwo: string
  backgroundImage: string
  products: string
  anchorLabel: string
}

export interface UniverseCategory {
  title: string
  image: string
  description: string
  link: string
}

export interface UniverseCategories extends UniversePageBloc {
  title: string
  categories: UniverseCategory[]
  buttonLink: string
  anchorLabel: string
}

export interface UniverseBanner extends UniversePageBloc {
  desktopImage: string
  mobileImage: string
  isSmall: boolean
  link: string
  description: string
}

export interface UniverseTextArea extends UniversePageBloc {
  title: string
  content: string
  anchorLabel: string
}

export interface UniverseArticle {
  title: string
  description: string
  image: string
  imageDescription: string
  link: string
}

export interface UniverseArticles extends UniversePageBloc {
  title: string
  description: string
  anchorLabel: string
  articles: UniverseArticle[]
}

export const informationsLogo: ImageProperties = {
  fileFormat: 'png',
  quality: 40,
}

export const informationsBackgroundImage: ImageProperties = {
  width: 1440,
  height: 280,
  fileFormat: 'jpg',
  progressive: true,
  quality: 60,
  fitMode: 'fill',
}

export const productsIllustrationBackgroundImage: ImageProperties = {
  fileFormat: 'png',
  quality: 60,
  fitMode: 'fill',
}

export const articleImage: ImageProperties = {
  width: 700,
  height: 320,
  fileFormat: 'jpg',
  progressive: true,
  quality: 40,
  fitMode: 'fill',
}

export const addImageProperties = (
  imageUrl: string,
  { width, height, fileFormat, progressive, quality, fitMode } = {} as ImageProperties,
): string => {
  const properties: string[] = []
  if (width) properties.push(`w=${width}`)
  if (height) properties.push(`h=${height}`)
  if (fileFormat) properties.push(`fm=${fileFormat}`)
  if (progressive) properties.push(`fl=progressive`)
  if (quality) properties.push(`q=${quality}`)
  if (fitMode) properties.push(`fit=${fitMode}`)

  const joinedProperties = properties.length ? `?${properties.join('&')}` : ''
  return `https:${imageUrl}${joinedProperties}`
}

export const mapGeneralInformationsFromApi = (informations): UniversePageGeneralInformation | null => {
  if (!informations) return null
  const {
    name = '',
    slug = '',
    logo: { fields: { file: { url: logo } = '' } = '' } = '',
    description = '',
    backgroundImage: { fields: { file: { url: backgroundImage } = '' } = '' } = '',
    themeColor = '',
    anchorColor = '',
    level = '',
  } = informations.fields
  return {
    type: UniversePageModelTypes.generalInformation,
    name,
    slug,
    logo: logo ? addImageProperties(logo, informationsLogo) : '',
    description: documentToHtmlString(description),
    descriptionSummary: concactTextNodes(description.content),
    backgroundImage: addImageProperties(backgroundImage, informationsBackgroundImage),
    themeColor,
    anchorColor,
    level,
  }
}

export const mapProductsIllustrationFromApi = (informations): UniverseProductsIllustration | null => {
  if (!informations) return null
  const {
    title,
    descriptionOne,
    buttonLink,
    descriptionTwo,
    backgroundImage: { fields: { file: { url: backgroundImage } = '' } = '' } = '',
    products,
    anchorLabel,
  } = informations.fields
  return {
    type: UniversePageModelTypes.productsIllustration,
    title,
    descriptionOne,
    buttonLink,
    descriptionTwo: documentToHtmlString(descriptionTwo),
    backgroundImage: addImageProperties(backgroundImage, productsIllustrationBackgroundImage),
    products,
    anchorLabel,
  }
}

export const mapUniverseCategoryFromApi = (universeCategory): UniverseCategory => {
  const {
    title,
    image: { fields: { file: { url: image } = '' } = '' } = '',
    description,
    link,
  } = universeCategory.fields
  return {
    title,
    image: addImageProperties(image, productsIllustrationBackgroundImage),
    description: documentToHtmlString(description),
    link,
  }
}

export const mapUniverseCategoriesFromApi = (universeCategories): UniverseCategories | null => {
  if (!universeCategories) return null
  const { title, categories, buttonLink, anchorLabel } = universeCategories.fields
  const mappedCategories = (categories as Array<Object>).map((category) => {
    return mapUniverseCategoryFromApi(category)
  })
  return {
    type: UniversePageModelTypes.categories,
    title,
    categories: mappedCategories,
    buttonLink,
    anchorLabel,
  }
}

export const mapHighlightedProductsFromApi = (informations) => {
  const { anchorLabel = '', buttonLink = '', products = '', title = '' } = informations.fields
  return {
    type: UniversePageModelTypes.highlightedProducts,
    anchorLabel,
    buttonLink,
    products,
    title,
  }
}

export const mapTextAreaFromApi = (informations): UniverseTextArea | null => {
  if (!informations) return null
  const { title, content, anchorLabel } = informations.fields
  return { type: UniversePageModelTypes.textArea, title, content: documentToHtmlString(content), anchorLabel }
}

export const mapUniverseBannersFromApi = (universeBanner): UniverseBanner | null => {
  if (!universeBanner) return null
  const {
    desktopImage: {
      fields: {
        file: { url: desktopImage },
      },
    },
    mobileImage: {
      fields: {
        file: { url: mobileImage },
      },
    },
    isSmall,
    link,
    description,
  } = universeBanner.fields
  return {
    type: UniversePageModelTypes.banner,
    desktopImage: addImageProperties(desktopImage),
    mobileImage: addImageProperties(mobileImage),
    isSmall,
    link,
    description,
  }
}

export const mapUniverseArticleFromApi = (universeArticle): UniverseArticle | null => {
  if (!universeArticle) return null
  const {
    title,
    image: { fields: { file: { url: image } = '', description: imageDescription = '' } = '' } = '',
    description,
    link,
  } = universeArticle.fields
  return {
    title,
    image: addImageProperties(image, articleImage),
    imageDescription,
    description: documentToHtmlString(description),
    link,
  }
}

export const mapUniverseArticlesFromApi = (universeArticles): UniverseArticles | null => {
  if (!universeArticles) return null
  const { title, articles, description, anchorLabel } = universeArticles.fields
  const mappedArticles: Array<UniverseArticle | null> = (articles as Array<Object>).map((article) => {
    return mapUniverseArticleFromApi(article)
  })
  return {
    type: UniversePageModelTypes.articles,
    title,
    articles: mappedArticles.filter((item) => item !== null) as UniverseArticle[],
    description: documentToHtmlString(description),
    anchorLabel,
  }
}

const typeToMapper = {
  [UniversePageModelTypes.articles]: mapUniverseArticlesFromApi,
  [UniversePageModelTypes.banner]: mapUniverseBannersFromApi,
  [UniversePageModelTypes.categories]: mapUniverseCategoriesFromApi,
  [UniversePageModelTypes.generalInformation]: mapGeneralInformationsFromApi,
  [UniversePageModelTypes.highlightedProducts]: mapHighlightedProductsFromApi,
  [UniversePageModelTypes.page]: null,
  [UniversePageModelTypes.productsIllustration]: mapProductsIllustrationFromApi,
  [UniversePageModelTypes.textArea]: mapTextAreaFromApi,
}

export const typeExists = (type: UniversePageModelTypes): boolean => {
  return Object.values(UniversePageModelTypes).includes(type)
}

export const getBlocType = (informations: { fields: any; sys: any }): UniversePageModelTypes | undefined => {
  const type: UniversePageModelTypes = informations?.sys?.contentType?.sys?.id
  if (typeExists(type)) return type
  // eslint-disable-next-line no-console
  console.error('unknown block type :', type)
  return undefined
}

export const mapBlocFromApi = (informations: { fields: any; sys: any }) => {
  const blockType = getBlocType(informations)
  if (blockType && typeToMapper[blockType]) {
    return typeToMapper[blockType](informations)
  }
  return null
}
