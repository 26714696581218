import { Discount, Offer } from '@b2ag/pricing'
import { computed, type ComputedRef, type Ref } from 'vue'
import { useDiscountValue } from '@b2ag/composables/src/catalog/useDiscountValue'
import {
  hasDiscount,
  getFormattedDiscountedPrice,
  getFormattedDiscountValue,
  getFormattedHTLabel,
  getFormattedPrice,
} from '../domain/price'

interface IUsePrice {
  hasDiscount: ComputedRef<boolean>
  formattedPrice: ComputedRef<string>
  formattedHTLabel: ComputedRef<string>
  formattedDiscountedPrice: ComputedRef<string | null>
  formattedDiscountValue: ComputedRef<string | null>
}

function usePrice(
  offer: Ref<
    Pick<
      Offer,
      | 'isVariantSoldAsUnit'
      | 'unitPriceExcludingTaxes'
      | 'measurementPriceExcludingTaxes'
      | 'variantMeasureUnit'
      | 'hasDiscount'
    >
  >,
  discountValue: Ref<Discount | null | undefined>,
): IUsePrice {
  const formattedDiscountedPrice = computed(
    () => (discountValue.value && getFormattedDiscountedPrice(offer.value, discountValue.value)) || null,
  )

  return {
    hasDiscount: computed(() => hasDiscount(offer.value)),
    formattedPrice: computed(() => getFormattedPrice(offer.value)),
    formattedHTLabel: computed(() => getFormattedHTLabel(offer.value)),
    formattedDiscountedPrice,
    formattedDiscountValue: computed(() => getFormattedDiscountValue(discountValue.value || null, offer.value)),
  }
}

export function useBestPrice(
  offer: Ref<
    Pick<
      Offer,
      | 'isVariantSoldAsUnit'
      | 'unitPriceExcludingTaxes'
      | 'measurementPriceExcludingTaxes'
      | 'variantMeasureUnit'
      | 'hasDiscount'
      | 'discountApplication'
    >
  >,
): IUsePrice {
  const bestDiscount = computed(() => offer.value?.discountApplication?.bestDiscount())
  return usePrice(offer, bestDiscount)
}

export function useSelectedPrice(
  offer: Ref<
    Pick<
      Offer,
      | 'isVariantSoldAsUnit'
      | 'unitPriceExcludingTaxes'
      | 'measurementPriceExcludingTaxes'
      | 'variantMeasureUnit'
      | 'hasDiscount'
      | 'discountApplication'
    >
  >,
  offerId: Ref<string>,
): IUsePrice {
  const discountValue = useDiscountValue(offer.value.discountApplication!, offerId.value)
  return usePrice(offer, discountValue)
}
