import { HomeUseCase } from './domain/use-case/home.use-case'
import { contentfulClient, searchService } from './application/services'
import type { IArticlesRepository } from './domain/repository/IArticlesRepository'
import type { ICategoryDescriptionRepository } from './domain/repository/ICategoryDescriptionRepository'
import type { IHomeRepository } from './domain/repository/IHomeRepository'
import { getArticlesByPartnerIdUseCaseFactory } from './domain/use-case/get-articles-by-partner-id.use-case'
import { getAnchoredArticleByPartnerIdUseCaseFactory } from './domain/use-case/get-anchored-article-by-partner-id.use-case'
import { categoryDescriptionUseCaseFactory } from './domain/use-case/get-category-description.use-case'
import { ArticlesRepository } from './infra/repository/articles.repository'
import { CategoryDescriptionRepository } from './infra/repository/category-descriptions.repository'
import { HomeRepository } from './infra/repository/home.repository'
import type { IAdvertorialRepository } from './domain/repository/IAdvertorialRepository'
import { getAdvertorialBySupplierUseCaseFactory } from './domain/use-case/get-advertorial-by-supplier.use-case'
import { AdvertorialRepository } from './infra/repository/advertorial.repository'
import { BannerRepository } from './infra/repository/banner.repository'
import { getBannersUseCaseFactory } from './domain/use-case/get-banners.use-case'
import { SupplierHighlightRepository } from './infra/repository/supplier-highlight.repository'
import { GetSupplierHighlightByPartnerUseCase } from './domain/use-case/get-supplier-highlight-by-partner.use-case'

const iHomeRepository = new HomeRepository(contentfulClient) as IHomeRepository
const homeUseCase = new HomeUseCase(iHomeRepository)
const categoryDescriptionRepository = new CategoryDescriptionRepository(
  contentfulClient,
) as ICategoryDescriptionRepository
const articlesRepository = new ArticlesRepository(contentfulClient) as IArticlesRepository
const advertorialRepository = new AdvertorialRepository(contentfulClient) as IAdvertorialRepository
const categoryDescriptionUseCase = categoryDescriptionUseCaseFactory(categoryDescriptionRepository)
const getArticlesByPartnerIdUseCase = getArticlesByPartnerIdUseCaseFactory(articlesRepository)
const getAnchoredArticleByPartnerIdUseCase = getAnchoredArticleByPartnerIdUseCaseFactory(
  articlesRepository,
  searchService,
)
const getAdvertorialBySupplierUseCase = getAdvertorialBySupplierUseCaseFactory(advertorialRepository, searchService)
const bannerRepository = new BannerRepository(contentfulClient)
const getBannersUseCase = getBannersUseCaseFactory(bannerRepository, searchService)

const supplierHighlightRepository = new SupplierHighlightRepository(contentfulClient)
const getSupplierHighlightByPartnerUseCase = new GetSupplierHighlightByPartnerUseCase(
  supplierHighlightRepository,
  searchService,
)

export {
  homeUseCase,
  iHomeRepository,
  categoryDescriptionUseCase,
  getAdvertorialBySupplierUseCase,
  getArticlesByPartnerIdUseCase,
  getAnchoredArticleByPartnerIdUseCase,
  getBannersUseCase,
  getSupplierHighlightByPartnerUseCase,
}
