import ContentfulClient from '@b2ag/contentful'
import type { CategoryDescription } from '../../domain/blocs/CategoryDescription'
import { mapCategoryDescriptionFromApi } from '../mapper/category-descriptions.mapper'
import type { ICategoryDescriptionRepository } from '../../domain/repository/ICategoryDescriptionRepository'

export class CategoryDescriptionRepository implements ICategoryDescriptionRepository {
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(public readonly client: ContentfulClient) {}

  getDescription(category: string): Promise<CategoryDescription | undefined> {
    if (!category) {
      return Promise.resolve(undefined)
    }
    return this.client.api
      .getEntries({
        include: 1,
        content_type: 'categoryDescription',
        'fields.category': category,
        limit: 1,
      })
      .then(
        (result) => {
          if (result.total === 0) {
            return { category: '', block3: '', block1: '', block2: [] } as CategoryDescription
          }
          return mapCategoryDescriptionFromApi(result.items[0])
        },
        () => {
          // eslint-disable-next-line no-console
          console.log('CategoryDescriptionRepository getDescription failed', category)
          return undefined
        },
      )
  }
}
