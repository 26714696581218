export default {
  expert: 'expert',
  interlocuteurs: 'interlocuteurs',
  metadata: {
    title: 'Episens | Vente en ligne de produits',
    defaultDescription: 'Episens, site E-commerce , trouvez ce dont vous avez besoin.',
  },
  nomApp: 'Episens',
  'p-app-search': {
    metadata: {
      titleAllProducts: 'Nos gammes',
      title: '{$familyOrCategory} - Consultez notre offre sur Episens',
      productGroupName: 'notre gamme {$familyOrCategory}',
      defaultProductGroupeName: 'nos gammes',
      description: 'Consulter {$productGroupName} et commandez en ligne sur Episens, vente de produits en ligne.',
    },
  },
  'p-product-layout': {
    'asterisk-info': 'Selon les modalités appliquées par Episens. Voir les conditions générales de vente.',
  },
  tech: 'commercial',
  tc: 'ARC',
  'v-product': {
    metadata: {
      title: {
        default: '{$firstCategory}, {$name} - Episens',
      },
      description: {
        default: 'Consultez la fiche produit Episens pour {$name}, {$firstCategory}, et commandez en quelques clics.',
      },
    },
  },
  'v-user': {
    'number-label': 'N° client',
    'user-section-label': 'Votre compte Episens',
  },
  partenaire: {
    exploitation: 'exploitation',
    serviceClient: 'service-client',
    negoce: 'service client',
    entrepriseNoArticle: 'entreprise',
    entreprise: 'une entreprise',
    choix: 'Episens',
  },
  adherent: {
    choix: 'client',
    client: 'client',
  },
}
