import iframes from './iframes.json'

type JsonIframeConfig = {
  basePath: string
  env: {
    dev: {
      host: string
      port: number
    }
    prod: {
      path?: string
    }
  }
  elementId: string
}

type ExtendIframeConfig = {
  env: {
    dev: {
      href: string
      path: string
    }
    prod: {
      origin: string
      href: string
      path: string
    }
  }
}

export type IframeConfig = JsonIframeConfig & ExtendIframeConfig

export function getBasePathForMicroFront(name: string) {
  const config: JsonIframeConfig = iframes[name]

  return config.basePath
}

export function getPortForMicroFront(name: string) {
  const config: JsonIframeConfig = iframes[name]

  return config.env.dev.port
}

export function getConfigForIframeProvider(name: string): IframeConfig {
  const config: JsonIframeConfig = iframes[name]

  return {
    ...config,
    env: {
      ...config.env,
      dev: {
        ...config.env.dev,
        href: `${window.location.origin}${config.basePath}`,
        path: config.basePath,
      },
      prod: {
        origin: `${window.location.origin}`,
        href: `${window.location.origin}${config.basePath}`,
        path: config.basePath,
      },
    },
  }
}

export function getConfigForDevServerProxy(name: string) {
  const config: JsonIframeConfig = iframes[name]
  const { host, port } = config.env.dev

  return {
    [config.basePath]: {
      target: host + ':' + port,
    },
  }
}
