import { i18n, I18n } from '@b2ag/locale'

declare module 'vue/types/vue' {
  interface Vue {
    i18n: I18n
  }
}

const install = (Vue) => {
  Vue.prototype.i18n = Vue.i18n = i18n
}

export default { install }
